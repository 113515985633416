
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Button } from "@/components/ui/button";
import { AlertTriangle } from "lucide-react";

const NotFound = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    console.error(
      "404 Error: User attempted to access non-existent route:",
      location.pathname
    );
  }, [location.pathname]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-pink-50 p-6">
      <div className="max-w-md w-full bg-white rounded-xl shadow-md overflow-hidden p-6 text-center">
        <div className="flex justify-center mb-4">
          <div className="bg-red-100 p-3 rounded-full">
            <AlertTriangle className="h-10 w-10 text-red-500" />
          </div>
        </div>
        
        <h1 className="text-2xl font-bold text-gray-800 mb-2">Page non trouvée</h1>
        <p className="text-gray-600 mb-6">
          Nous ne trouvons pas la page que vous recherchez. 
          Il est possible que la page ait été déplacée ou que le lien soit incorrect.
        </p>
        
        <div className="space-y-3">
          <Button 
            onClick={() => navigate('/')} 
            className="w-full bg-nutrimam-accent hover:bg-nutrimam-accent/90"
          >
            Retour à l'accueil
          </Button>
          <Button 
            onClick={() => navigate(-1)} 
            variant="outline" 
            className="w-full border-nutrimam-accent text-nutrimam-accent hover:bg-pink-50"
          >
            Retour à la page précédente
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
