
import React from 'react';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { updateProfile } from '@/services/authService';
import { toast } from 'sonner';

interface WarningModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const WarningModal: React.FC<WarningModalProps> = ({ isOpen, onClose }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleAccept = async () => {
    setIsSubmitting(true);
    try {
      const result = await updateProfile({ warning_accepted: true });
      if (result.success) {
        toast.success("Merci d'avoir accepté les conditions");
        onClose();
      } else {
        toast.error("Une erreur est survenue. Veuillez réessayer.");
      }
    } catch (error) {
      console.error("Erreur lors de l'acceptation des conditions:", error);
      toast.error("Une erreur est survenue. Veuillez réessayer.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && onClose()}>
      <DialogContent className="sm:max-w-md" aria-describedby="warning-dialog">
        <DialogHeader>
          <DialogTitle className="text-xl font-semibold text-pink-700">Information importante</DialogTitle>
        </DialogHeader>
        <DialogDescription className="text-base py-4">
          <p className="mb-4">
            Les informations et les conseils fournis par NutriMam sont destinés à titre informatif uniquement.
          </p>
          <p className="mb-4">
            Ils ne remplacent en aucun cas l'avis, le diagnostic ou le traitement médical professionnel. Consultez toujours votre médecin ou un autre professionnel de santé qualifié pour toute question concernant une condition médicale.
          </p>
          <p className="mb-4">
            NutriMam ne peut être tenu responsable de l'utilisation que vous faites des informations fournies.
          </p>
        </DialogDescription>
        <DialogFooter className="mt-2">
          <Button 
            onClick={handleAccept} 
            disabled={isSubmitting}
            className="w-full bg-pink-600 hover:bg-pink-700"
          >
            {isSubmitting ? "Traitement en cours..." : "J'ai compris et j'accepte"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default WarningModal;
