import { Product } from '@/utils/productUtils';
import { HistoryEntry } from './types';
import { supabase } from '@/integrations/supabase/client';
import { 
  addToLocalHistory, 
  getLocalHistory, 
  removeFromLocalHistory 
} from './localHistoryService';
import {
  addToSupabaseHistory,
  getSupabaseHistory,
  removeFromSupabaseHistory,
  clearSupabaseHistory
} from './supabaseHistoryService';

/**
 * Adds a product to the history of scans
 */
export const addToHistory = async (product: Product, preventDuplication: boolean = false): Promise<void> => {
  try {
    // Check if the user is logged in
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) {
      console.log('Utilisateur non connecté, utilisation du stockage local');
      addToLocalHistory(product, preventDuplication);
      return;
    }
    
    // Try to add to Supabase
    const success = await addToSupabaseHistory(user.id, product, preventDuplication);
    
    // Fallback to local storage if Supabase fails
    if (!success) {
      addToLocalHistory(product, preventDuplication);
    }
  } catch (error) {
    console.error('Erreur lors de l\'ajout à l\'historique:', error);
    // Fallback to local storage
    addToLocalHistory(product, preventDuplication);
  }
};

/**
 * Retrieves the history of scans
 */
export const getHistory = async (limit?: number): Promise<HistoryEntry[]> => {
  try {
    // Check if the user is logged in
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) {
      console.log('Utilisateur non connecté, récupération depuis le stockage local');
      return getLocalHistory(limit);
    }
    
    // Get history from Supabase
    const history = await getSupabaseHistory(user.id, limit);
    
    // Pour les utilisateurs authentifiés, ne pas utiliser l'historique local
    // si aucun historique n'est trouvé dans Supabase
    return history;
  } catch (error) {
    console.error('Erreur lors de la récupération de l\'historique:', error);
    return getLocalHistory(limit);
  }
};

/**
 * Removes an entry from history
 */
export const removeFromHistory = async (entryId: string): Promise<void> => {
  try {
    // Check if the user is logged in
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) {
      removeFromLocalHistory(entryId);
      return;
    }
    
    // Try to remove from Supabase
    const success = await removeFromSupabaseHistory(user.id, entryId);
    
    // Fallback to local if Supabase fails
    if (!success) {
      removeFromLocalHistory(entryId);
    }
  } catch (error) {
    console.error('Erreur lors de la suppression de l\'entrée de l\'historique:', error);
    removeFromLocalHistory(entryId);
  }
};

/**
 * Clears the history of scans
 */
export const clearHistory = async (): Promise<void> => {
  try {
    // Check if the user is logged in
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) {
      localStorage.removeItem('product_scan_history');
      return;
    }
    
    // Try to clear history from Supabase
    const success = await clearSupabaseHistory(user.id);
    
    // Fallback to local if Supabase fails
    if (!success) {
      localStorage.removeItem('product_scan_history');
    }
  } catch (error) {
    console.error('Erreur lors de la suppression de l\'historique:', error);
    localStorage.removeItem('product_scan_history');
  }
};

// Re-export the HistoryEntry type for use by other modules
export type { HistoryEntry };
