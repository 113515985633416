import { Product, NutritionData, NutritionItem } from '@/utils/productUtils';
import { translateAllergen, translateIngredient } from '@/utils/translationUtils';
import { mockProducts } from '@/data/mockProducts';
import { addToHistory } from './historyService';

// Keep track of ongoing requests to prevent duplicate calls
const ongoingRequests = new Map<string, Promise<Product>>();

export const fetchProductById = async (id: string): Promise<Product> => {
  // Check if we're already fetching this product
  if (ongoingRequests.has(id)) {
    return ongoingRequests.get(id)!;
  }
  
  // Create a new request and store it
  const request = fetchProductData(id);
  ongoingRequests.set(id, request);
  
  try {
    const result = await request;
    return result;
  } finally {
    // Clean up the request when done
    ongoingRequests.delete(id);
  }
};

// Actual data fetching function
const fetchProductData = async (id: string): Promise<Product> => {
  try {
    const response = await fetch(`https://world.openfoodfacts.org/api/v2/product/${id}.json`);
    if (!response.ok) {
      throw new Error('Produit non trouvé dans Open Food Facts');
    }
    const apiData = await response.json();
    if (apiData.status === 0) {
      throw new Error('Désolé, ce produit ne fait pas encore partie de notre base de données mais promis on y travaille !');
    }
    const product = mapProductData(apiData.product);
    
    // Add the product to history with prevention of duplications
    addToHistory(product, true);
    
    return product;
  } catch (err: any) {
    console.error("Error fetching product:", err);
    const mockProduct = mockProducts.find(p => p.id === Number(id));
    if (mockProduct) {
      // Add mock product to history as well, with prevention of duplications
      addToHistory(mockProduct, true);
      return mockProduct;
    }
    throw new Error('Désolé, ce produit ne fait pas encore partie de notre base de données mais promis on y travaille !');
  }
};

export const mapProductData = (apiProduct: any): Product => {
  const allergens: string[] = [];
  if (apiProduct.allergens_tags && apiProduct.allergens_tags.length > 0) {
    apiProduct.allergens_tags.forEach((allergen: string) => {
      const cleanAllergen = allergen.replace('en:', '').replace(/-/g, ' ');
      const translatedAllergen = translateAllergen(cleanAllergen);
      allergens.push(translatedAllergen.charAt(0).toUpperCase() + translatedAllergen.slice(1));
    });
  }
  
  const warnings: string[] = [];
  if (apiProduct.ingredients_from_or_that_may_be_from_palm_oil_n > 0) {
    warnings.push('Contient de l\'huile de palme');
  }
  if (apiProduct.categories_tags && apiProduct.categories_tags.some((cat: string) => cat.includes('smoked') && (cat.includes('fish') || cat.includes('salmon')))) {
    warnings.push('Les poissons fumés sont interdits pendant la grossesse car ils présentent un risque élevé de contamination par la listeria.');
  }
  
  const nutriments = apiProduct.nutriments || {};
  const nutritionItems: NutritionItem[] = [];
  
  if (nutriments['fat_100g'] !== undefined) {
    let level = 'low';
    let icon = 'check';
    if (nutriments['fat_100g'] > 10) {
      level = 'high';
      icon = 'circle-x';
    } else if (nutriments['fat_100g'] > 3) {
      level = 'moderate';
      icon = 'warning';
    }
    nutritionItems.push({
      name: 'Matières grasses en quantité ' + level,
      value: nutriments['fat_100g'] + 'g',
      level: level as 'low' | 'moderate' | 'high',
      icon: icon as 'check' | 'warning' | 'circle-x'
    });
  }
  
  if (nutriments['saturated-fat_100g'] !== undefined) {
    let level = 'low';
    let icon = 'check';
    if (nutriments['saturated-fat_100g'] > 5) {
      level = 'high';
      icon = 'circle-x';
    } else if (nutriments['saturated-fat_100g'] > 1.5) {
      level = 'moderate';
      icon = 'warning';
    }
    nutritionItems.push({
      name: 'Acides gras saturés en quantité ' + level,
      value: nutriments['saturated-fat_100g'] + 'g',
      level: level as 'low' | 'moderate' | 'high',
      icon: icon as 'check' | 'warning' | 'circle-x'
    });
  }
  
  if (nutriments['sugars_100g'] !== undefined) {
    let level = 'low';
    let icon = 'check';
    if (nutriments['sugars_100g'] > 15) {
      level = 'high';
      icon = 'circle-x';
    } else if (nutriments['sugars_100g'] > 5) {
      level = 'moderate';
      icon = 'warning';
    }
    nutritionItems.push({
      name: 'Sucres en quantité ' + level,
      value: nutriments['sugars_100g'] + 'g',
      level: level as 'low' | 'moderate' | 'high',
      icon: icon as 'check' | 'warning' | 'circle-x'
    });
  }
  
  if (nutriments['salt_100g'] !== undefined) {
    let level = 'low';
    let icon = 'check';
    if (nutriments['salt_100g'] > 1.5) {
      level = 'high';
      icon = 'circle-x';
    } else if (nutriments['salt_100g'] > 0.3) {
      level = 'moderate';
      icon = 'warning';
    }
    nutritionItems.push({
      name: 'Sel en ' + (level === 'low' ? 'faible quantité' : 'quantité ' + level),
      value: nutriments['salt_100g'] + 'g',
      level: level as 'low' | 'moderate' | 'high',
      icon: icon as 'check' | 'warning' | 'circle-x'
    });
  }
  
  const nutrition: NutritionData = {
    items: nutritionItems,
    energy: nutriments['energy-kj_100g'] ? nutriments['energy-kj_100g'] + ' kJ' : 'N/A',
    energyKcal: nutriments['energy-kcal_100g'] ? nutriments['energy-kcal_100g'] + ' kcal' : 'N/A',
    carbohydrates: nutriments['carbohydrates_100g'] ? nutriments['carbohydrates_100g'] + 'g' : 'N/A',
    proteins: nutriments['proteins_100g'] ? nutriments['proteins_100g'] + 'g' : 'N/A',
    fiber: nutriments['fiber_100g'] ? nutriments['fiber_100g'] + 'g' : 'N/A'
  };
  
  let status = 'Autorisé sans modération';
  const novaGroup = apiProduct.nova_group ? parseInt(apiProduct.nova_group) : 0;
  const nutriScore = apiProduct.nutriscore_grade ? apiProduct.nutriscore_grade.toUpperCase() : '';
  
  if (novaGroup >= 4 || nutriScore === 'E' || warnings.length > 0) {
    status = 'Autorisé sous conditions';
  } else if (novaGroup >= 3 || nutriScore === 'D') {
    status = 'Autorisé avec modération';
  }
  
  let ingredients: string[] = [];
  if (apiProduct.ingredients_text) {
    ingredients = apiProduct.ingredients_text.split(',')
      .map((i: string) => i.trim());
  } else if (apiProduct.ingredients) {
    ingredients = apiProduct.ingredients
      .map((i: any) => i.text || i.id || '')
      .filter(Boolean);
  }
  
  const ingredientCount = ingredients.length || apiProduct.ingredients_n || 0;
  
  // Use categories_hierarchy instead of categories
  let categories: string[] = [];
  if (apiProduct.categories_hierarchy && apiProduct.categories_hierarchy.length > 0) {
    categories = apiProduct.categories_hierarchy.map((cat: string) => {
      // Remove 'en:' prefix but preserve hyphens
      return cat.replace('en:', '');
    });
  } else if (apiProduct.categories) {
    // Fallback to the old method if categories_hierarchy is not available
    categories = apiProduct.categories.split(',').map((c: string) => c.trim());
  }
  
  const detailedAllergens: string[] = [];
  if (apiProduct.allergens_tags && apiProduct.allergens_tags.length > 0) {
    apiProduct.allergens_tags.forEach((allergen: string) => {
      const cleanAllergen = allergen.replace('en:', '').replace(/-/g, ' ');
      const translatedAllergen = translateAllergen(cleanAllergen);
      detailedAllergens.push(translatedAllergen.charAt(0).toUpperCase() + translatedAllergen.slice(1));
    });
  }
  
  return {
    id: parseInt(apiProduct.code) || 0,
    name: apiProduct.product_name || apiProduct.product_name_fr || 'Produit inconnu',
    fullName: apiProduct.product_name_fr || apiProduct.product_name || 'Produit inconnu',
    brand: apiProduct.brands || 'Marque inconnue',
    weight: apiProduct.quantity || '',
    category: apiProduct.categories || 'Catégorie inconnue',
    categories: categories,
    imageUrl: apiProduct.image_url || apiProduct.image_small_url || '/placeholder.svg',
    novaScore: apiProduct.nova_group ? parseInt(apiProduct.nova_group) : 0,
    nutriScore: apiProduct.nutriscore_grade ? apiProduct.nutriscore_grade.toUpperCase() : '',
    status,
    warnings,
    allergens,
    detailedAllergens,
    nutrition,
    ingredients,
    ingredientCount
  };
};
