
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '@/integrations/supabase/client';
import { Button } from '@/components/ui/button';
import { CheckCircle, XCircle } from 'lucide-react';
import useAuth from '@/hooks/useAuth';
import { toast } from 'sonner';

const AuthCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const { refreshProfile } = useAuth();
  
  useEffect(() => {
    const handleAuthCallback = async () => {
      try {
        console.log('Processing auth callback, URL:', window.location.href);
        console.log('URL search params:', location.search);
        console.log('URL hash:', location.hash);
        
        // First, check if we have a session already
        const { data: sessionData } = await supabase.auth.getSession();
        
        if (sessionData.session) {
          console.log('User already has a session, navigating to home');
          await refreshProfile();
          toast.success('Compte vérifié avec succès!');
          setLoading(false);
          setTimeout(() => {
            // Check if we're in a mobile app context by checking the platform
            if (window.location.href.includes('capacitor://')) {
              window.location.href = 'nutrimam://home';
            } else {
              navigate('/home');
            }
          }, 1000);
          return;
        }
        
        // Extract params - handle both token (from email) and code (from OAuth)
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        const type = params.get('type');
        const code = params.get('code');
        
        console.log('Auth params:', { token, type, code });
        
        // Handle code exchange case (from OAuth redirect)
        if (code) {
          console.log('Processing auth code');
          const { data, error: exchangeError } = await supabase.auth.exchangeCodeForSession(code);
          
          if (exchangeError) {
            console.error('Error exchanging code for session:', exchangeError);
            setError('Erreur lors de l\'échange du code: ' + exchangeError.message);
            setLoading(false);
            return;
          }
          
          if (data.session) {
            console.log('Successfully got session from code, navigating to home');
            await refreshProfile();
            toast.success('Connexion réussie!');
            setLoading(false);
            setTimeout(() => {
              // Check if we're in a mobile app context by checking the platform
              if (window.location.href.includes('capacitor://')) {
                window.location.href = 'nutrimam://home';
              } else {
                navigate('/home');
              }
            }, 1000);
            return;
          }
        }
        // If no token or code, or other processing failed
        else {
          console.error('No valid auth parameters found');
          setError('Aucun paramètre d\'authentification trouvé. Veuillez réessayer.');
          setLoading(false);
        }
      } catch (err) {
        console.error('Unexpected error during auth callback:', err);
        setError('Une erreur inattendue est survenue lors de la vérification');
        setLoading(false);
      }
    };
    
    // Execute the callback handler
    handleAuthCallback();
  }, [location, navigate, refreshProfile]);
  
  return (
    <div className="min-h-screen bg-pink-50 flex flex-col items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full text-center">
        {loading ? (
          <div className="flex flex-col items-center justify-center py-8">
            <div className="h-12 w-12 rounded-full border-4 border-t-nutrimam-accent border-r-nutrimam-accent border-b-transparent border-l-transparent animate-spin mb-4"></div>
            <h2 className="text-xl font-semibold">Vérification en cours...</h2>
            <p className="text-gray-500 mt-2">Veuillez patienter pendant que nous vérifions votre email</p>
          </div>
        ) : error ? (
          <div className="flex flex-col items-center justify-center py-8">
            <XCircle className="h-16 w-16 text-red-500 mb-4" />
            <h2 className="text-xl font-semibold text-red-600">Vérification échouée</h2>
            <p className="text-gray-500 mt-2 mb-6">{error}</p>
            <Button 
              onClick={() => navigate('/')}
              className="bg-nutrimam-accent hover:bg-nutrimam-accent/90"
            >
              Retour à l'accueil
            </Button>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center py-8">
            <CheckCircle className="h-16 w-16 text-green-500 mb-4" />
            <h2 className="text-xl font-semibold">Email vérifié avec succès !</h2>
            <p className="text-gray-500 mt-2 mb-6">Votre compte a été activé, vous allez être redirigé.</p>
            <Button 
              onClick={() => {
                if (window.location.href.includes('capacitor://')) {
                  window.location.href = 'nutrimam://home';
                } else {
                  navigate('/home');
                }
              }}
              className="bg-nutrimam-accent hover:bg-nutrimam-accent/90"
            >
              Aller à l'accueil
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthCallback;
