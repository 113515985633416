
import { Product } from '../utils/productUtils';

// Mock products data
export const mockProducts: Product[] = [{
  id: 1,
  name: 'Lait infantile',
  fullName: 'Lait infantile en poudre',
  brand: 'Gallia',
  weight: '900g',
  category: 'Lait',
  imageUrl: '/lovable-uploads/01d4eb2e-8161-4349-bdb5-412d02301ad2.png',
  novaScore: 3,
  nutriScore: 'B',
  status: 'Autorisé sans modération',
  warnings: [],
  allergens: [],
  ingredients: ['Lait écrémé', 'Lactose', 'Huiles végétales', 'Protéines de lait', 'Vitamines'],
  ingredientCount: 15,
  nutrition: {
    items: [{
      name: 'Matières grasses',
      value: '3.6g',
      level: 'moderate',
      icon: 'warning'
    }, {
      name: 'Acides gras saturés',
      value: '2.1g',
      level: 'low',
      icon: 'check'
    }, {
      name: 'Sucres',
      value: '5.0g',
      level: 'moderate',
      icon: 'warning'
    }, {
      name: 'Sel',
      value: '0.1g',
      level: 'low',
      icon: 'check'
    }],
    energy: '293 kJ',
    energyKcal: '70 kcal',
    carbohydrates: '7.2g',
    proteins: '3.5g',
    fiber: '0g'
  }
}, {
  id: 2,
  name: 'Purée de carottes',
  fullName: 'Purée de carottes pour bébé',
  brand: 'Blédina',
  weight: '200g',
  category: 'Purée',
  imageUrl: '/lovable-uploads/305379c8-086c-4847-afc1-c0928eafe008.png',
  novaScore: 2,
  nutriScore: 'A',
  status: 'Autorisé sans modération',
  warnings: [],
  allergens: [],
  ingredients: ['Carottes', 'Eau', 'Amidon de maïs', 'Sel'],
  ingredientCount: 4,
  nutrition: {
    items: [{
      name: 'Matières grasses',
      value: '0.2g',
      level: 'low',
      icon: 'check'
    }, {
      name: 'Acides gras saturés',
      value: '0.1g',
      level: 'low',
      icon: 'check'
    }, {
      name: 'Sucres',
      value: '4.5g',
      level: 'moderate',
      icon: 'warning'
    }, {
      name: 'Sel',
      value: '0.02g',
      level: 'low',
      icon: 'check'
    }],
    energy: '146 kJ',
    energyKcal: '35 kcal',
    carbohydrates: '7.8g',
    proteins: '0.8g',
    fiber: '1.5g'
  }
}, {
  id: 3,
  name: 'Compote pomme-banane',
  fullName: 'Compote pomme-banane pour bébé',
  brand: 'Nestlé',
  weight: '90g',
  category: 'Compote',
  imageUrl: '/lovable-uploads/45395e0f-f000-43a3-844e-ffa10f961245.png',
  novaScore: 2,
  nutriScore: 'B',
  status: 'Autorisé sans modération',
  warnings: [],
  allergens: [],
  ingredients: ['Pomme', 'Banane', 'Sucre', 'Acide ascorbique'],
  ingredientCount: 4,
  nutrition: {
    items: [{
      name: 'Matières grasses',
      value: '0.1g',
      level: 'low',
      icon: 'check'
    }, {
      name: 'Acides gras saturés',
      value: '0g',
      level: 'low',
      icon: 'check'
    }, {
      name: 'Sucres',
      value: '12.0g',
      level: 'high',
      icon: 'circle-x'
    }, {
      name: 'Sel',
      value: '0.02g',
      level: 'low',
      icon: 'check'
    }],
    energy: '293 kJ',
    energyKcal: '70 kcal',
    carbohydrates: '16g',
    proteins: '0.5g',
    fiber: '1.5g'
  }
}, {
  id: 4,
  name: 'Céréales infantiles',
  fullName: 'Céréales infantiles au blé',
  brand: 'Picot',
  weight: '400g',
  category: 'Céréales',
  imageUrl: '/lovable-uploads/554b1d0b-bc59-423b-be33-b05b04a84add.png',
  novaScore: 4,
  nutriScore: 'C',
  status: 'Autorisé avec modération',
  warnings: ['Contient du gluten'],
  allergens: ['Gluten'],
  ingredients: ['Farine de blé', 'Sucre', 'Vitamines', 'Minéraux'],
  ingredientCount: 12,
  nutrition: {
    items: [{
      name: 'Matières grasses',
      value: '1.2g',
      level: 'low',
      icon: 'check'
    }, {
      name: 'Acides gras saturés',
      value: '0.3g',
      level: 'low',
      icon: 'check'
    }, {
      name: 'Sucres',
      value: '22.4g',
      level: 'high',
      icon: 'circle-x'
    }, {
      name: 'Sel',
      value: '0.3g',
      level: 'moderate',
      icon: 'warning'
    }],
    energy: '1632 kJ',
    energyKcal: '388 kcal',
    carbohydrates: '78.5g',
    proteins: '9.8g',
    fiber: '3.2g'
  }
}, {
  id: 5,
  name: 'Petit pot légumes',
  fullName: 'Petit pot légumes variés bio',
  brand: 'Hipp',
  weight: '190g',
  category: 'Légumes',
  imageUrl: '/lovable-uploads/59ff6c0f-33d6-4bc5-9851-72dcc2d4dfb0.png',
  novaScore: 1,
  nutriScore: 'A',
  status: 'Autorisé sans modération',
  warnings: [],
  allergens: [],
  ingredients: ['Carottes', 'Pommes de terre', 'Petits pois', 'Eau'],
  ingredientCount: 7,
  nutrition: {
    items: [{
      name: 'Matières grasses',
      value: '0.3g',
      level: 'low',
      icon: 'check'
    }, {
      name: 'Acides gras saturés',
      value: '0.1g',
      level: 'low',
      icon: 'check'
    }, {
      name: 'Sucres',
      value: '2.6g',
      level: 'low',
      icon: 'check'
    }, {
      name: 'Sel',
      value: '0.05g',
      level: 'low',
      icon: 'check'
    }],
    energy: '113 kJ',
    energyKcal: '27 kcal',
    carbohydrates: '4.9g',
    proteins: '0.9g',
    fiber: '1.8g'
  }
}, {
  id: 6,
  name: 'Nutella',
  fullName: 'Glace Batonnet Classic Magnum 400g',
  brand: 'Ferrero',
  weight: '400g',
  category: 'Pâte à tartiner',
  imageUrl: '/lovable-uploads/506f4aa4-528d-4b94-a32d-e54394805d40.png',
  novaScore: 1,
  nutriScore: 'A',
  status: 'Autorisé sous conditions',
  warnings: ['Les poissons fumés sont interdits pendant la grossesse car ils présentent un risque élevé de contamination par la listeria.'],
  allergens: ['Présence d\'un de vos allergènes'],
  ingredients: ['Sucre', 'Huile de palme', 'Noisettes', 'Cacao maigre', 'Lait écrémé en poudre', 'Lactoserum en poudre', 'Lécithine de soja', 'Vanilline'],
  ingredientCount: 34,
  nutrition: {
    items: [{
      name: 'Matières grasses en quantité modérée',
      value: '12g',
      level: 'moderate',
      icon: 'warning'
    }, {
      name: 'Acides gras saturés en quantité élevée',
      value: '8.1g',
      level: 'high',
      icon: 'circle-x'
    }, {
      name: 'Sucres en quantité élevée',
      value: '23g',
      level: 'high',
      icon: 'circle-x'
    }, {
      name: 'Sel en faible quantité',
      value: '0.05g',
      level: 'low',
      icon: 'check'
    }],
    energy: '2256 kJ',
    energyKcal: '539 kcal',
    carbohydrates: '57.5g',
    proteins: '6.3g',
    fiber: '3.4g'
  }
}];
