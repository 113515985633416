import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Camera, History, Search, FileText, Settings, ArrowLeft, ChevronRight, AlertTriangle, Flag, Barcode, Eye } from 'lucide-react';
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/components/ui/carousel";
import { useIsMobile } from '@/hooks/use-mobile';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { Card } from '@/components/ui/card';
import { BarcodeScanner } from '@/components/BarcodeScanner';
import { getHistory } from '@/services/historyService';
import { Product } from '@/utils/productUtils';
import { useAuth } from '@/hooks/useAuth';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import BottomNavbar from '@/components/BottomNavbar';
import { useProductViewLimit } from '@/hooks/useProductViewLimit';
interface BlogPost {
  id: string;
  title: string;
  excerpt: string;
  category: string;
  image: string;
  date: string;
}
const blogArticles = [{
  id: 1,
  title: "Nutrition pendant la grossesse : les clés d'une alimentation saine",
  excerpt: "La nutrition pendant la grossesse joue un rôle essentiel pour le bien-être de la mère et le développement du bébé.",
  category: 'Nutrition',
  image: 'https://images.unsplash.com/photo-1490818387583-1baba5e638af?q=80&w=1932&auto=format&fit=crop',
  date: '24/01/2025'
}, {
  id: 2,
  title: "Les fruits et légumes de saison : pourquoi les privilégier",
  excerpt: "Consommer des produits de saison présente de nombreux avantages pour la santé et l'environnement.",
  category: 'Conseils',
  image: 'https://images.unsplash.com/photo-1610832958506-aa56368176cf?q=80&w=1170&auto=format&fit=crop',
  date: '15/01/2025'
}, {
  id: 3,
  title: "Mythes et réalités sur les aliments bio",
  excerpt: "Démêler le vrai du faux concernant les produits biologiques et leur impact sur la santé.",
  category: 'Mythes & réalités',
  image: 'https://images.unsplash.com/photo-1506484381205-f7945653044d?q=80&w=1170&auto=format&fit=crop',
  date: '10/01/2025'
}];
const HomePage = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const {
    user,
    isAuthenticated
  } = useAuth();
  const {
    viewsRemaining
  } = useProductViewLimit();
  const [barcodeDetected, setBarcodeDetected] = useState(false);
  const [showProductSummary, setShowProductSummary] = useState(false);
  const [scannedCode, setScannedCode] = useState<string | null>(null);
  const [recentScans, setRecentScans] = useState<Product[]>([]);
  const [recentBlogPosts, setRecentBlogPosts] = useState<BlogPost[]>([]);
  const [loadingBlogPosts, setLoadingBlogPosts] = useState(true);
  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const history = await getHistory(5);
        setRecentScans(history);
      } catch (error) {
        console.error("Erreur lors de la récupération de l'historique:", error);
        setRecentScans([]);
      }
    };
    fetchHistory();
  }, []);
  useEffect(() => {
    const fetchRecentBlogPosts = async () => {
      try {
        setLoadingBlogPosts(true);
        const {
          data,
          error
        } = await supabase.from('blog_posts').select('id, title, excerpt, category, image, date').order('created_at', {
          ascending: false
        }).limit(5);
        if (error) {
          console.error("Erreur lors de la récupération des articles:", error);
          return;
        }
        if (data) {
          const updatedData = data.map(post => {
            if (post.image && post.image.startsWith('/lovable-uploads/')) {
              const category = post.category.toLowerCase();
              let unsplashUrl = '';
              if (category.includes('nutrition')) {
                unsplashUrl = 'https://images.unsplash.com/photo-1512621776951-a57141f2eefd?q=80&w=1170&auto=format&fit=crop';
              } else if (category.includes('conseils')) {
                unsplashUrl = 'https://images.unsplash.com/photo-1498837167922-ddd27525d352?q=80&w=1170&auto=format&fit=crop';
              } else if (category.includes('bien-être') || category.includes('bien-etre')) {
                unsplashUrl = 'https://images.unsplash.com/photo-1507120410856-1f35574c3b45?q=80&w=1170&auto=format&fit=crop';
              } else if (category.includes('recettes')) {
                unsplashUrl = 'https://images.unsplash.com/photo-1504674900247-0877df9cc836?q=80&w=1170&auto=format&fit=crop';
              } else if (category.includes('mythes')) {
                unsplashUrl = 'https://images.unsplash.com/photo-1505253758473-96b7015fcd40?q=80&w=1170&auto=format&fit=crop';
              } else {
                unsplashUrl = 'https://images.unsplash.com/photo-1576402187878-974f70c890a5?q=80&w=1333&auto=format&fit=crop';
              }
              return {
                ...post,
                image: unsplashUrl
              };
            }
            return post;
          });
          setRecentBlogPosts(updatedData);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des articles:", error);
      } finally {
        setLoadingBlogPosts(false);
      }
    };
    fetchRecentBlogPosts();
  }, []);
  const handleViewArticle = (articleId: string) => {
    navigate(`/blog/${articleId}`);
  };
  const handleViewProduct = (productId: number) => {
    navigate(`/product/${productId}`);
  };
  const handleScanClose = () => {
    console.log('Scan closed');
  };
  const handleCodeScanned = (code: string) => {
    console.log('Code-barres scanné:', code);
    navigate(`/product/${code}`);
  };
  return <div className="flex flex-col min-h-screen bg-[#fff5ff] pb-16 md:pb-0 safe-area">
      <div className="bg-[#fff5ff] py-4 px-4 flex items-center justify-between border-b safe-top">
        <h1 className="text-xl font-bold">Accueil</h1>
        {/* All users have unlimited scans now */}
        
      </div>

      {!showProductSummary && !barcodeDetected && <>
          <div className="px-4 pt-4 safe-top">
            <Card className="w-full overflow-hidden border-none shadow-lg rounded-xl mb-4">
              <div className="h-full relative" style={{
            minHeight: '240px'
          }}>
                <BarcodeScanner onScan={handleCodeScanned} onClose={handleScanClose} />
              </div>
            </Card>
            
            <h2 className="text-lg font-semibold mb-3">Derniers scans</h2>
            <Carousel className="w-full">
              <CarouselContent>
                {recentScans.length > 0 ? recentScans.map(product => <CarouselItem key={product.id} className="basis-auto">
                    <div className="min-w-[180px] h-[140px] rounded-lg overflow-hidden relative flex-shrink-0 cursor-pointer" onClick={() => handleViewProduct(product.id)}>
                      <img src={product.imageUrl} alt={product.name} className="w-full h-full object-cover" />
                      <div className="absolute bottom-0 left-0 right-0 bg-black/50 text-white p-2 text-sm">
                        {product.name} - {product.brand} - {product.weight}
                      </div>
                    </div>
                  </CarouselItem>) : <CarouselItem className="basis-auto">
                    <div className="min-w-[180px] h-[140px] rounded-lg overflow-hidden relative flex-shrink-0 bg-gradient-to-br from-nutrimam-pink to-white flex flex-col items-center justify-center p-4">
                      <Barcode className="h-10 w-10 text-nutrimam-heart mb-2 opacity-80" />
                      <p className="text-gray-600 text-sm text-center">Aucun produit scanné récemment</p>
                    </div>
                  </CarouselItem>}
              </CarouselContent>
              <div className="hidden md:flex">
                <CarouselPrevious />
                <CarouselNext />
              </div>
            </Carousel>
          </div>
          
          <div className="p-4 flex-1 pb-24">
            <div className="flex justify-between items-center mb-3">
              <h2 className="text-lg font-semibold">Derniers articles</h2>
              <button className="text-pink-400 text-sm font-medium flex items-center" onClick={() => navigate('/blog')}>
                Voir tous les articles
                <ChevronRight className="h-4 w-4 ml-1" />
              </button>
            </div>
            
            <Carousel className="w-full">
              <CarouselContent>
                {loadingBlogPosts ? <CarouselItem className="md:basis-1/2 lg:basis-1/3">
                    <div className="h-40 w-full bg-gray-200 animate-pulse rounded-lg"></div>
                  </CarouselItem> : recentBlogPosts.length > 0 ? recentBlogPosts.map(article => <CarouselItem key={article.id} className="md:basis-1/2 lg:basis-1/3">
                      <Card className="overflow-hidden rounded-xl cursor-pointer hover:shadow-md transition-shadow h-full" onClick={() => handleViewArticle(article.id)}>
                        <div className="relative">
                          <img src={article.image} alt={article.title} className="w-full h-40 object-cover" />
                          <div className="absolute top-4 left-4">
                            <Badge className="bg-white text-pink-400 rounded-full hover:bg-white/90">
                              {article.category}
                            </Badge>
                          </div>
                        </div>
                        
                        <div className="p-4">
                          <h3 className="font-bold mb-1">{article.title}</h3>
                          <p className="text-sm text-gray-700 line-clamp-2">{article.excerpt}</p>
                          
                          <div className="flex justify-between items-center mt-2">
                            <span className="text-xs text-gray-500">{article.date}</span>
                            <div className="flex items-center text-xs font-medium text-pink-400">
                              Lire plus <ArrowLeft className="h-3 w-3 ml-1 rotate-180" />
                            </div>
                          </div>
                        </div>
                      </Card>
                    </CarouselItem>) : <CarouselItem className="md:basis-1/2 lg:basis-1/3">
                    <Card className="h-full p-8 flex items-center justify-center">
                      <p className="text-gray-500">Aucun article disponible.</p>
                    </Card>
                  </CarouselItem>}
              </CarouselContent>
              <div className="hidden md:flex">
                <CarouselPrevious />
                <CarouselNext />
              </div>
            </Carousel>
          </div>
        </>}

      <BottomNavbar />
    </div>;
};
export default HomePage;