
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, AlertTriangle } from 'lucide-react';
import { Switch } from '@/components/ui/switch';
import { Alert, AlertDescription } from '@/components/ui/alert';
import BottomNavbar from '@/components/BottomNavbar';

// List of common allergens
const allergens = [
  "Anhydride sulfureux et sulfites",
  "Arachides",
  "Céleri",
  "Crustacés",
  "Gluten",
  "Graines de sésame",
  "Fruits à coque",
  "Lait",
  "Moutarde",
  "Mollusques",
  "Oeufs",
  "Soja",
  "Sésame"
];

const AllergyPage = () => {
  const navigate = useNavigate();
  // Initialize state for each allergen (all false by default)
  const [allergies, setAllergies] = useState<{ [key: string]: boolean }>(
    allergens.reduce((acc, allergen) => ({ ...acc, [allergen]: false }), {})
  );
  
  // State to check if any allergy is selected
  const [hasSelectedAllergies, setHasSelectedAllergies] = useState(false);

  // Update hasSelectedAllergies whenever allergies change
  useEffect(() => {
    const anySelected = Object.values(allergies).some(value => value);
    setHasSelectedAllergies(anySelected);
  }, [allergies]);

  const toggleAllergy = (allergen: string) => {
    setAllergies(prev => ({
      ...prev,
      [allergen]: !prev[allergen]
    }));
  };

  return (
    <div className="flex flex-col min-h-screen bg-[#FFDEE2] pb-16 md:pb-0">
      {/* Header */}
      <div className="bg-[#FFDEE2] py-4 px-4 flex items-center border-b">
        <button className="mr-2" onClick={() => navigate(-1)}>
          <ArrowLeft className="h-6 w-6" />
        </button>
        <h1 className="text-xl font-bold">Mes allergies</h1>
      </div>

      {/* Allergies Content */}
      <div className="flex-1 px-4 pt-4 pb-24">
        <p className="text-gray-600 italic mb-6">Des allergies? renseignez les ici.</p>

        <div className="space-y-4">
          {allergens.map((allergen) => (
            <div 
              key={allergen} 
              className="flex items-center justify-between bg-white rounded-lg p-3 shadow-sm"
            >
              <div className="flex items-center">
                <div className="w-6 h-6 rounded-full bg-pink-400 flex items-center justify-center mr-3">
                  <span className="text-white text-sm">!</span>
                </div>
                <span className="text-gray-700">{allergen}</span>
              </div>
              <Switch
                checked={allergies[allergen]}
                onCheckedChange={() => toggleAllergy(allergen)}
                className="data-[state=checked]:bg-pink-400"
              />
            </div>
          ))}
        </div>
      </div>
      
      {/* Warning Alert */}
      {hasSelectedAllergies && (
        <div className="px-4 mb-20">
          <Alert variant="destructive" className="bg-[#f8f9fa] border border-red-200 text-red-500">
            <AlertTriangle className="h-4 w-4" />
            <AlertDescription className="text-sm">
              Il est toujours possible que les données relatives aux allergènes soient manquantes, incomplètes ou incorrectes, ou que la composition du produit ait changé. Il convient de toujours procéder à une vérification sur l'emballage.
            </AlertDescription>
          </Alert>
        </div>
      )}

      {/* Bottom Navigation Bar */}
      <BottomNavbar />
    </div>
  );
};

export default AllergyPage;
