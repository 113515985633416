
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, User, Calendar, Mail, Phone } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import useAuth from '@/hooks/useAuth';
import { format } from 'date-fns';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import BottomNavbar from '@/components/BottomNavbar';

const ProfilePage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [profileData, setProfileData] = useState({
    firstName: '',
    lastName: '',
    babyBirthDate: '',
    email: '',
    phone: '',
    babySex: ''
  });
  const [isLoading, setIsLoading] = useState(true);
  
  const fetchProfileData = async () => {
    setIsLoading(true);
    try {
      if (user?.id) {
        console.log("Fetching profile data for user ID:", user.id);
        const { data, error } = await supabase
          .from('profiles')
          .select('*')
          .eq('id', user.id)
          .single();
          
        if (error) {
          console.error("Erreur lors de la récupération du profil:", error);
          toast.error("Erreur lors du chargement du profil");
          return;
        }
        
        if (data) {
          console.log("Profile data received:", data);
          setProfileData({
            firstName: data.first_name || '',
            lastName: data.last_name || '',
            babyBirthDate: data.due_date ? data.due_date : '',
            email: data.email || '',
            phone: data.phone || '',
            babySex: data.baby_sex || ''
          });
        }
      }
    } catch (error) {
      console.error("Erreur:", error);
      toast.error("Erreur lors du chargement du profil");
    } finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    if (user) {
      fetchProfileData();
    }
  }, [user]);

  const getBabySexLabel = (sex: string) => {
    switch (sex) {
      case 'boy': return 'Garçon';
      case 'girl': return 'Fille';
      case 'surprise': return 'On garde la surprise';
      default: return '';
    }
  };

  // Log profile data for debugging
  console.log("Current profile data state:", profileData);

  return (
    <div className="flex flex-col min-h-screen bg-[#fff5ff] pb-16 md:pb-0 safe-area">
      <div className="bg-[#fff5ff] py-4 px-4 flex items-center border-b safe-top">
        <button className="mr-2" onClick={() => navigate(-1)}>
          <ArrowLeft className="h-6 w-6" />
        </button>
        <h1 className="text-xl font-bold">Mon profil</h1>
      </div>

      <div className="flex-1 px-4 py-4">
        <div className="space-y-0 mb-4">
          <div className="bg-pink-400 rounded-t-xl p-3">
            <h2 className="text-white font-semibold">Mes informations</h2>
          </div>
          
          <div className="bg-white rounded-b-xl overflow-hidden shadow-sm p-4 space-y-4">
            {isLoading ? (
              <div className="py-4 text-center">Chargement des données...</div>
            ) : (
              <>
                <div className="flex items-center space-x-3 border-b pb-3">
                  <User className="h-5 w-5 text-pink-400" />
                  <div>
                    <Label className="text-sm text-gray-500">Nom</Label>
                    <Input 
                      value={profileData.lastName} 
                      className="border-none px-0 py-0 h-auto text-pink-400 font-medium focus-visible:ring-0" 
                      readOnly 
                    />
                  </div>
                </div>

                <div className="flex items-center space-x-3 border-b pb-3">
                  <User className="h-5 w-5 text-pink-400" />
                  <div>
                    <Label className="text-sm text-gray-500">Prénom</Label>
                    <Input 
                      value={profileData.firstName} 
                      className="border-none px-0 py-0 h-auto text-pink-400 font-medium focus-visible:ring-0" 
                      readOnly 
                    />
                  </div>
                </div>

                <div className="flex items-center space-x-3 border-b pb-3">
                  <Calendar className="h-5 w-5 text-pink-400" />
                  <div>
                    <Label className="text-sm text-gray-500">Date prévue du terme</Label>
                    <Input 
                      value={profileData.babyBirthDate} 
                      className="border-none px-0 py-0 h-auto text-pink-400 font-medium focus-visible:ring-0" 
                      readOnly 
                    />
                  </div>
                </div>

                <div className="flex items-center space-x-3 border-b pb-3">
                  <User className="h-5 w-5 text-pink-400" />
                  <div>
                    <Label className="text-sm text-gray-500">Sexe du bébé</Label>
                    <Input 
                      value={getBabySexLabel(profileData.babySex)} 
                      className="border-none px-0 py-0 h-auto text-pink-400 font-medium focus-visible:ring-0" 
                      readOnly 
                    />
                  </div>
                </div>

                <div className="flex items-center space-x-3 border-b pb-3">
                  <Mail className="h-5 w-5 text-pink-400" />
                  <div>
                    <Label className="text-sm text-gray-500">Email</Label>
                    <Input 
                      value={profileData.email} 
                      className="border-none px-0 py-0 h-auto text-pink-400 font-medium focus-visible:ring-0" 
                      readOnly 
                    />
                  </div>
                </div>

                <div className="flex items-center space-x-3">
                  <Phone className="h-5 w-5 text-pink-400" />
                  <div>
                    <Label className="text-sm text-gray-500">Téléphone</Label>
                    <Input 
                      value={profileData.phone} 
                      className="border-none px-0 py-0 h-auto text-pink-400 font-medium focus-visible:ring-0" 
                      readOnly 
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="mt-6 px-4">
          <Button 
            className="w-full bg-pink-400 hover:bg-pink-500"
            onClick={() => navigate('/edit-profile')}
          >
            Modifier mon profil
          </Button>
        </div>
      </div>

      <BottomNavbar />
    </div>
  );
};

export default ProfilePage;
