
import { Product } from '@/utils/productUtils';
import { HistoryEntry, MAX_HISTORY_ITEMS } from './types';

/**
 * Adds a product to the local history storage
 */
export const addToLocalHistory = (product: Product, preventDuplication: boolean = false): void => {
  try {
    // Get existing history
    const historyString = localStorage.getItem('product_scan_history');
    const history: HistoryEntry[] = historyString ? JSON.parse(historyString) : [];
    
    // If we want to prevent duplications, check if the product already exists in recent history
    if (preventDuplication) {
      const existingProduct = history.find(item => item.id === product.id);
      if (existingProduct) {
        console.log('Produit déjà dans l\'historique local récent, pas d\'ajout');
        return;
      }
    }
    
    // Each entry receives a unique ID, even if the product already exists
    const newEntry: HistoryEntry = {
      ...product,
      entryId: Date.now().toString() + '-' + Math.random().toString(36).substr(2, 9)
    };
    
    // Add product to the beginning of the array
    history.unshift(newEntry);
    
    // Trim history to maximum length
    const trimmedHistory = history.slice(0, MAX_HISTORY_ITEMS);
    
    // Save back to localStorage
    localStorage.setItem('product_scan_history', JSON.stringify(trimmedHistory));
  } catch (error) {
    console.error('Erreur lors de l\'enregistrement du produit dans l\'historique local:', error);
  }
};

/**
 * Retrieves history from local storage
 */
export const getLocalHistory = (limit?: number): HistoryEntry[] => {
  try {
    const historyString = localStorage.getItem('product_scan_history');
    const history = historyString ? JSON.parse(historyString) : [];
    
    // If limit is provided, return only that number of items
    return limit ? history.slice(0, limit) : history;
  } catch (error) {
    console.error('Erreur lors de la récupération de l\'historique local:', error);
    return [];
  }
};

/**
 * Removes an entry from local history
 */
export const removeFromLocalHistory = (entryId: string): void => {
  try {
    const historyString = localStorage.getItem('product_scan_history');
    if (!historyString) return;
    
    const history: HistoryEntry[] = JSON.parse(historyString);
    const updatedHistory = history.filter(item => item.entryId !== entryId);
    
    localStorage.setItem('product_scan_history', JSON.stringify(updatedHistory));
  } catch (error) {
    console.error('Erreur lors de la suppression de l\'entrée de l\'historique local:', error);
  }
};
