
import React from 'react';
import { useNavigate } from 'react-router-dom';
import BottomNavbar from '@/components/BottomNavbar';
import SearchHeader from '@/components/search/SearchHeader';
import SearchBar from '@/components/search/SearchBar';
import SearchResults from '@/components/search/SearchResults';
import { useProductSearch } from '@/hooks/useProductSearch';

const SearchPage = () => {
  const navigate = useNavigate();
  const { 
    searchQuery, 
    setSearchQuery, 
    searchTerm, 
    data, 
    isLoading, 
    error, 
    nutriScoreImages, 
    handleSearch 
  } = useProductSearch();

  const handleProductClick = (productCode: string) => {
    navigate(`/product/${productCode}`);
  };

  return (
    <div className="flex flex-col min-h-screen bg-white pb-16 md:pb-0 safe-area">
      <SearchHeader />
      
      <SearchBar 
        searchQuery={searchQuery} 
        setSearchQuery={setSearchQuery} 
        handleSearch={handleSearch} 
      />

      <div className="px-4 space-y-4 mb-4 pb-24">
        <SearchResults 
          isLoading={isLoading} 
          error={error} 
          searchTerm={searchTerm} 
          data={data} 
          nutriScoreImages={nutriScoreImages} 
          onProductClick={handleProductClick} 
        />
      </div>

      <BottomNavbar />
    </div>
  );
};

export default SearchPage;

