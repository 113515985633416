import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, AlertTriangle, Camera, History, Search, FileText, Settings, Leaf, PieChart, Tag, Cog, Info, Flag } from 'lucide-react';
import { useQuery } from '@tanstack/react-query';
import { useToast } from '@/hooks/use-toast';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { getNutriScoreImage, getNovaScoreColor, getNovaScoreExplanation } from '@/utils/productUtils';
import { fetchProductById } from '@/services/productService';
import { categorizeProduct, CategoryType } from '@/hooks/useCategorizeProduct';
import { Badge } from '@/components/ui/badge';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { Separator } from '@/components/ui/separator';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Textarea } from '@/components/ui/textarea';
import { Input } from '@/components/ui/input';
import BottomNavbar from '@/components/BottomNavbar';
import { useAuth } from '@/hooks/useAuth';
import { supabase } from '@/integrations/supabase/client';
import { useProductViewLimit, incrementProductView } from '@/hooks/useProductViewLimit';
import ProductViewLimitModal from '@/components/ProductViewLimitModal';

const ProductDetailPage = () => {
  const {
    id
  } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { user } = useAuth();
  
  const { canViewProduct, isLimitReached, nextResetDate } = useProductViewLimit();
  const [showLimitModal, setShowLimitModal] = useState(false);
  
  const [nutriScoreImage, setNutriScoreImage] = useState('');
  const [categoryResult, setCategoryResult] = useState<{
    type: CategoryType;
    explanation: string;
    name?: string;
  } | null>(null);
  const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);
  const [reportText, setReportText] = useState('');

  useEffect(() => {
    if (user?.id && id) {
      incrementProductView(user.id);
    }
  }, [user, id]);

  const {
    data: product,
    isLoading,
    error
  } = useQuery({
    queryKey: ['product', id],
    queryFn: () => fetchProductById(id || ''),
    meta: {
      onError: (err: any) => {
        toast({
          title: "Erreur",
          description: err.message || "Impossible de charger les détails du produit",
          variant: "destructive"
        });
      }
    },
    enabled: canViewProduct
  });

  useEffect(() => {
    const loadNutriScoreImage = async () => {
      if (product?.nutriScore) {
        try {
          const imageUrl = await getNutriScoreImage(product.nutriScore.toLowerCase());
          setNutriScoreImage(imageUrl);
        } catch (error) {
          console.error('Error loading nutriscore image:', error);
        }
      } else {
        try {
          const imageUrl = await getNutriScoreImage('unknown');
          setNutriScoreImage(imageUrl);
        } catch (error) {
          console.error('Error loading unknown nutriscore image:', error);
        }
      }
    };
    loadNutriScoreImage();
  }, [product]);

  useEffect(() => {
    if (product) {
      const result = categorizeProduct(product);
      setCategoryResult(result);
    }
  }, [product]);

  const getStatusStyles = (type: CategoryType) => {
    switch (type) {
      case 'forbidden':
        return 'bg-red-100 text-red-800 border-red-300';
      case 'authorized-with-warning':
        return 'bg-amber-100 text-amber-800 border-amber-300';
      case 'authorized-with-moderation':
        return 'bg-yellow-100 text-yellow-800 border-yellow-300';
      case 'authorized':
        return 'bg-green-100 text-green-800 border-green-300';
      case 'lack-of-information':
        return 'bg-gray-100 text-gray-800 border-gray-300';
      default:
        return 'bg-gray-100 text-gray-800 border-gray-300';
    }
  };

  const getStatusTitle = (type: CategoryType) => {
    switch (type) {
      case 'forbidden':
        return 'Interdit pendant la grossesse';
      case 'authorized-with-warning':
        return 'Autorisé avec précautions pendant la grossesse';
      case 'authorized-with-moderation':
        return 'Autorisé avec modération pendant la grossesse';
      case 'authorized':
        return 'Autorisé pendant la grossesse';
      case 'lack-of-information':
        return 'Information incomplète';
      default:
        return 'Information non disponible';
    }
  };

  const getStatusIcon = (type: CategoryType) => {
    switch (type) {
      case 'forbidden':
        return '⛔';
      case 'authorized-with-warning':
        return '⚠️';
      case 'authorized-with-moderation':
        return '🔶';
      case 'authorized':
        return '✅';
      case 'lack-of-information':
        return '❓';
      default:
        return '❓';
    }
  };

  const handleReportSubmit = () => {
    const subject = `Erreur : ${id}`;
    const body = reportText;
    const mailtoLink = `mailto:nutrimam.contact@gmail.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    
    window.location.href = mailtoLink;
    
    setIsReportDialogOpen(false);
  };

  return (
    <div className="flex flex-col min-h-screen bg-white pb-24 safe-area">
      <div className="bg-white py-4 px-4 flex items-center border-b safe-top">
        <button className="mr-2" onClick={() => navigate(-1)}>
          <ArrowLeft className="h-6 w-6" />
        </button>
        <h1 className="text-xl font-bold">Détails du produit</h1>
      </div>

      {isLoading ? <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-pink-500"></div>
        </div> : error ? <div className="p-4 mx-4 my-6 bg-red-50 text-red-600 rounded-xl flex items-center justify-center">
          <AlertTriangle className="mr-2 h-5 w-5" />
          Une erreur est survenue lors du chargement du produit
        </div> : product ? <div className="flex-1 bg-white px-4 py-6 space-y-6">
          <Card className="transform hover:scale-[1.01] transition-all duration-200 shadow-lg hover:shadow-xl border-2">
            <CardContent className="p-4">
              <div className="flex items-start">
                <div className="w-24 h-24 flex-shrink-0 mr-4">
                  <img src={product.imageUrl || '/placeholder.svg'} alt={product.name} className="w-full h-full object-contain" onError={e => {
                (e.target as HTMLImageElement).src = '/placeholder.svg';
              }} />
                </div>
                <div className="flex-1">
                  <h2 className="text-xl font-bold">{product.name}</h2>
                  <p className="text-sm text-gray-600">{product.brand}</p>
                  <p className="text-xs text-gray-500">{product.weight}</p>
                  
                  <div className="mt-4 flex flex-wrap items-center gap-4">
                    {nutriScoreImage && <div className="flex items-center">
                        <img src={nutriScoreImage} alt={`Nutri-Score ${product.nutriScore || 'Inconnu'}`} className="h-10" onError={() => {
                    console.log('Error loading nutriscore image');
                  }} />
                        
                        {product.novaScore > 0 && <div className="flex items-center ml-3">
                            <span className="text-xs mr-1">NOVA</span>
                            <div className={`w-8 h-8 rounded-full ${getNovaScoreColor(product.novaScore)} flex items-center justify-center text-white font-bold`}>
                              {product.novaScore}
                            </div>
                            <span className="text-xs text-gray-600 ml-1 hidden sm:inline-block">
                              {getNovaScoreExplanation(product.novaScore).split(' - ')[1] || ''}
                            </span>
                          </div>}
                      </div>}
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>

          {categoryResult && <div className={`p-4 rounded-lg border-2 shadow-lg transform hover:scale-[1.01] transition-all duration-200 ${getStatusStyles(categoryResult.type)}`}>
              <div className="flex items-start">
                <span className="text-2xl mr-3">{getStatusIcon(categoryResult.type)}</span>
                <div>
                  <h3 className="font-bold text-lg">{getStatusTitle(categoryResult.type)}</h3>
                  {categoryResult.name}
                  <p className="mt-2 text-sm">{categoryResult.explanation}</p>
                </div>
              </div>
            </div>}

          <Card className="border-2 border-[#ea384c] bg-red-50 shadow-lg transform hover:scale-[1.01] transition-all duration-200">
            <CardHeader className="pb-2">
              <div className="flex items-center">
                <AlertTriangle className="h-6 w-6 text-[#ea384c] mr-2" />
                <CardTitle className="text-lg text-[#ea384c]">Allergènes</CardTitle>
              </div>
            </CardHeader>
            <CardContent>
              {product.allergens && product.allergens.length > 0 ? <ul className="list-disc pl-5 space-y-1">
                  {product.allergens.map((allergen, index) => <li key={index} className="text-sm font-medium">
                      <Badge variant="outline" className="bg-red-100 text-red-800 border-red-300 mr-2">
                        {allergen}
                      </Badge>
                    </li>)}
                </ul> : <p className="text-gray-500 text-sm">Aucun allergène répertorié</p>}
            </CardContent>
          </Card>

          <Accordion type="single" collapsible className="w-full">
            <AccordionItem value="ingredients" className="shadow-md hover:shadow-lg transition-all duration-200 mb-3">
              <AccordionTrigger className="py-4 px-4 bg-white/75 backdrop-blur-lg rounded-t-lg border-2 border-pink-400 font-medium text-lg">
                <div className="flex flex-col items-start">
                  <div className="flex items-center">
                    <div className="w-8 h-8 rounded-full bg-[#F2FCE2] flex items-center justify-center mr-2">
                      <Leaf className="h-5 w-5 text-green-600" />
                    </div>
                    <span>Ingrédients</span>
                  </div>
                  {product.ingredientCount > 0 && <span className="text-xs text-gray-500 ml-10">({product.ingredientCount})</span>}
                </div>
              </AccordionTrigger>
              <AccordionContent className="bg-white/75 backdrop-blur-lg rounded-b-lg border-2 border-pink-400 px-4">
                {product.ingredients && product.ingredients.length > 0 ? <ul className="list-disc pl-5 space-y-1 text-sm py-4">
                    {product.ingredients.map((ingredient, index) => <li key={index}>{ingredient}</li>)}
                  </ul> : <p className="text-gray-500 text-sm py-4">Aucun ingrédient listé</p>}
              </AccordionContent>
            </AccordionItem>
            
            <AccordionItem value="nutriscore" className="mb-3 shadow-md hover:shadow-lg transition-all duration-200">
              <AccordionTrigger className="py-4 px-4 bg-white/75 backdrop-blur-lg rounded-t-lg border-2 border-pink-400 font-medium text-lg">
                <div className="flex items-center">
                  <div className="w-8 h-8 rounded-full bg-[#E6F7FF] flex items-center justify-center mr-2">
                    <Info className="h-5 w-5 text-blue-600" />
                  </div>
                  <span>Nutri-Score</span>
                </div>
              </AccordionTrigger>
              <AccordionContent className="bg-white/75 backdrop-blur-lg rounded-b-lg border-2 border-t-0 border-pink-400 px-4">
                <div className="py-4">
                  <div className="flex items-center mb-4">
                    <span className="font-medium mr-2">Score:</span>
                    {nutriScoreImage && <img src={nutriScoreImage} alt={`Nutri-Score ${product.nutriScore || 'Inconnu'}`} className="h-8" />}
                  </div>
                  
                  <div className="mt-2">
                    <Separator className="my-3" />
                    <h4 className="font-medium mb-2">À propos du Nutri-Score</h4>
                    <p className="text-sm mb-3">Le Nutri-Score est un système d'étiquetage nutritionnel qui note les aliments de A à E:</p>
                    
                    <div className="space-y-2 mb-4">
                      <div className="flex items-center bg-[#0A8E45] bg-opacity-10 p-2 rounded">
                        <div className="w-6 h-6 bg-[#0A8E45] text-white font-bold rounded-full flex items-center justify-center mr-2">A</div>
                        <span className="text-sm">Excellente qualité nutritionnelle</span>
                      </div>
                      <div className="flex items-center bg-[#7AC547] bg-opacity-10 p-2 rounded">
                        <div className="w-6 h-6 bg-[#7AC547] text-white font-bold rounded-full flex items-center justify-center mr-2">B</div>
                        <span className="text-sm">Bonne qualité nutritionnelle</span>
                      </div>
                      <div className="flex items-center bg-[#FFC734] bg-opacity-10 p-2 rounded">
                        <div className="w-6 h-6 bg-[#FFC734] text-white font-bold rounded-full flex items-center justify-center mr-2">C</div>
                        <span className="text-sm">Qualité nutritionnelle moyenne</span>
                      </div>
                      <div className="flex items-center bg-[#FF7D24] bg-opacity-10 p-2 rounded">
                        <div className="w-6 h-6 bg-[#FF7D24] text-white font-bold rounded-full flex items-center justify-center mr-2">D</div>
                        <span className="text-sm">Qualité nutritionnelle médiocre</span>
                      </div>
                      <div className="flex items-center bg-[#FF6B6B] bg-opacity-10 p-2 rounded">
                        <div className="w-6 h-6 bg-[#FF6B6B] text-white font-bold rounded-full flex items-center justify-center mr-2">E</div>
                        <span className="text-sm">Faible qualité nutritionnelle</span>
                      </div>
                    </div>

                    <div className="p-3 bg-gray-50/80 backdrop-blur-sm rounded-lg text-sm">
                      <p className="font-medium mb-1">Pendant la grossesse :</p>
                      <p>Les aliments avec un Nutri-Score A ou B sont généralement recommandés pendant la grossesse car ils contiennent moins de graisses saturées, de sucres et de sel, tout en apportant des nutriments essentiels. Pour un régime équilibré, privilégiez ces catégories tout en consommant occasionnellement et en petites portions les aliments notés D ou E.</p>
                    </div>
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>
            
            <AccordionItem value="nova" className="mb-3 shadow-md hover:shadow-lg transition-all duration-200">
              <AccordionTrigger className="py-4 px-4 bg-white/75 backdrop-blur-lg rounded-t-lg border-2 border-pink-400 font-medium text-lg">
                <div className="flex items-center">
                  <div className="w-8 h-8 rounded-full bg-[#FFF3E0] flex items-center justify-center mr-2">
                    <Cog className="h-5 w-5 text-orange-600" />
                  </div>
                  <span className="text-left">Transformation des aliments</span>
                </div>
              </AccordionTrigger>
              <AccordionContent className="bg-white/75 backdrop-blur-lg rounded-b-lg border-2 border-t-0 border-pink-400 px-4">
                <div className="py-4">
                  <div className="flex items-center mb-3">
                    <span className="font-medium mr-2">Score NOVA:</span>
                    {product.novaScore > 0 ? <div className={`w-8 h-8 rounded-full ${getNovaScoreColor(product.novaScore)} flex items-center justify-center text-white font-bold`}>
                        {product.novaScore}
                      </div> : <span className="text-gray-500">Non disponible</span>}
                  </div>
                  
                  {product.novaScore > 0 && <div className="mt-2">
                      <p className="text-sm mb-2">{getNovaScoreExplanation(product.novaScore)}</p>
                      <Separator className="my-3" />
                      <h4 className="font-medium mb-2">À propos du score NOVA</h4>
                      <p className="text-sm mb-2">Le score NOVA est un système de classification des aliments qui les classe selon leur degré de transformation:</p>
                      <ul className="list-disc pl-5 space-y-2 text-sm">
                        <li><span className="font-medium">NOVA 1</span>: Aliments non transformés ou minimalement transformés (fruits, légumes, viandes fraîches)</li>
                        <li><span className="font-medium">NOVA 2</span>: Ingrédients culinaires transformés (huiles, beurre, sucre)</li>
                        <li><span className="font-medium">NOVA 3</span>: Aliments transformés (conserves, fromages, pains)</li>
                        <li><span className="font-medium">NOVA 4</span>: Produits ultra-transformés (snacks, sodas, plats préparés)</li>
                      </ul>
                      
                      <div className="mt-3 p-3 bg-gray-50/80 backdrop-blur-sm rounded-lg text-sm">
                        <p className="font-medium mb-1">Pendant la grossesse :</p>
                        <p>Les aliments peu transformés (NOVA 1-2) sont généralement préférables. Les produits ultra-transformés (NOVA 4) peuvent contenir des additifs, des conservateurs et des teneurs élevées en sucre, sel et graisses dont la consommation devrait être limitée pendant la grossesse.</p>
                      </div>
                    </div>}
                </div>
              </AccordionContent>
            </AccordionItem>
            
            <AccordionItem value="nutrition" className="mb-3 shadow-md hover:shadow-lg transition-all duration-200">
              <AccordionTrigger className="py-4 px-4 bg-white/75 backdrop-blur-lg rounded-t-lg border-2 border-pink-400 font-medium text-lg">
                <div className="flex flex-col items-start">
                  <div className="flex items-center">
                    <div className="w-8 h-8 rounded-full bg-[#D3E4FD] flex items-center justify-center mr-2">
                      <PieChart className="h-5 w-5 text-blue-600" />
                    </div>
                    <span className="text-left">Valeurs nutritionnelles</span>
                  </div>
                  <span className="text-xs text-gray-500 ml-10">(pour 100g)</span>
                </div>
              </AccordionTrigger>
              <AccordionContent className="bg-white/75 backdrop-blur-lg rounded-b-lg border-2 border-t-0 border-pink-400 px-4">
                {product.nutrition && <div className="space-y-4 py-4">
                    <div className="grid grid-cols-2 gap-3">
                      <div className="bg-gray-50/80 backdrop-blur-sm p-3 rounded shadow-sm transform hover:translate-y-[-2px] transition-all">
                        <p className="text-xs text-gray-500">Énergie</p>
                        <p className="font-bold">{product.nutrition.energy}</p>
                      </div>
                      <div className="bg-gray-50/80 backdrop-blur-sm p-3 rounded shadow-sm transform hover:translate-y-[-2px] transition-all">
                        <p className="text-xs text-gray-500">Calories</p>
                        <p className="font-bold">{product.nutrition.energyKcal}</p>
                      </div>
                      <div className="bg-gray-50/80 backdrop-blur-sm p-3 rounded shadow-sm transform hover:translate-y-[-2px] transition-all">
                        <p className="text-xs text-gray-500">Glucides</p>
                        <p className="font-bold">{product.nutrition.carbohydrates}</p>
                      </div>
                      <div className="bg-gray-50/80 backdrop-blur-sm p-3 rounded shadow-sm transform hover:translate-y-[-2px] transition-all">
                        <p className="text-xs text-gray-500">Protéines</p>
                        <p className="font-bold">{product.nutrition.proteins}</p>
                      </div>
                    </div>
                    
                    <div className="mt-6">
                      <h4 className="font-medium mb-2">Tableau nutritionnel</h4>
                      <div className="bg-white/50 backdrop-blur-sm rounded-lg overflow-hidden">
                        <Table>
                          <TableHeader>
                            <TableRow>
                              <TableHead>Nutriment</TableHead>
                              <TableHead>Quantité</TableHead>
                              <TableHead>Niveau</TableHead>
                            </TableRow>
                          </TableHeader>
                          <TableBody>
                            {product.nutrition.items.map((item, index) => <TableRow key={index}>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.value}</TableCell>
                                <TableCell>
                                  <div className="flex items-center">
                                    <div className={`w-3 h-3 rounded-full mr-2 ${item.level === 'low' ? 'bg-green-500' : item.level === 'moderate' ? 'bg-amber-500' : 'bg-red-500'}`}></div>
                                    <span className="text-xs capitalize">
                                      {item.level === 'low' ? 'Faible' : item.level === 'moderate' ? 'Modéré' : 'Élevé'}
                                    </span>
                                  </div>
                                </TableCell>
                              </TableRow>)}
                          </TableBody>
                        </Table>
                      </div>
                    </div>
                  </div>}
              </AccordionContent>
            </AccordionItem>
            
            <AccordionItem value="category" className="shadow-md hover:shadow-lg transition-all duration-200">
              <AccordionTrigger className="py-4 px-4 bg-white/75 backdrop-blur-lg rounded-t-lg border-2 border-pink-400 font-medium text-lg">
                <div className="flex items-center">
                  <div className="w-8 h-8 rounded-full bg-[#FFDEE2] flex items-center justify-center mr-2">
                    <Tag className="h-5 w-5 text-pink-600" />
                  </div>
                  <span>Catégorie</span>
                </div>
              </AccordionTrigger>
              <AccordionContent className="bg-white/75 backdrop-blur-lg rounded-b-lg border-2 border-t-0 border-pink-400 px-4">
                <div className="py-4">
                  {product.categories && product.categories.length > 0 && <div className="mt-2 flex flex-wrap gap-2">
                      {product.categories.map((category, index) => <span key={index} className="text-xs bg-pink-100/80 backdrop-blur-sm text-pink-800 px-2 py-1 rounded-full shadow-sm transform hover:translate-y-[-1px] transition-all">
                          {category}
                        </span>)}
                    </div>}
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>

          <Dialog open={isReportDialogOpen} onOpenChange={setIsReportDialogOpen}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Signaler une erreur</DialogTitle>
                <DialogDescription>
                  Vous avez remarqué une erreur concernant ce produit ? Nous vous remercions de nous la signaler.
                </DialogDescription>
              </DialogHeader>
              <div className="py-4 space-y-4">
                <div className="space-y-1">
                  <label htmlFor="productId" className="text-sm font-medium">Code du produit</label>
                  <Input 
                    id="productId" 
                    value={id || ''}
                    readOnly
                    className="bg-gray-50"
                  />
                </div>
                <div className="space-y-1">
                  <label htmlFor="reportDescription" className="text-sm font-medium">Description du problème</label>
                  <Textarea 
                    id="reportDescription"
                    className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500" 
                    rows={4} 
                    placeholder="Décrivez l'erreur que vous avez constatée..."
                    value={reportText}
                    onChange={(e) => setReportText(e.target.value)}
                  />
                </div>
              </div>
              <DialogFooter>
                <Button variant="secondary" onClick={() => setIsReportDialogOpen(false)}>Annuler</Button>
                <Button onClick={handleReportSubmit}>Envoyer</Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>

          <div className="mt-8 text-center">
            <Button 
              variant="outline" 
              onClick={() => setIsReportDialogOpen(true)}
              className="border-pink-300 text-pink-700 hover:bg-pink-50"
            >
              <Flag className="w-4 h-4 mr-2" />
              Signaler une erreur
            </Button>
          </div>
        </div> : <div className="p-4 text-center text-gray-500">
          Produit non trouvé
        </div>}
        
      <BottomNavbar />
      
      <ProductViewLimitModal 
        open={showLimitModal} 
        onOpenChange={setShowLimitModal}
        nextResetDate={nextResetDate}
      />
    </div>
  );
};

export default ProductDetailPage;
