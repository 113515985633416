
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Lock } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { changePassword } from '@/services/authService';
import { useAuth } from '@/hooks/useAuth';
import BottomNavbar from '@/components/BottomNavbar';

const ChangePasswordPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPasswordData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user types
    if (error) setError('');
    if (successMessage) setSuccessMessage('');
  };
  
  const validateForm = () => {
    if (!passwordData.currentPassword) {
      setError('Veuillez entrer votre mot de passe actuel');
      return false;
    }
    if (!passwordData.newPassword) {
      setError('Veuillez entrer un nouveau mot de passe');
      return false;
    }
    
    // Nouvelles règles de validation
    if (passwordData.newPassword.length < 6) {
      setError('Le nouveau mot de passe doit contenir au moins 6 caractères');
      return false;
    }
    
    // Vérifier la présence d'une lettre minuscule
    if (!/[a-z]/.test(passwordData.newPassword)) {
      setError('Le nouveau mot de passe doit contenir au moins une lettre minuscule');
      return false;
    }
    
    // Vérifier la présence d'une lettre majuscule
    if (!/[A-Z]/.test(passwordData.newPassword)) {
      setError('Le nouveau mot de passe doit contenir au moins une lettre majuscule');
      return false;
    }
    
    // Vérifier la présence d'un chiffre
    if (!/[0-9]/.test(passwordData.newPassword)) {
      setError('Le nouveau mot de passe doit contenir au moins un chiffre');
      return false;
    }
    
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setError('Les mots de passe ne correspondent pas');
      return false;
    }
    if (passwordData.currentPassword === passwordData.newPassword) {
      setError('Le nouveau mot de passe doit être différent du mot de passe actuel');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    setIsSubmitting(true);
    
    const result = await changePassword(passwordData.currentPassword, passwordData.newPassword);
    
    setIsSubmitting(false);
    
    if (result.success) {
      setSuccessMessage('Mot de passe mis à jour avec succès');
      // Réinitialiser le formulaire
      setPasswordData({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      });
      
      // Rediriger après un court délai
      setTimeout(() => {
        navigate('/account');
      }, 2000);
    } else {
      setError(result.error || 'Une erreur est survenue lors de la mise à jour du mot de passe');
    }
  };
  
  return (
    <div className="flex flex-col min-h-screen bg-[#fff5ff] pb-16 md:pb-0 safe-area">
      {/* Header */}
      <div className="bg-[#fff5ff] py-4 px-4 flex items-center border-b safe-top">
        <button className="mr-2" onClick={() => navigate(-1)}>
          <ArrowLeft className="h-6 w-6" />
        </button>
        <h1 className="text-xl font-bold">Modifier mon mot de passe</h1>
      </div>

      {/* Form Content */}
      <div className="flex-1 px-4 py-4">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-0 mb-4">
            <div className="bg-pink-400 rounded-t-xl p-3">
              <h2 className="text-white font-semibold">Sécurité du compte</h2>
            </div>
            
            <div className="bg-white rounded-b-xl overflow-hidden shadow-sm p-4 space-y-4">
              <div className="space-y-2">
                <div className="flex items-center space-x-3 pb-2">
                  <Lock className="h-5 w-5 text-pink-400" />
                  <Label htmlFor="currentPassword" className="text-gray-500">Mot de passe actuel</Label>
                </div>
                <Input 
                  id="currentPassword" 
                  name="currentPassword" 
                  type="password" 
                  value={passwordData.currentPassword} 
                  onChange={handleInputChange} 
                  className="border border-gray-300" 
                />
              </div>

              <div className="space-y-2">
                <div className="flex items-center space-x-3 pb-2">
                  <Lock className="h-5 w-5 text-pink-400" />
                  <Label htmlFor="newPassword" className="text-gray-500">Nouveau mot de passe</Label>
                </div>
                <Input 
                  id="newPassword" 
                  name="newPassword" 
                  type="password" 
                  value={passwordData.newPassword} 
                  onChange={handleInputChange} 
                  className="border border-gray-300" 
                />
                <p className="text-xs text-gray-500 mt-1">
                  Doit contenir au moins 6 caractères, une lettre minuscule, une lettre majuscule et un chiffre
                </p>
              </div>

              <div className="space-y-2">
                <div className="flex items-center space-x-3 pb-2">
                  <Lock className="h-5 w-5 text-pink-400" />
                  <Label htmlFor="confirmPassword" className="text-gray-500">Confirmer le nouveau mot de passe</Label>
                </div>
                <Input 
                  id="confirmPassword" 
                  name="confirmPassword" 
                  type="password" 
                  value={passwordData.confirmPassword} 
                  onChange={handleInputChange} 
                  className="border border-gray-300" 
                />
              </div>
              
              {error && (
                <Alert variant="destructive" className="mt-4">
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}
              
              {successMessage && (
                <Alert className="mt-4 bg-green-50 text-green-800 border-green-200">
                  <AlertDescription>{successMessage}</AlertDescription>
                </Alert>
              )}
            </div>
          </div>

          <div className="px-4">
            <Button 
              type="submit" 
              className="w-full bg-pink-400 hover:bg-pink-500"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Modification en cours...' : 'Modifier mon mot de passe'}
            </Button>
          </div>
        </form>
      </div>

      {/* Bottom Navigation Bar */}
      <BottomNavbar />
    </div>
  );
};

export default ChangePasswordPage;
