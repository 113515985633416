import React, { useEffect, useRef, useState } from 'react';
import { BrowserMultiFormatReader } from '@zxing/library';
import { Camera, ArrowLeft, Check, Loader2, SwitchCamera, Focus, ZoomIn, ZoomOut, Flashlight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface BarcodeScannerProps {
  onScan: (code: string) => void;
  onClose: () => void;
}

interface BarcodeDetection {
  code: string;
  timestamp: number;
}

export function BarcodeScanner({
  onScan,
  onClose
}: BarcodeScannerProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [error, setError] = useState<string | null>(null);
  const [scanning, setScanning] = useState(true);
  const codeReaderRef = useRef<BrowserMultiFormatReader | null>(null);
  const [facingMode, setFacingMode] = useState<'user' | 'environment'>('environment');
  const [camerasAvailable, setCamerasAvailable] = useState<boolean>(false);
  const navigate = useNavigate();
  const streamRef = useRef<MediaStream | null>(null);
  const [detectionProgress, setDetectionProgress] = useState(0);
  const [lastDetections, setLastDetections] = useState<BarcodeDetection[]>([]);
  const REQUIRED_DETECTIONS = 1;
  const DETECTION_TIMEOUT = 1000;
  const DETECTION_THRESHOLD = 0.5;
  const [zoomLevel, setZoomLevel] = useState(1);
  const [maxZoom, setMaxZoom] = useState(1);
  const [zoomSupported, setZoomSupported] = useState(false);
  const [digitalZoom, setDigitalZoom] = useState(1);
  const [isUsingDigitalZoom, setIsUsingDigitalZoom] = useState(false);
  const [torchEnabled, setTorchEnabled] = useState(false);
  const autoFocusIntervalRef = useRef<number | null>(null);

  const checkCameras = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === 'videoinput');
      if (videoDevices.length === 0) {
        setError("Aucune caméra détectée");
        setCamerasAvailable(false);
        return false;
      }
      setCamerasAvailable(true);
      return true;
    } catch (err) {
      console.error('Erreur lors de la vérification des caméras:', err);
      setError("Impossible d'accéder aux caméras");
      setCamerasAvailable(false);
      return false;
    }
  };

  const applyZoom = async (level: number) => {
    try {
      if (!streamRef.current) return;
      const videoTrack = streamRef.current.getVideoTracks()[0];
      if (!videoTrack) return;
      if (videoTrack.getCapabilities && videoTrack.getCapabilities().hasOwnProperty('zoom')) {
        const capabilities = videoTrack.getCapabilities();
        if (capabilities.zoom) {
          const min = capabilities.zoom.min || 1;
          const max = capabilities.zoom.max || 1;
          const clampedLevel = Math.max(min, Math.min(level, max));
          await videoTrack.applyConstraints({
            advanced: [{
              zoom: clampedLevel
            }]
          });
          console.log(`Zoom level set to: ${clampedLevel}`);
          setZoomLevel(clampedLevel);
          setIsUsingDigitalZoom(false);
        }
      } else {
        setDigitalZoom(level);
        setIsUsingDigitalZoom(true);
        console.log(`Digital zoom level set to: ${level}`);
      }
    } catch (err) {
      console.error('Error applying zoom:', err);
      setDigitalZoom(level);
      setIsUsingDigitalZoom(true);
      console.log(`Fallback to digital zoom: ${level}`);
    }
  };

  const increaseZoom = () => {
    if (isUsingDigitalZoom) {
      const newLevel = Math.min(digitalZoom + 0.5, 3);
      setDigitalZoom(newLevel);
    } else {
      const newLevel = Math.min(zoomLevel + 0.5, maxZoom);
      applyZoom(newLevel);
    }
  };

  const decreaseZoom = () => {
    if (isUsingDigitalZoom) {
      const newLevel = Math.max(digitalZoom - 0.5, 1);
      setDigitalZoom(newLevel);
    } else {
      const newLevel = Math.max(zoomLevel - 0.5, 1);
      applyZoom(newLevel);
    }
  };

  const toggleTorch = async () => {
    try {
      if (!streamRef.current) return;
      const videoTrack = streamRef.current.getVideoTracks()[0];
      if (!videoTrack) return;
      if (videoTrack.getCapabilities && videoTrack.getCapabilities().hasOwnProperty('torch')) {
        const newTorchState = !torchEnabled;
        await videoTrack.applyConstraints({
          advanced: [{
            torch: newTorchState
          }]
        });
        setTorchEnabled(newTorchState);
        console.log(`Torch ${newTorchState ? 'enabled' : 'disabled'}`);
      } else {
        console.log('Torch not supported on this device');
      }
    } catch (err) {
      console.error('Error toggling torch:', err);
    }
  };

  const refocusCamera = async () => {
    try {
      if (!streamRef.current) return;
      const videoTrack = streamRef.current.getVideoTracks()[0];
      if (!videoTrack) return;
      if (videoTrack.getCapabilities && videoTrack.getCapabilities().hasOwnProperty('focusMode')) {
        try {
          await videoTrack.applyConstraints({
            advanced: [{
              focusMode: "continuous"
            }]
          });
          console.log('Camera set to continuous autofocus');
        } catch (err) {
          try {
            await videoTrack.applyConstraints({
              advanced: [{
                focusMode: "single-shot"
              }]
            });
            console.log('Camera set to single-shot autofocus');
          } catch (innerErr) {
            try {
              await videoTrack.applyConstraints({
                advanced: [{
                  focusMode: "manual",
                  focusDistance: 0.3
                }]
              });
              console.log('Camera set to manual focus');
            } catch (finalErr) {
              console.error('All focus methods failed:', finalErr);
            }
          }
        }
      } else {
        console.log('Focus mode not supported on this camera');
      }
    } catch (err) {
      console.error('Error refocusing camera:', err);
    }
  };

  const startPeriodicAutofocus = () => {
    if (autoFocusIntervalRef.current) {
      window.clearInterval(autoFocusIntervalRef.current);
    }
    refocusCamera();
    autoFocusIntervalRef.current = window.setInterval(() => {
      refocusCamera();
    }, 1000);
    console.log('Periodic autofocus started');
  };

  const stopPeriodicAutofocus = () => {
    if (autoFocusIntervalRef.current) {
      window.clearInterval(autoFocusIntervalRef.current);
      autoFocusIntervalRef.current = null;
      console.log('Periodic autofocus stopped');
    }
  };

  const startScanner = async () => {
    const hasCamera = await checkCameras();
    if (!hasCamera) return;
    setError(null);
    setDetectionProgress(0);
    setLastDetections([]);
    if (codeReaderRef.current) {
      try {
        if (videoRef.current && videoRef.current.srcObject) {
          const stream = videoRef.current.srcObject as MediaStream;
          stream.getTracks().forEach(track => track.stop());
        }
        codeReaderRef.current = new BrowserMultiFormatReader();
        codeReaderRef.current.timeBetweenDecodingAttempts = 100;
      } catch (e) {
        console.error('Error resetting scanner:', e);
      }
    } else {
      codeReaderRef.current = new BrowserMultiFormatReader();
      codeReaderRef.current.timeBetweenDecodingAttempts = 100;
    }
    if (!codeReaderRef.current || !videoRef.current) return;
    try {
      const devices = await codeReaderRef.current.listVideoInputDevices();
      if (devices.length === 0) {
        setError("Aucune caméra détectée");
        return;
      }
      let deviceId;
      if (facingMode === 'environment') {
        const backCamera = devices.find(device => device.label.toLowerCase().includes('back') || device.label.toLowerCase().includes('arrière') || device.label.toLowerCase().includes('rear'));
        deviceId = backCamera ? backCamera.deviceId : devices.length > 0 ? devices[0].deviceId : undefined;
      } else {
        const frontCamera = devices.find(device => device.label.toLowerCase().includes('front') || device.label.toLowerCase().includes('avant') || device.label.toLowerCase().includes('selfie'));
        deviceId = frontCamera ? frontCamera.deviceId : devices.length > 0 ? devices[0].deviceId : undefined;
      }
      if (!deviceId && devices.length > 0) {
        deviceId = devices[0].deviceId;
      }
      if (!deviceId) {
        setError("Aucune caméra détectée");
        return;
      }
      console.log('Caméras disponibles:', devices.map(d => ({
        id: d.deviceId,
        label: d.label
      })));
      console.log('Utilisation de la caméra:', deviceId);
      await codeReaderRef.current.decodeFromVideoDevice(deviceId, videoRef.current, result => {
        if (result && scanning) {
          const newDetection = {
            code: result.getText(),
            timestamp: Date.now()
          };
          setLastDetections(prev => {
            const updated = [...prev, newDetection];
            const filtered = updated.filter(d => Date.now() - d.timestamp < DETECTION_TIMEOUT);
            const progress = Math.min(filtered.length / REQUIRED_DETECTIONS * 100, 100);
            setDetectionProgress(progress);
            if (filtered.length >= REQUIRED_DETECTIONS) {
              const counts = filtered.reduce((acc, det) => {
                acc[det.code] = (acc[det.code] || 0) + 1;
                return acc;
              }, {} as Record<string, number>);
              const [mostDetectedCode, count] = Object.entries(counts).reduce((max, [code, count]) => count > max[1] ? [code, count] : max, ['', 0]);
              if (count / filtered.length >= DETECTION_THRESHOLD) {
                setScanning(false);
                if (videoRef.current && videoRef.current.srcObject) {
                  const stream = videoRef.current.srcObject as MediaStream;
                  stream.getTracks().forEach(track => track.stop());
                }
                codeReaderRef.current = null;
                stopPeriodicAutofocus();
                setTimeout(() => {
                  if (onScan) {
                    onScan(mostDetectedCode);
                  }
                }, 500);
              }
            }
            return filtered;
          });
        }
      });
      if (videoRef.current && videoRef.current.srcObject) {
        streamRef.current = videoRef.current.srcObject as MediaStream;
        const videoTrack = streamRef.current.getVideoTracks()[0];
        if (videoTrack && videoTrack.getCapabilities) {
          const capabilities = videoTrack.getCapabilities();
          if (capabilities.zoom) {
            setZoomSupported(true);
            setMaxZoom(capabilities.zoom.max || 1);
            console.log(`Zoom is supported. Max zoom: ${capabilities.zoom.max}`);
          } else {
            setZoomSupported(false);
            console.log('Zoom is not supported on this device');
          }
        }
        startPeriodicAutofocus();
      }
    } catch (err) {
      console.error('Error accessing camera:', err);
      setError("Impossible d'accéder à la caméra. Vérifiez vos permissions.");
      if (err instanceof Error) {
        if (err.name === 'NotAllowedError') {
          setError("Accès à la caméra refusé. Vérifiez vos autorisations.");
        } else if (err.name === 'NotFoundError') {
          setError("Aucune caméra détectée sur votre appareil.");
        } else if (err.name === 'NotReadableError') {
          setError("La caméra est occupée ou inaccessible.");
        }
      }
    }
  };

  const toggleCamera = () => {
    const newFacingMode = facingMode === 'user' ? 'environment' : 'user';
    setFacingMode(newFacingMode);
  };

  const getCurrentZoom = () => {
    return isUsingDigitalZoom ? digitalZoom : zoomLevel;
  };

  const zoomDisplay = getCurrentZoom();

  useEffect(() => {
    const initializeCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: facingMode
          }
        });
        stream.getTracks().forEach(track => track.stop());
        await startScanner();
      } catch (err) {
        console.error('Error initializing camera:', err);
        if (err instanceof Error) {
          if (err.name === 'NotAllowedError') {
            setError("Accès à la caméra refusé. Vérifiez vos autorisations.");
          } else if (err.name === 'NotFoundError') {
            setError("Aucune caméra détectée sur votre appareil.");
          } else {
            setError(`Erreur: ${err.message}`);
          }
        } else {
          setError("Impossible d'accéder à la caméra");
        }
      }
    };
    initializeCamera();
    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        const stream = videoRef.current.srcObject as MediaStream;
        stream.getTracks().forEach(track => track.stop());
      }
      codeReaderRef.current = null;
      stopPeriodicAutofocus();
    };
  }, [facingMode]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        stopPeriodicAutofocus();
      } else {
        startPeriodicAutofocus();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return <div className="absolute inset-0 bg-black overflow-hidden">
      <video ref={videoRef} className="absolute inset-0 w-full h-full object-cover" style={{
      transform: getCurrentZoom() > 1 ? `scale(${getCurrentZoom()})` : 'none',
      transformOrigin: 'center'
    }} />

      <div className="absolute top-0 left-0 right-0 p-2 flex justify-between items-center bg-black/60">
        <div className="text-pink-400 text-xs flex items-center gap-1">
          <Camera className="w-3 h-3" />
          Scanner un code-barres
        </div>
        <div className="flex items-center gap-2">
          
          <button onClick={toggleCamera} className="text-white hover:text-gray-300 flex items-center gap-1 bg-black/50 px-2 py-1 rounded-lg text-xs">
            <SwitchCamera className="w-3 h-3" />
            Changer de caméra
          </button>
        </div>
      </div>

      <div className="absolute bottom-4 right-4 flex flex-col gap-2">
        <button onClick={increaseZoom} className="text-white bg-black/60 p-2 rounded-full hover:bg-black/80" disabled={isUsingDigitalZoom ? digitalZoom >= 3 : zoomLevel >= maxZoom}>
          <ZoomIn className="w-6 h-6" />
        </button>
        <button onClick={decreaseZoom} className="text-white bg-black/60 p-2 rounded-full hover:bg-black/80" disabled={isUsingDigitalZoom ? digitalZoom <= 1 : zoomLevel <= 1}>
          <ZoomOut className="w-6 h-6" />
        </button>
        {zoomDisplay > 1 && <div className="text-white bg-black/60 px-2 py-1 rounded-full text-xs text-center">
            {Math.round(zoomDisplay * 10) / 10}x
            {isUsingDigitalZoom && <span className="text-xs block">digital</span>}
          </div>}
      </div>

      <div className="absolute bottom-4 left-4">
        <button onClick={toggleTorch} className={`text-white p-2 rounded-full hover:bg-black/80 ${torchEnabled ? 'bg-yellow-600' : 'bg-black/60'}`} aria-label="Toggle flashlight">
          <Flashlight className="w-6 h-6" />
        </button>
      </div>

      <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
        {detectionProgress > 0 && <div className="absolute top-1/2 left-0 right-0 flex justify-center transform -translate-y-1/2">
            <div className="bg-black/80 px-4 py-2 rounded-full text-white text-xs flex items-center gap-2">
              {!scanning ? <>
                  <Check className="w-3 h-3 text-green-500" />
                  <span>Code vérifié !</span>
                </> : <>
                  <Loader2 className="w-3 h-3 animate-spin" />
                  <span>Vérification ({Math.round(detectionProgress)}%)</span>
                </>}
            </div>
          </div>}
      </div>

      {error && <div className="absolute bottom-0 left-0 right-0 p-2 bg-red-500 text-white text-center text-xs">
          {error}
        </div>}
    </div>;
}
