
import React from 'react';
import { Card } from '@/components/ui/card';
import { getNovaScoreColor } from '@/utils/productUtils';

interface Product {
  product_name: string;
  brands: string;
  image_url: string;
  code: string;
  quantity: string;
  nutriscore_grade?: string;
  nova_group?: string;
}

interface ProductCardProps {
  product: Product;
  onClick: (code: string) => void;
  nutriScoreImages: { [key: string]: string; };
}

const ProductCard = ({ product, onClick, nutriScoreImages }: ProductCardProps) => {
  return (
    <Card 
      className="overflow-hidden rounded-xl cursor-pointer shadow-md transition-shadow" 
      onClick={() => onClick(product.code)}
    >
      <div className="flex p-4">
        <div className="w-24 h-24 mr-4 flex-shrink-0">
          <img 
            src={product.image_url || '/placeholder.svg'} 
            alt={product.product_name} 
            className="w-full h-full object-contain" 
            onError={(e) => {
              (e.target as HTMLImageElement).src = '/placeholder.svg';
            }} 
          />
        </div>
        <div className="flex-1">
          <h2 className="font-bold mb-1 line-clamp-2">{product.product_name || 'Produit sans nom'}</h2>
          <p className="text-sm text-gray-600 mb-1">{product.brands || 'Marque inconnue'}</p>
          <p className="text-xs text-gray-500">{product.quantity || ''}</p>
          <div className="mt-2 flex items-center gap-3">
            {product.nutriscore_grade ? (
              <img 
                src={nutriScoreImages[product.nutriscore_grade] || nutriScoreImages['unknown']} 
                alt={`Nutri-Score ${product.nutriscore_grade ? product.nutriscore_grade.toUpperCase() : 'Inconnu'}`} 
                className="h-9" 
                onError={() => {
                  console.log('Error loading nutriscore image, falling back to text');
                }} 
              />
            ) : (
              <img 
                src={nutriScoreImages['unknown']} 
                alt="Nutri-Score Inconnu" 
                className="h-9" 
                onError={() => {
                  console.log('Error loading unknown nutriscore image');
                }} 
              />
            )}
            
            {product.nova_group && (
              <div className="flex items-center">
                <span className="text-xs mr-1">NOVA</span>
                <div className={`w-7 h-7 rounded-full ${getNovaScoreColor(parseInt(product.nova_group))} flex items-center justify-center text-white font-bold`}>
                  {product.nova_group}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ProductCard;
