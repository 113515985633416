
import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { Eye, EyeOff, Check, X } from 'lucide-react';
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { supabase } from "@/integrations/supabase/client";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "@/components/ui/checkbox";

interface SignUpFormProps {
  onComplete: () => void;
}

const signUpSchema = z.object({
  firstName: z.string().min(1, "Prénom requis"),
  lastName: z.string().min(1, "Nom requis"),
  email: z.string().email("Email invalide"),
  confirmEmail: z.string().email("Email invalide"),
  password: z.string().min(6, "Le mot de passe doit contenir au moins 6 caractères").regex(/[A-Z]/, "Au moins une majuscule requise").regex(/[a-z]/, "Au moins une minuscule requise").regex(/[0-9]/, "Au moins un chiffre requis"),
  confirmPassword: z.string(),
  termsAccepted: z.boolean().refine(val => val === true, {
    message: "Vous devez accepter les conditions d'utilisation"
  }),
}).refine(data => data.password === data.confirmPassword, {
  message: "Les mots de passe ne correspondent pas",
  path: ["confirmPassword"]
}).refine(data => data.email === data.confirmEmail, {
  message: "Les adresses email ne correspondent pas",
  path: ["confirmEmail"]
});

const SignUpForm = ({
  onComplete
}: SignUpFormProps) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false
  });
  
  const form = useForm<z.infer<typeof signUpSchema>>({
    resolver: zodResolver(signUpSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      confirmEmail: "",
      password: "",
      confirmPassword: "",
      termsAccepted: false,
    }
  });
  
  useEffect(() => {
    const password = form.watch("password");
    
    setPasswordCriteria({
      length: password.length >= 6,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password)
    });
  }, [form.watch("password")]);

  const onSubmit = async (values: z.infer<typeof signUpSchema>) => {
    setIsLoading(true);
    try {
      const userData = {
        first_name: values.firstName,
        last_name: values.lastName,
        warning_accepted: true, // Set to true by default
      };
      console.log('Signing up with metadata:', userData);

      const {
        data,
        error
      } = await supabase.auth.signUp({
        email: values.email,
        password: values.password,
        options: {
          data: userData,
          emailRedirectTo: `nutrimam://home`
        }
      });
      
      if (error) {
        console.error("Signup error:", error);
        toast.error(error.message || "Une erreur est survenue lors de l'inscription");
        return;
      }

      // Explicitly update the profiles table
      if (data.user) {
        const { error: profileError } = await supabase
          .from('profiles')
          .update({ 
            warning_accepted: true,
          })
          .eq('id', data.user.id);
        
        if (profileError) {
          console.error("Error updating profile:", profileError);
          toast.error("Erreur lors de la mise à jour du profil");
        }
      }

      // Rediriger l'utilisateur vers la page de confirmation au lieu d'afficher un toast
      navigate("/signup-confirmation");
    } catch (error) {
      console.error("Unexpected error during signup:", error);
      toast.error("Une erreur inattendue est survenue");
    } finally {
      setIsLoading(false);
    }
  };

  return <div className="w-full max-w-md mx-auto">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <FormField control={form.control} name="firstName" render={({
            field
          }) => <FormItem>
                  <FormLabel>Prénom</FormLabel>
                  <FormControl>
                    <Input placeholder="Prénom" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>} />
            
            <FormField control={form.control} name="lastName" render={({
            field
          }) => <FormItem>
                  <FormLabel>Nom</FormLabel>
                  <FormControl>
                    <Input placeholder="Nom" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>} />
          </div>
          
          <FormField control={form.control} name="email" render={({
          field
        }) => <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input placeholder="votre@email.com" type="email" autoComplete="email" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>} />
          
          <FormField control={form.control} name="confirmEmail" render={({
          field
        }) => <FormItem>
                <FormLabel>Confirmer l'email</FormLabel>
                <FormControl>
                  <Input placeholder="votre@email.com" type="email" autoComplete="email" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>} />
          
          <FormField control={form.control} name="password" render={({
          field
        }) => <FormItem>
                <FormLabel>Mot de passe</FormLabel>
                <FormControl>
                  <div className="relative">
                    <Input type={showPassword ? "text" : "password"} autoComplete="new-password" {...field} />
                    <button type="button" className="absolute right-3 top-1/2 transform -translate-y-1/2" onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                    </button>
                  </div>
                </FormControl>
                <FormMessage />
                <div className="text-xs text-gray-500 space-y-1 mt-2">
                  <p>Le mot de passe doit contenir:</p>
                  <ul className="list-disc pl-5">
                    <li className={`flex items-center ${passwordCriteria.length ? 'text-green-500' : ''}`}>
                      {passwordCriteria.length ? <Check size={12} className="mr-1 inline" /> : <X size={12} className="mr-1 inline" />}
                      Au moins 6 caractères
                    </li>
                    <li className={`flex items-center ${passwordCriteria.uppercase ? 'text-green-500' : ''}`}>
                      {passwordCriteria.uppercase ? <Check size={12} className="mr-1 inline" /> : <X size={12} className="mr-1 inline" />}
                      Au moins une majuscule
                    </li>
                    <li className={`flex items-center ${passwordCriteria.lowercase ? 'text-green-500' : ''}`}>
                      {passwordCriteria.lowercase ? <Check size={12} className="mr-1 inline" /> : <X size={12} className="mr-1 inline" />}
                      Au moins une minuscule
                    </li>
                    <li className={`flex items-center ${passwordCriteria.number ? 'text-green-500' : ''}`}>
                      {passwordCriteria.number ? <Check size={12} className="mr-1 inline" /> : <X size={12} className="mr-1 inline" />}
                      Au moins un chiffre
                    </li>
                  </ul>
                </div>
              </FormItem>} />
          
          <FormField control={form.control} name="confirmPassword" render={({
          field
        }) => <FormItem>
                <FormLabel>Confirmer le mot de passe</FormLabel>
                <FormControl>
                  <div className="relative">
                    <Input type={showConfirmPassword ? "text" : "password"} autoComplete="new-password" {...field} />
                    <button type="button" className="absolute right-3 top-1/2 transform -translate-y-1/2" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                      {showConfirmPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                    </button>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>} />
          
          <FormField
            control={form.control}
            name="termsAccepted"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md p-4 border">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>
                    En cliquant ici, j'accepte les{" "}
                    <a href="/legal#terms" className="text-nutrimam-accent">Conditions d'utilisation</a>{" "}
                    et la{" "}
                    <a href="/legal#privacy" className="text-nutrimam-accent">Politique de confidentialité</a>
                  </FormLabel>
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />
          
          <Button type="submit" className="w-full bg-nutrimam-accent hover:bg-nutrimam-accent/90 mt-6" disabled={isLoading}>
            {isLoading ? "Création en cours..." : "Créer mon compte"}
          </Button>
        </form>
      </Form>
      
      <div className="mt-6 text-xs text-gray-500 text-center">
        En créant un compte, vous acceptez nos{" "}
        <a href="/legal#terms" className="text-nutrimam-accent">Conditions d'utilisation</a>{" "}
        et notre{" "}
        <a href="/legal#privacy" className="text-nutrimam-accent">Politique de confidentialité</a>.
      </div>
    </div>;
};

export default SignUpForm;
