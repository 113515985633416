import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Mail, Facebook, Instagram, Send } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { toast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";
import { useAuth } from '@/hooks/useAuth';
import BottomNavbar from '@/components/BottomNavbar';

const ContactPage = () => {
  const navigate = useNavigate();
  const {
    user,
    isAuthenticated
  } = useAuth();
  const [email, setEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState<string | null>(null);

  useEffect(() => {
    const initializeEmail = async () => {
      if (isAuthenticated && user?.email) {
        setEmail(user.email);
        await checkSubscription(user.email, user.id);
      } else {
        const savedEmail = localStorage.getItem('newsletter_email');
        if (savedEmail) {
          setEmail(savedEmail);
          await checkSubscription(savedEmail);
        }
      }
    };
    initializeEmail();
  }, [isAuthenticated, user]);

  const checkSubscription = async (emailToCheck: string, userId?: string) => {
    if (!emailToCheck) return;
    try {
      let query = supabase.from('newsletter_subscribers').select('email, user_id').eq('email', emailToCheck);

      if (userId) {
        query = query.or(`user_id.eq.${userId}`);
      }
      const {
        data,
        error
      } = await query;
      if (data && data.length > 0) {
        setIsSubscribed(true);
        if (userId && data[0] && !data[0].user_id) {
          await supabase.from('newsletter_subscribers').update({
            user_id: userId
          }).eq('email', emailToCheck);
        }
      } else {
        setIsSubscribed(false);
      }
    } catch (error) {
      console.error("Error checking subscription:", error);
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    localStorage.setItem('newsletter_email', newEmail);

    if (newEmail && newEmail.includes('@')) {
      checkSubscription(newEmail, user?.id);
    }
  };

  const handleSubscribe = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubscriptionError(null);
    if (!email.trim() || !email.includes('@')) {
      toast({
        title: "Erreur",
        description: "Veuillez entrer une adresse email valide.",
        variant: "destructive"
      });
      return;
    }
    setIsLoading(true);
    try {
      const {
        data,
        error
      } = await supabase.functions.invoke("newsletter-subscribe", {
        body: {
          email,
          userId: user?.id
        }
      });
      if (error) {
        throw new Error(error.message || "Erreur lors de l'inscription");
      }
      if (data.error) {
        throw new Error(data.error || "Erreur lors de l'inscription");
      }
      setIsSubscribed(true);
      toast({
        title: "Inscription réussie !",
        description: "Vous êtes maintenant inscrit à notre newsletter."
      });
    } catch (error: any) {
      console.error("Error subscribing to newsletter:", error);
      setSubscriptionError(error.message || "Une erreur est survenue lors de l'inscription.");
      toast({
        title: "Erreur",
        description: error.message || "Une erreur est survenue lors de l'inscription.",
        variant: "destructive"
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnsubscribe = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubscriptionError(null);
    setIsLoading(true);
    try {
      const {
        data,
        error
      } = await supabase.functions.invoke("newsletter-unsubscribe", {
        body: {
          email,
          userId: user?.id
        }
      });
      if (error) {
        throw new Error(error.message || "Erreur lors de la désinscription");
      }
      if (data.error) {
        throw new Error(data.error || "Erreur lors de la désinscription");
      }
      setIsSubscribed(false);
      toast({
        title: "Désinscription réussie",
        description: "Vous avez été désinscrit de notre newsletter."
      });
    } catch (error: any) {
      console.error("Error unsubscribing from newsletter:", error);
      setSubscriptionError(error.message || "Une erreur est survenue lors de la désinscription.");
      toast({
        title: "Erreur",
        description: error.message || "Une erreur est survenue lors de la désinscription.",
        variant: "destructive"
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-[#fff5ff] pb-16 md:pb-0 safe-area">
      <div className="bg-[#fff5ff] py-4 px-4 flex items-center border-b safe-top">
        <button className="mr-2" onClick={() => navigate('/settings')}>
          <ArrowLeft className="h-6 w-6" />
        </button>
        <h1 className="text-xl font-bold">Nous contacter</h1>
      </div>

      <div className="flex-1 px-4 pt-6 pb-24">
        <Card className="bg-white shadow-sm border-0 mb-6">
          <CardHeader className="pb-2">
            <CardTitle className="text-xl text-center text-pink-400">
              <Mail className="h-6 w-6 inline-block mr-2 text-pink-400" />
              Restez connecté avec Nutrimam
            </CardTitle>
          </CardHeader>
          <CardContent className="text-center pt-0">
            <p className="text-gray-600 mb-6">
              Suivez-nous sur les réseaux sociaux pour les dernières actualités et conseils sur l'alimentation pendant la grossesse.
            </p>

            <div className="flex justify-center gap-4 mb-8">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                <Button variant="outline" size="icon" className="rounded-full w-12 h-12 bg-blue-100 hover:bg-blue-200 border-0">
                  <Facebook className="h-6 w-6 text-blue-600" />
                </Button>
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                <Button variant="outline" size="icon" className="rounded-full w-12 h-12 bg-gradient-to-br from-purple-100 to-pink-100 hover:from-purple-200 hover:to-pink-200 border-0">
                  <Instagram className="h-6 w-6 text-pink-600" />
                </Button>
              </a>
              <a href="https://pinterest.com" target="_blank" rel="noopener noreferrer">
                <Button variant="outline" size="icon" className="rounded-full w-12 h-12 bg-red-100 hover:bg-red-200 border-0">
                  <svg className="h-6 w-6 text-red-600" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2Z" fill="currentColor" fillOpacity="0.2" />
                    <path d="M12.5 5C10.7761 5 9.07575 5.59434 7.81282 6.65252C6.54989 7.7107 5.83856 9.17543 5.81577 10.7019C5.79298 12.2284 6.46049 13.6999 7.68658 14.7743C7.50673 15.8622 7.18524 16.9161 6.73 17.9C6.73 17.9 8.0993 17.5145 9.3971 16.8791C10.3919 17.3095 11.4687 17.5246 12.5472 17.5C15.8444 17.5 18.5 14.6579 18.5 11.25C18.5 7.84215 15.8444 5 12.5 5ZM9 10C9 9.44772 9 9 10 9C10.5523 9 11 9.44772 11 10C11 10.5523 10.5523 11 10 11C9.44772 11 9 10.5523 9 10ZM13 10C13 9.44772 13.4477 9 14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11C13.4477 11 13 10.5523 13 10ZM8.5 14C9.48986 14.991 10.8434 15.572 12.2711 15.6175C13.6989 15.6631 15.0885 15.1693 16.1481 14.2384" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </Button>
              </a>
            </div>
            
            <div className="bg-pink-50 p-4 rounded-lg mb-4">
              <h3 className="font-semibold text-pink-400 mb-2">S'abonner à notre newsletter</h3>
              <p className="text-sm text-gray-600 mb-4">Recevez nos dernières informations et nos derniers articles de blog par mail.</p>
              
              {subscriptionError && <Alert variant="destructive" className="mb-4">
                  <AlertTitle>Erreur</AlertTitle>
                  <AlertDescription>{subscriptionError}</AlertDescription>
                </Alert>}
              
              <form onSubmit={isSubscribed ? handleUnsubscribe : handleSubscribe} className="flex flex-col gap-2">
                <Input type="email" placeholder="Votre adresse email" value={email} onChange={handleEmailChange} className="w-full" disabled={isLoading || isSubscribed} required />
                <Button type="submit" className={`${isSubscribed ? 'bg-red-400 hover:bg-red-500' : 'bg-pink-400 hover:bg-pink-500'} w-full`} disabled={isLoading}>
                  {isLoading ? "Traitement en cours..." : isSubscribed ? "Se désinscrire" : <>
                      <Send className="h-4 w-4 mr-2" />
                      S'abonner
                    </>}
                </Button>
                {isSubscribed && <p className="text-sm text-green-600 mt-2">
                    Vous êtes actuellement inscrit avec l'adresse : {email}
                  </p>}
              </form>
            </div>
          </CardContent>
        </Card>

        <div className="bg-white rounded-lg p-5 shadow-sm">
          <h3 className="font-semibold text-pink-400 mb-2">Notre équipe est à votre écoute</h3>
          <p className="text-gray-600 mb-4">Des questions sur l'application? Notre équipe est disponible pour vous aider.</p>
          <div className="space-y-2">
            <div className="flex items-center">
              <Mail className="h-5 w-5 text-pink-400 mr-2" />
              <a href="mailto:nutrimam.contact@gmail.com" className="text-pink-400 hover:underline">nutrimam.contact@gmail.com</a>
            </div>
            
          </div>
        </div>
      </div>

      <BottomNavbar />
    </div>
  );
};

export default ContactPage;
