
import React from 'react';
import { ArrowLeft, Mail, Phone, MapPin } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const ContactExternePage = () => {
  const navigate = useNavigate();

  return <div className="flex flex-col min-h-screen bg-[#fff5ff]">
      <div className="bg-[#fff5ff] py-4 px-4 flex items-center border-b safe-top">
        <button className="mr-2" onClick={() => navigate('/')}>
          <ArrowLeft className="h-5 w-5" />
        </button>
        <h1 className="text-xl font-bold">Contact</h1>
      </div>
      
      <div className="flex-1 px-4 py-8 max-w-md mx-auto w-full">
        <div className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4 text-center">Contactez-nous</h2>
          
          <div className="space-y-4">
            <div className="flex items-center gap-2 text-pink-400">
              <Mail className="h-5 w-5" />
              <a href="mailto:nutrimam.contact@gmail.com" className="hover:underline">
                nutrimam.contact@gmail.com
              </a>
            </div>
            
            <div className="flex items-center gap-2 text-pink-400">
              <Phone className="h-5 w-5" />
              <a href="tel:+33650437023" className="hover:underline">
                06 50 43 70 23
              </a>
            </div>
            
            <div className="flex items-center gap-2 text-pink-400">
              <MapPin className="h-5 w-5" />
              <span>40 chemin de lizieu, 01510 Pugieu</span>
            </div>
          </div>
        </div>
      </div>
    </div>;
};

export default ContactExternePage;

