import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, User, Mail, Info, FileText } from 'lucide-react';
import { useAuth } from '@/hooks/useAuth';
import BottomNavbar from '@/components/BottomNavbar';
const SettingsPage = () => {
  const navigate = useNavigate();
  const {
    user
  } = useAuth();
  return <div className="flex flex-col min-h-screen bg-[#fff5ff] pb-16 md:pb-0 safe-area">
      <div className="bg-[#fff5ff] py-4 px-4 flex items-center border-b safe-top">
        <button className="mr-2" onClick={() => navigate(-1)}>
          
        </button>
        <h1 className="text-xl font-bold">Paramètres</h1>
      </div>

      <div className="flex-1 px-4 py-4 pb-24">
        <div className="space-y-0 mb-4">
          <div className="bg-pink-400 rounded-t-xl p-3">
            <h2 className="text-white font-semibold">Général</h2>
          </div>
          
          <div className="bg-white rounded-b-xl overflow-hidden shadow-sm">
            <button className="w-full flex items-center justify-between p-4 border-b" onClick={() => navigate('/account')}>
              <div className="flex items-center">
                <User className="h-5 w-5 text-pink-400 mr-2" />
                <div className="text-left">
                  <p className="font-medium text-pink-400">Mon compte</p>
                  <p className="text-sm text-gray-500">Modifier mon profil, se déconnecter...</p>
                </div>
              </div>
            </button>
          </div>
        </div>

        <div className="space-y-0 mb-4">
          <div className="bg-pink-400 rounded-t-xl p-3">
            <h2 className="text-white font-semibold">Aide</h2>
          </div>
          
          <div className="bg-white rounded-b-xl overflow-hidden shadow-sm">
            <button className="w-full flex items-center justify-between p-4 border-b" onClick={() => navigate('/contact')}>
              <div className="flex items-center">
                <Mail className="h-5 w-5 text-pink-400 mr-2" />
                <div className="text-left">
                  <p className="font-medium text-pink-400">Nous contacter</p>
                  <p className="text-sm text-gray-500">Nos réseaux sociaux, email...</p>
                </div>
              </div>
            </button>
            
            <button className="w-full flex items-center justify-between p-4" onClick={() => navigate('/contact-externe')}>
              <div className="flex items-center">
                <Mail className="h-5 w-5 text-pink-400 mr-2" />
                <div className="text-left">
                  <p className="font-medium text-pink-400">Contact public</p>
                  <p className="text-sm text-gray-500">Formulaire accessible sans connexion</p>
                </div>
              </div>
            </button>
          </div>
        </div>

        <div className="space-y-0">
          <div className="bg-pink-400 rounded-t-xl p-3">
            <h2 className="text-white font-semibold">À propos</h2>
          </div>
          
          <div className="bg-white rounded-b-xl overflow-hidden shadow-sm">
            <button className="w-full flex items-center justify-between p-4" onClick={() => navigate('/legal')}>
              <div className="flex items-center">
                <Info className="h-5 w-5 text-pink-400 mr-2" />
                <div className="text-left">
                  <p className="font-medium text-pink-400">Informations légales</p>
                  <p className="text-sm text-gray-500">Conditions d'utilisation, confidentialité...</p>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>

      <BottomNavbar />
    </div>;
};
export default SettingsPage;