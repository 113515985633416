
// Types et interfaces
export type CategoryType = 'forbidden' | 'authorized-with-warning' | 'authorized-with-moderation' | 'authorized' | 'lack-of-information';

export interface CategoryRule {
  name: string;
  check: (product: any) => boolean;
  type: CategoryType;
  explanation: string;
}

export interface CategoryResult {
  type: CategoryType;
  explanation: string;
  name?: string;
}

// Fonction auxiliaire pour vérifier si une catégorie est présente dans un produit
const productHasCategory = (product: any, categoryPattern: string): boolean => {
  const categories = product.categories?.map((c: string) => c.toLowerCase()) || [];
  return categories.some(cat => cat.includes(categoryPattern));
};

// Fonction de catégorisation
export function categorizeProduct(product: any): CategoryResult {
  if (!product) {
    return {
      type: 'lack-of-information',
      explanation: "Information produit insuffisante pour établir une recommandation précise.",
      name: "Manque d'information"
    };
  }

  // Vérifier si le produit n'a pas de catégories
  if (!product.categories || product.categories.length === 0) {
    return {
      type: 'lack-of-information',
      explanation: "Ce produit ne possède pas de catégorie renseignée. Impossible d'établir une recommandation précise.",
      name: "Manque d'information"
    };
  }

  // Définition des règles de catégorisation
  const categoryRules: CategoryRule[] = [
    // Produits interdits (forbidden)
    {
      name: "Lait non pasteurisé",
      check: (p) => productHasCategory(p, 'milks') && !productHasCategory(p, 'uht-milks'),
      type: 'forbidden',
      explanation: "Les laits non pasteurisés (non UHT) sont interdits pendant la grossesse car ils présentent un risque élevé de contamination par la listeria. Privilégiez les laits UHT."
    },
    {
      name: "Fromages de montagne",
      check: (p) => productHasCategory(p, 'mountain-cheeses'),
      type: 'forbidden',
      explanation: "Les fromages de montagne non-pasteurisés sont interdits pendant la grossesse car ils présentent souvent un risque élevé de contamination par la listeria, notamment lorsqu'ils sont au lait cru."
    },
    {
      name: "Produit sans catégorie",
      check: (p) => !p.categories || p.categories.length === 0,
      type: 'forbidden',
      explanation: "Ce produit ne possède pas de catégorie renseignée. Par mesure de précaution pendant la grossesse, il est préférable d'éviter les produits dont on ne peut pas vérifier la nature exacte. Privilégiez les produits dont les catégories sont clairement identifiées."
    },
    {
      name: "Fromages non pasteurisés",
      check: (p) => productHasCategory(p, 'unpasteurised-cheeses'),
      type: 'forbidden',
      explanation: "Les fromages non pasteurisés (au lait cru) sont interdits pendant la grossesse car ils présentent un risque élevé de contamination par la listeria. Privilégiez les fromages pasteurisés."
    },
    {
      name: "Fromages à pâte pressée non cuite",
      check: (p) => productHasCategory(p, 'uncooked-pressed-cheeses'),
      type: 'forbidden',
      explanation: "Les fromages à pâte pressée non cuite sont interdits pendant la grossesse car ils présentent un risque élevé de contamination par la listeria, notamment lorsqu'ils ne sont pas pasteurisés."
    },
    {
      name: "Fromages à pâte persillée",
      check: (p) => productHasCategory(p, 'blue-veined-cheeses'),
      type: 'forbidden',
      explanation: "Les fromages à pâte persillée (bleus) non pasteurisés sont interdits pendant la grossesse car ils présentent un risque élevé de contamination par la listeria."
    },
    {
      name: "Fromages à croûte fleurie",
      check: (p) => productHasCategory(p, 'soft-cheeses-with-bloomy-rind') || productHasCategory(p, 'bloomy-rind-cheeses'),
      type: 'forbidden',
      explanation: "Les fromages à pâte molle à croûte fleurie (comme le camembert, le brie) sont interdits pendant la grossesse car ils présentent un risque élevé de contamination par la listeria, même s'ils sont pasteurisés."
    },
    {
      name: "Fromages à croûte naturelle",
      check: (p) => productHasCategory(p, 'soft-cheese-with-a-natural-rind'),
      type: 'forbidden',
      explanation: "Les fromages à pâte molle à croûte naturelle sont interdits pendant la grossesse car ils présentent un risque élevé de contamination par la listeria. La croûte naturelle peut héberger des bactéries potentiellement dangereuses."
    },
    {
      name: "Fromages à croûte lavée",
      check: (p) => productHasCategory(p, 'soft-cheeses-with-washed-rind'),
      type: 'forbidden',
      explanation: "Les fromages à pâte molle à croûte lavée sont interdits pendant la grossesse car ils présentent un risque élevé de contamination par la listeria."
    },
    {
      name: "Boissons alcoolisées",
      check: (p) => productHasCategory(p, 'alcoholic-beverages'),
      type: 'forbidden',
      explanation: "Les boissons alcoolisées sont strictement interdites pendant la grossesse car l'alcool peut avoir des effets néfastes sur le développement du fœtus."
    },
    {
      name: "Rillettes",
      check: (p) => productHasCategory(p, 'rillettes'),
      type: 'forbidden',
      explanation: "Les rillettes sont interdites pendant la grossesse car elles présentent un risque de contamination par la listeria et la toxoplasmose."
    },
    {
      name: "Pâté",
      check: (p) => productHasCategory(p, 'pâté'),
      type: 'forbidden',
      explanation: "Le pâté est interdit pendant la grossesse car il présente un risque de contamination par la listeria et la toxoplasmose."
    },
    {
      name: "Foie gras",
      check: (p) => productHasCategory(p, 'foies-gras'),
      type: 'forbidden',
      explanation: "Le foie gras est interdit pendant la grossesse car il présente un risque de contamination par la listeria et la toxoplasmose."
    },
    {
      name: "Poissons fumés",
      check: (p) => productHasCategory(p, 'smoked-fishes') || productHasCategory(p, 'smoked-fish'),
      type: 'forbidden',
      explanation: "Les poissons fumés sont interdits pendant la grossesse car ils présentent un risque élevé de contamination par la listeria."
    },
    {
      name: "Saumon fumé",
      check: (p) => productHasCategory(p, 'smoked-salmon'),
      type: 'forbidden',
      explanation: "Le saumon fumé est interdit pendant la grossesse car il présente un risque élevé de contamination par la listeria."
    },
    {
      name: "Mollusques",
      check: (p) => productHasCategory(p, 'mollusc'),
      type: 'forbidden',
      explanation: "Tous les mollusques crus ou insuffisamment cuits (ex. huîtres, moules, palourdes) en raison du risque élevé de listériose et de contamination bactérienne."
    },
    {
      name: "Sushi et maki",
      check: (p) => productHasCategory(p, 'sushi-and-maki'),
      type: 'forbidden',
      explanation: "Toute préparation contenant du poisson cru (sushis, makis) est interdite. Les versions avec poisson cuit ou légumes bien lavés peuvent être une alternative."
    },
    {
      name: "Tarama",
      check: (p) => productHasCategory(p, 'taramasalata'),
      type: 'forbidden',
      explanation: "Préparation contenant des œufs de poisson crus, interdite à cause du risque de listériose et d'intoxications alimentaires."
    },
    {
      name: "Surimi",
      check: (p) => productHasCategory(p, 'surimi'),
      type: 'forbidden',
      explanation: "Le surimi est interdit pendant la grossesse car il présente un risque de contamination bactérienne et peut contenir des additifs déconseillés."
    },
    
    // Produits autorisés avec précaution (authorized-with-warning)
    {
      name: "Filet de poisson",
      check: (p) => productHasCategory(p, 'fish-fillets'),
      type: 'authorized-with-warning',
      explanation: "Les poissons peuvent être consommés pendant la grossesse à condition d'être IMPÉRATIVEMENT bien cuit à cœur (température >70°C) pour éliminer tout risque de parasites (anisakis) et de bactéries. La consommation crue ou insuffisamment cuite est à proscrire absolument. Vérifiez que le poisson est bien opaque et se défait facilement à la fourchette avant de le consommer."
    },
    {
      name: "Sandwichs au poisson",
      check: (p) => productHasCategory(p, 'fish-sandwiches') || productHasCategory(p, 'salmon-sandwiches'),
      type: 'authorized-with-warning',
      explanation: "Les sandwichs au poisson peuvent être consommés pendant la grossesse à condition que le poisson soit IMPÉRATIVEMENT bien cuit (température >70°C). Vérifiez que le poisson est bien opaque et cuit à cœur. Évitez les sandwichs contenant du poisson cru ou fumé. Assurez-vous également que le sandwich est frais et conservé au frais."
    },
    {
      name: "Charcuterie",
      check: (p) => {
        const cats = p.categories?.map((c: string) => c.toLowerCase()) || [];
        const isWhiteHam = cats.some(cat => cat.includes('white-hams') || cat.includes('mortadella'));
        const isPreparedMeat = cats.some(cat => cat.includes('prepared-meats'));
        return isPreparedMeat && !isWhiteHam;
      },
      type: 'forbidden',
      explanation: "La charcuterie crue ou insuffisamment cuite (jambon cru, saucisson sec, chorizo, etc.) est interdite pendant la grossesse en raison du risque de toxoplasmose et de listériose. Seuls les produits de charcuterie bien cuits (jambon blanc, mortadelle cuite, etc.) peuvent être consommés après une cuisson complète. Assurez-vous toujours que la charcuterie soit bien cuite avant consommation."
    },
    {
      name: "Crustacés",
      check: (p) => productHasCategory(p, 'crustaceans'),
      type: 'authorized-with-warning',
      explanation: "Les crustacés peuvent être consommés pendant la grossesse à condition d'être IMPÉRATIVEMENT bien cuits (température >70°C). La cuisson doit être complète pour éliminer tout risque de contamination bactérienne. Les crustacés crus ou insuffisamment cuits sont à proscrire absolument."
    },
    {
      name: "Steaks de saumon",
      check: (p) => productHasCategory(p, 'salmon-steaks') || productHasCategory(p, 'salmons'),
      type: 'authorized-with-warning',
      explanation: "Le saumon peut être consommé pendant la grossesse à condition d'être IMPÉRATIVEMENT bien cuit à cœur (température >70°C) pour éliminer tout risque de parasites (anisakis) et de bactéries. La consommation crue ou insuffisamment cuite est à proscrire absolument. Vérifiez que le poisson est bien opaque et se défait facilement à la fourchette avant de le consommer."
    },
    {
      name: "Thon",
      check: (p) => productHasCategory(p, 'tunas'),
      type: 'authorized-with-warning',
      explanation: "Le thon peut être consommé pendant la grossesse à condition d'être IMPÉRATIVEMENT bien cuit à cœur (température >70°C) pour éliminer tout risque de parasites et de bactéries. La consommation crue ou insuffisamment cuite est à proscrire absolument. Vérifiez que le poisson est bien opaque et se défait facilement à la fourchette avant de le consommer. En raison de sa teneur potentielle en mercure, limitez sa consommation à 2 fois par semaine."
    },
    {
      name: "Boulettes de viande",
      check: (p) => productHasCategory(p, 'meat-balls'),
      type: 'authorized-with-warning',
      explanation: "Les boulettes de viande peuvent être consommées pendant la grossesse à condition d'être IMPÉRATIVEMENT bien cuites à cœur (absence de zone rosée) pour éliminer tout risque de contamination bactérienne. Vérifiez la cuisson en coupant une boulette en deux avant de consommer."
    },
    {
      name: "Viande fraîche NOVA 1",
      check: (p) => productHasCategory(p, 'meats') && p.novaScore === 1,
      type: 'authorized-with-warning',
      explanation: "La viande doit être bien cuite (aucune trace rosée ou de saignant) pour éviter les risques liés à la toxoplasmose ou à la listériose."
    },
    {
      name: "Viandes fraîches",
      check: (p) => productHasCategory(p, 'fresh-meats'),
      type: 'authorized-with-warning',
      explanation: "Les viandes fraîches doivent être consommées rapidement après achat, bien cuites, et manipulées avec des ustensiles propres pour limiter la contamination croisée."
    },
    {
      name: "Steaks hachés frais",
      check: (p) => productHasCategory(p, 'fresh-ground-steaks'),
      type: 'authorized-with-warning',
      explanation: "Les steaks hachés frais doivent être cuits à cœur (absence de zone rouge ou rosée) afin d'éliminer tout risque d'intoxication alimentaire, comme la bactérie E. coli."
    },
    {
      name: "Steaks surgelés",
      check: (p) => productHasCategory(p, 'frozen-steaks'),
      type: 'authorized-with-warning',
      explanation: "Les steaks surgelés doivent être décongelés au réfrigérateur et bien cuits avant consommation. Ne jamais consommer de steak surgelé encore rosé."
    },
    {
      name: "Poissons surgelés",
      check: (p) => productHasCategory(p, 'frozen-fishes'),
      type: 'authorized-with-warning',
      explanation: "Les poissons surgelés doivent être bien cuits après décongélation (température interne >70°C). Évitez de recongeler après décongélation."
    },
    {
      name: "Lardons",
      check: (p) => productHasCategory(p, 'lardons'),
      type: 'authorized-with-warning',
      explanation: "Les lardons doivent être bien cuits avant consommation, que ce soit dans des plats cuisinés ou à la poêle, pour éliminer tout risque de listériose."
    },
    {
      name: "Légumes frais",
      check: (p) => productHasCategory(p, 'fresh-vegetables'),
      type: 'authorized-with-warning',
      explanation: "Les légumes frais doivent être soigneusement lavés à l'eau claire (éventuellement avec du vinaigre blanc pour un nettoyage approfondi) pour éliminer tout résidu de terre ou parasite."
    },
    {
      name: "Fruits frais",
      check: (p) => productHasCategory(p, 'fresh-fruits'),
      type: 'authorized-with-warning',
      explanation: "Les fruits frais doivent être lavés méticuleusement, pelés si possible, pour éviter toute contamination par des résidus de terre ou de pesticides."
    },
    {
      name: "Herbes aromatiques",
      check: (p) => productHasCategory(p, 'aromatic-plants'),
      type: 'authorized-with-warning',
      explanation: "Les herbes aromatiques (basilic, persil, coriandre, etc.) doivent être lavées très soigneusement, car elles peuvent être porteuses de résidus de terre ou de parasites (toxoplasmose)."
    },
    {
      name: "Oeufs frais",
      check: (p) => productHasCategory(p, 'fresh-eggs'),
      type: 'authorized-with-warning',
      explanation: "Les œufs peuvent être consommés pendant la grossesse à condition d'être IMPÉRATIVEMENT bien cuits (jaune et blanc doivent être fermes) pour éliminer tout risque de salmonellose. Les préparations à base d'œufs crus ou peu cuits (mayonnaise maison, mousse au chocolat, etc.) sont à éviter absolument."
    },
    {
      name: "Oeufs de poule",
      check: (p) => productHasCategory(p, 'chicken-eggs'),
      type: 'authorized-with-warning',
      explanation: "Les œufs peuvent être consommés pendant la grossesse à condition d'être IMPÉRATIVEMENT bien cuits (jaune et blanc doivent être fermes) pour éliminer tout risque de salmonellose. Les préparations à base d'œufs crus ou peu cuits (mayonnaise maison, mousse au chocolat, etc.) sont à éviter absolument."
    },
    
    // Produits autorisés avec modération (authorized-with-moderation)
    {
      name: "Boissons énergisantes",
      check: (p) => productHasCategory(p, 'energy-drinks'),
      type: 'authorized-with-moderation',
      explanation: "Déconseillés en raison de leur forte teneur en caféine ou en taurine. Si vous choisissez d'en consommer, limitez à une canette occasionnelle et évitez les boissons énergisantes contenant des stimulants puissants."
    },
    {
      name: "Café",
      check: (p) => productHasCategory(p, 'coffees'),
      type: 'authorized-with-moderation',
      explanation: "Autorisé, mais limité à 3 tasses par jour maximum pour éviter les risques liés à une consommation excessive de caféine (agitation ou accélération du rythme cardiaque du bébé)."
    },
    {
      name: "Produit ultra-transformé",
      check: (p) => p.novaScore === 4,
      type: 'authorized-with-moderation',
      explanation: "Ce produit est autorisé pendant la grossesse, mais étant ultra-transformé (NOVA 4), il est recommandé de limiter sa consommation et de privilégier les aliments peu transformés."
    },
    
    // Produits autorisés (authorized)
    {
      name: "Fromages à pâte pressée cuite",
      check: (p) => productHasCategory(p, 'hard-cheeses'),
      type: 'authorized',
      explanation: "Les fromages à pâte pressée cuite sont autorisés pendant la grossesse car le processus de fabrication (cuisson à haute température) élimine les risques de contamination bactérienne. Ces fromages sont généralement sûrs, même s'ils sont au lait cru."
    }
  ];

  // Vérifier s'il s'agit d'un fromage pasteurisé avec croûte à risque
  const isPasteurizedCheese = product.categories?.some((c: string) => 
    c.toLowerCase().includes('pasteurized-cheeses')
  );
  
  const hasRiskyCrust = product.categories?.some((c: string) => {
    const lowerCase = c.toLowerCase();
    return lowerCase.includes('bloomy-rind-cheeses') ||
           lowerCase.includes('soft-cheese-with-a-natural-rind') ||
           lowerCase.includes('soft-cheeses-with-washed-rind') ||
           lowerCase.includes('soft-cheeses-with-bloomy-rind');
  });

  // Parcourir les règles et retourner le premier résultat correspondant
  for (const rule of categoryRules) {
    // Ignorer les règles "forbidden" pour les fromages pasteurisés, sauf pour ceux à croûte à risque
    if (isPasteurizedCheese && !hasRiskyCrust && rule.type === 'forbidden') {
      continue;
    }

    if (rule.check(product)) {
      return {
        type: rule.type,
        explanation: rule.explanation,
        name: rule.name
      };
    }
  }

  // Par défaut - produit autorisé
  return {
    type: 'authorized',
    explanation: "Ce produit est autorisé pendant la grossesse. Veillez tout de même à respecter les principes d'une alimentation équilibrée et variée.",
    name: "Produit autorisé"
  };
}

export default function useCategorizeProduct() {
  return { categorizeProduct };
}
