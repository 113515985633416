
import React, { useState } from 'react';
import { DialogContent, Dialog, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { format, addMonths } from 'date-fns';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { updateProfile } from '@/services/authService';
import { CalendarIcon } from 'lucide-react';
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { toast } from "sonner";
import { supabase } from '@/integrations/supabase/client';

interface MissingInfoModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onCompleted: () => void;
  userId: string;
}

const MissingInfoModal = ({ open, onOpenChange, onCompleted, userId }: MissingInfoModalProps) => {
  const [date, setDate] = useState<Date | undefined>(undefined);
  const [babySex, setBabySex] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSkip = async () => {
    try {
      // Calculer la date du prochain rappel (1 mois plus tard)
      const nextReminderDate = addMonths(new Date(), 1);
      
      // Mettre à jour le profil avec la date du prochain rappel
      const { error } = await supabase
        .from('profiles')
        .update({
          info_reminder_date: nextReminderDate.toISOString()
        })
        .eq('id', userId);
      
      if (error) throw error;
      
      onOpenChange(false);
      toast.success("Vous serez invité à compléter ces informations le mois prochain");
    } catch (error) {
      console.error("Erreur lors de l'enregistrement de la date de rappel:", error);
      toast.error("Une erreur est survenue");
    }
  };

  const handleSubmit = async () => {
    if (!date && !babySex) {
      toast.error("Veuillez renseigner au moins une information");
      return;
    }

    setIsSubmitting(true);
    
    try {
      const updateData: any = {};
      
      if (date) {
        updateData.due_date = format(date, 'yyyy-MM-dd');
      }
      
      if (babySex) {
        updateData.baby_sex = babySex;
      }
      
      // Réinitialiser la date de rappel lorsque les informations sont complétées
      updateData.info_reminder_date = null;
      
      const result = await updateProfile(updateData);
      
      if (result.success) {
        onOpenChange(false);
        onCompleted();
        toast.success("Informations mises à jour avec succès");
      } else {
        toast.error("Une erreur est survenue lors de la mise à jour");
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du profil:", error);
      toast.error("Une erreur est survenue lors de la mise à jour");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Complétez votre profil</DialogTitle>
          <DialogDescription>
            Ces informations nous aideront à vous offrir un contenu personnalisé
          </DialogDescription>
        </DialogHeader>
        
        <div className="flex flex-col gap-4 py-4">
          <div className="space-y-2">
            <Label htmlFor="due-date">Date de naissance de votre bébé</Label>
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant={"outline"}
                  className={cn(
                    "w-full justify-start text-left font-normal",
                    !date && "text-muted-foreground"
                  )}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {date ? format(date, 'dd/MM/yyyy') : <span>Choisir une date</span>}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  mode="single"
                  selected={date}
                  onSelect={setDate}
                  initialFocus
                  className={cn("p-3 pointer-events-auto")}
                />
              </PopoverContent>
            </Popover>
          </div>
          
          <div className="space-y-2">
            <Label>Sexe de votre bébé</Label>
            <RadioGroup value={babySex} onValueChange={setBabySex}>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="boy" id="boy" />
                <Label htmlFor="boy">Garçon</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="girl" id="girl" />
                <Label htmlFor="girl">Fille</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="surprise" id="surprise" />
                <Label htmlFor="surprise">On garde la surprise</Label>
              </div>
            </RadioGroup>
          </div>
        </div>
        
        <DialogFooter className="flex flex-col sm:flex-row gap-2">
          <Button variant="outline" onClick={handleSkip} className="sm:order-1 order-2">
            Passer pour le moment
          </Button>
          <Button 
            onClick={handleSubmit} 
            className="bg-pink-400 hover:bg-pink-500 sm:order-2 order-1"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Enregistrement...' : 'Enregistrer'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default MissingInfoModal;
