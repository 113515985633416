import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, User, Calendar, Mail, Phone } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { format } from 'date-fns';
import { Calendar as CalendarComponent } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { toast } from 'sonner';
import { updateProfile } from '@/services/authService';
import useAuth from '@/hooks/useAuth';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import BottomNavbar from '@/components/BottomNavbar';

const EditProfilePage = () => {
  const navigate = useNavigate();
  const { user, refreshProfile } = useAuth();

  const [profileData, setProfileData] = useState({
    firstName: '',
    lastName: '',
    babyBirthDate: '',
    email: '',
    phone: '',
    babySex: ''
  });
  
  const [date, setDate] = useState<Date | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  useEffect(() => {
    if (user) {
      setProfileData({
        firstName: user.first_name || '',
        lastName: user.last_name || '',
        babyBirthDate: user.due_date ? format(new Date(user.due_date), 'dd/MM/yyyy') : '',
        email: user.email || '',
        phone: user.phone || '',
        babySex: user.baby_sex || ''
      });

      if (user.due_date) {
        setDate(new Date(user.due_date));
      }
    }
  }, [user]);
  
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      name,
      value
    } = e.target;
    setProfileData(prev => ({
      ...prev,
      [name]: value
    }));
  };
  
  const handleDateChange = (newDate: Date | undefined) => {
    setDate(newDate);
    if (newDate) {
      setProfileData(prev => ({
        ...prev,
        babyBirthDate: format(newDate, 'dd/MM/yyyy')
      }));
    }
  };

  const handleBabySexChange = (value: string) => {
    setProfileData(prev => ({
      ...prev,
      babySex: value
    }));
  };
  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    const result = await updateProfile({
      first_name: profileData.firstName,
      last_name: profileData.lastName,
      due_date: date ? format(date, 'yyyy-MM-dd') : undefined,
      phone: profileData.phone || null,
      baby_sex: profileData.babySex || null
    });
    
    setIsSubmitting(false);
    
    if (result.success) {
      await refreshProfile();
      navigate('/profile');
    }
  };
  
  return <div className="flex flex-col min-h-screen bg-[#fee2f4] pb-16 md:pb-0">
      <div className="bg-[#fee2f4] py-4 px-4 flex items-center border-b">
        <button className="mr-2" onClick={() => navigate(-1)}>
          <ArrowLeft className="h-6 w-6" />
        </button>
        <h1 className="text-xl font-bold">Modifier mon profil</h1>
      </div>

      <div className="flex-1 px-4 py-4">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-0 mb-4">
            <div className="bg-pink-400 rounded-t-xl p-3">
              <h2 className="text-white font-semibold">Mes informations</h2>
            </div>
            
            <div className="bg-white rounded-b-xl overflow-hidden shadow-sm p-4 space-y-4">
              <div className="space-y-2">
                <div className="flex items-center space-x-3 pb-2">
                  <User className="h-5 w-5 text-pink-400" />
                  <Label htmlFor="lastName" className="text-gray-500">Nom</Label>
                </div>
                <Input id="lastName" name="lastName" value={profileData.lastName} onChange={handleInputChange} className="border border-gray-300" />
              </div>

              <div className="space-y-2">
                <div className="flex items-center space-x-3 pb-2">
                  <User className="h-5 w-5 text-pink-400" />
                  <Label htmlFor="firstName" className="text-gray-500">Prénom</Label>
                </div>
                <Input id="firstName" name="firstName" value={profileData.firstName} onChange={handleInputChange} className="border border-gray-300" />
              </div>

              <div className="space-y-2">
                <div className="flex items-center space-x-3 pb-2">
                  <Calendar className="h-5 w-5 text-pink-400" />
                  <Label htmlFor="babyBirthDate" className="text-gray-500">Date de naissance du bébé</Label>
                </div>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button variant={"outline"} className={cn("w-full justify-start text-left font-normal", !date && "text-muted-foreground")}>
                      <Calendar className="mr-2 h-4 w-4" />
                      {date ? format(date, 'dd/MM/yyyy') : <span>Choisir une date</span>}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <CalendarComponent 
                      mode="single" 
                      selected={date} 
                      onSelect={handleDateChange} 
                      initialFocus 
                      className={cn("pointer-events-auto")}
                    />
                  </PopoverContent>
                </Popover>
              </div>

              <div className="space-y-2">
                <div className="flex items-center space-x-3 pb-2">
                  <User className="h-5 w-5 text-pink-400" />
                  <Label htmlFor="babySex" className="text-gray-500">Sexe du bébé</Label>
                </div>
                <RadioGroup 
                  value={profileData.babySex} 
                  onValueChange={handleBabySexChange}
                  className="flex flex-col space-y-2"
                >
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="boy" id="boy" />
                    <Label htmlFor="boy">Garçon</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="girl" id="girl" />
                    <Label htmlFor="girl">Fille</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="surprise" id="surprise" />
                    <Label htmlFor="surprise">On garde la surprise</Label>
                  </div>
                </RadioGroup>
              </div>

              <div className="space-y-2">
                <div className="flex items-center space-x-3 pb-2">
                  <Mail className="h-5 w-5 text-pink-400" />
                  <Label htmlFor="email" className="text-gray-500">Email</Label>
                </div>
                <Input id="email" name="email" value={profileData.email} onChange={handleInputChange} className="border border-gray-300" readOnly />
                <p className="text-xs text-gray-500">L'adresse email ne peut pas être modifiée</p>
              </div>

              <div className="space-y-2">
                <div className="flex items-center space-x-3 pb-2">
                  <Phone className="h-5 w-5 text-pink-400" />
                  <Label htmlFor="phone" className="text-gray-500">Téléphone</Label>
                </div>
                <Input id="phone" name="phone" value={profileData.phone} onChange={handleInputChange} className="border border-gray-300" />
              </div>
            </div>
          </div>

          <div className="px-4">
            <Button 
              type="submit" 
              className="w-full bg-pink-400 hover:bg-pink-500"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Enregistrement...' : 'Enregistrer les modifications'}
            </Button>
          </div>
        </form>
      </div>

      <BottomNavbar />
    </div>;
};

export default EditProfilePage;
