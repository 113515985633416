
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Mail, CheckCircle } from 'lucide-react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';

const SignUpConfirmationPage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col min-h-screen bg-[#fff5ff]">
      <div className="bg-[#fff5ff] py-4 px-4 flex items-center border-b safe-top">
        <h1 className="text-xl font-bold flex-1 text-center">Inscription confirmée</h1>
      </div>
      
      <div className="flex-1 px-4 py-8 max-w-md mx-auto w-full">
        <Card>
          <CardHeader>
            <div className="flex justify-center mb-4">
              <CheckCircle className="h-16 w-16 text-green-500" />
            </div>
            <CardTitle className="text-center">Création de compte confirmée</CardTitle>
            <CardDescription className="text-center">
              Veuillez vérifier votre boîte mail pour activer votre compte
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-6">
            <div className="rounded-lg bg-blue-50 p-4 text-blue-800 text-sm">
              <div className="flex items-center gap-2 mb-2">
                <Mail className="h-5 w-5 flex-shrink-0" />
                <p className="font-medium">Vérifiez vos emails</p>
              </div>
              <p>
                Nous avons envoyé un email de confirmation à votre adresse.
                <strong className="block mt-1">Pensez à vérifier vos courriers indésirables ou spams.</strong>
              </p>
            </div>
            
            <p className="text-gray-600 text-sm text-center">
              Vous n'avez pas reçu d'email ? Vérifiez votre dossier spam ou réessayez dans quelques minutes.
            </p>
          </CardContent>
          <CardFooter className="flex justify-center">
            <Button 
              onClick={() => navigate('/')} 
              className="bg-nutrimam-accent hover:bg-nutrimam-accent/90"
            >
              Retour à l'accueil
            </Button>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
};

export default SignUpConfirmationPage;
