
import React, { createContext, useState, useEffect, useRef } from 'react';
import { Session } from '@supabase/supabase-js';
import { supabase } from '@/integrations/supabase/client';
import { UserProfile, AuthContextType } from '@/types/auth.types';
import { useProfileLoader } from '@/hooks/useProfileLoader';

const AuthContext = createContext<AuthContextType>({
  session: null,
  user: null,
  loading: true,
  isAuthenticated: false,
  showMissingInfoModal: false,
  setShowMissingInfoModal: () => {},
  refreshProfile: async () => {},
  signOut: async () => {},
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<UserProfile | null>(null);
  const [session, setSession] = useState<Session | null>(null);
  const [loading, setLoading] = useState(true);
  const [showMissingInfoModal, setShowMissingInfoModal] = useState(false);
  const isRefreshingRef = useRef(false);
  
  const { loadUserProfile, setLoading: setProfileLoading } = useProfileLoader();

  useEffect(() => {
    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, currentSession) => {
      console.log('Auth state changed:', event);
      setSession(currentSession);
      
      if (currentSession?.user) {
        const baseUserProfile = {
          id: currentSession.user.id,
          email: currentSession.user.email,
          first_name: currentSession.user.user_metadata?.first_name,
          last_name: currentSession.user.user_metadata?.last_name,
          due_date: currentSession.user.user_metadata?.due_date,
          phone: currentSession.user.user_metadata?.phone,
          baby_sex: currentSession.user.user_metadata?.baby_sex,
        };
        
        setUser(baseUserProfile);
        
        loadUserProfile(currentSession.user.id)
          .then(profileData => {
            if (profileData) {
              setUser(prev => {
                if (!prev) return profileData;
                return { ...prev, ...profileData };
              });

              // Ne pas vérifier immédiatement les informations manquantes
              // La vérification se fera après que la modale d'avertissement soit traitée
            }
            setLoading(false);
          });
      } else {
        setUser(null);
        setLoading(false);
      }
    });

    supabase.auth.getSession().then(({ data: { session: currentSession } }) => {
      setSession(currentSession);
      if (currentSession?.user) {
        const baseUserProfile = {
          id: currentSession.user.id,
          email: currentSession.user.email,
          first_name: currentSession.user.user_metadata?.first_name,
          last_name: currentSession.user.user_metadata?.last_name,
          due_date: currentSession.user.user_metadata?.due_date,
          phone: currentSession.user.user_metadata?.phone,
          baby_sex: currentSession.user.user_metadata?.baby_sex,
        };
        
        setUser(baseUserProfile);
        
        loadUserProfile(currentSession.user.id)
          .then(profileData => {
            if (profileData) {
              setUser(prev => {
                if (!prev) return profileData;
                return { ...prev, ...profileData };
              });
              
              // Attendre que le composant soit monté pour vérifier les informations manquantes
              setTimeout(() => {
                checkMissingInfo(profileData);
              }, 1000);
            }
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  // Fonction pour vérifier si des informations importantes sont manquantes
  const checkMissingInfo = (profile: UserProfile) => {
    // Si l'utilisateur n'a pas de date de naissance ou de sexe pour le bébé
    const missingDueDate = !profile.due_date;
    const missingBabySex = !profile.baby_sex;
    const today = new Date();
    
    if ((missingDueDate || missingBabySex) && profile.id) {
      // Vérifier si nous devons rappeler l'utilisateur maintenant ou plus tard
      if (profile.info_reminder_date) {
        const reminderDate = new Date(profile.info_reminder_date);
        if (today < reminderDate) {
          // La date de rappel n'est pas encore arrivée
          return;
        }
      }
      
      // Ne pas afficher immédiatement si l'avertissement n'a pas été accepté
      if (profile.warning_accepted === false) {
        return;
      }
      
      // Afficher la modale après un court délai
      setTimeout(() => {
        setShowMissingInfoModal(true);
      }, 500);
    }
  };

  const refreshProfile = async () => {
    if (isRefreshingRef.current) {
      console.log('Rafraîchissement du profil déjà en cours, ignoré');
      return;
    }
    
    try {
      console.log('Rafraîchissement du profil...');
      isRefreshingRef.current = true;
      
      const { data: { user: authUser } } = await supabase.auth.getUser();
      
      if (authUser) {
        console.log('Utilisateur trouvé, récupération du profil...');
        const profile = await loadUserProfile(authUser.id);
        
        if (profile) {
          console.log('Profil récupéré:', { 
            userId: profile.id
          });
          
          setUser({
            id: authUser.id,
            first_name: profile.first_name,
            last_name: profile.last_name,
            email: profile.email,
            due_date: profile.due_date,
            phone: profile.phone,
            baby_sex: profile.baby_sex,
            warning_accepted: profile.warning_accepted,
            info_reminder_date: profile.info_reminder_date
          });
          
          // Vérifier à nouveau si des informations sont manquantes après le rafraîchissement
          // Cela permettra d'afficher la modale de profil après la fermeture de la modale d'avertissement
          checkMissingInfo(profile);
        }
      }
    } catch (error) {
      console.error('Erreur lors du rafraîchissement du profil:', error);
    } finally {
      isRefreshingRef.current = false;
    }
  };

  const signOut = async () => {
    try {
      await supabase.auth.signOut();
      setUser(null);
      setSession(null);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        session,
        user,
        loading,
        isAuthenticated: !!user,
        showMissingInfoModal,
        setShowMissingInfoModal,
        refreshProfile,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
