import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';

export type UserProfile = {
  id: string;
  first_name?: string | null;
  last_name?: string | null;
  email?: string | null;
  due_date?: string | null;
  phone?: string | null;
  baby_sex?: string | null;
  warning_accepted?: boolean | null;
};

export type Session = {
  user: UserProfile | null;
};

// Get user profile
export const getUserProfile = async (): Promise<UserProfile | null> => {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    
    if (!user) return null;
    
    const { data: profile } = await supabase
      .from('profiles')
      .select('*')
      .eq('id', user.id)
      .single();
    
    if (!profile) return null;
    
    return {
      id: user.id,
      first_name: profile.first_name,
      last_name: profile.last_name,
      email: profile.email,
      due_date: profile.due_date,
      phone: profile.phone,
      baby_sex: profile.baby_sex,
      warning_accepted: profile.warning_accepted
    };
  } catch (error) {
    console.error('Erreur lors de la récupération du profil:', error);
    return null;
  }
};

// Sign up
export const signUp = async (email: string, password: string, userData: Partial<UserProfile> = {}) => {
  try {
    console.log('Signup with complete userData:', userData);
    
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: {
          first_name: userData.first_name,
          last_name: userData.last_name,
          warning_accepted: userData.warning_accepted || false
        },
        emailRedirectTo: `${window.location.origin}/auth/callback`
      }
    });
    
    if (error) throw error;
    
    // Update additional profile data
    if (data.user) {
      console.log('Updating profile with:', {
        first_name: userData.first_name,
        last_name: userData.last_name,
        warning_accepted: userData.warning_accepted
      });
      
      const { error: updateError } = await supabase
        .from('profiles')
        .update({
          first_name: userData.first_name,
          last_name: userData.last_name,
          warning_accepted: userData.warning_accepted || false,
          updated_at: new Date().toISOString()
        })
        .eq('id', data.user.id);
        
      if (updateError) {
        console.error('Erreur lors de la mise à jour directe du profil:', updateError);
      }
    }
    
    return { success: true, user: data.user };
  } catch (error: any) {
    console.error('Erreur lors de l\'inscription:', error);
    return { success: false, error: error.message || 'Erreur lors de l\'inscription' };
  }
};

// Sign in
export const signIn = async (email: string, password: string) => {
  try {
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password
    });
    
    if (error) throw error;
    
    return { success: true, user: data.user };
  } catch (error: any) {
    console.error('Erreur lors de la connexion:', error);
    return { success: false, error: error.message || 'Erreur lors de la connexion' };
  }
};

// Sign out
export const signOut = async () => {
  try {
    const { error } = await supabase.auth.signOut();
    if (error) throw error;
    return { success: true };
  } catch (error: any) {
    console.error('Erreur lors de la déconnexion:', error);
    return { success: false, error: error.message || 'Erreur lors de la déconnexion' };
  }
};

// Get current session
export const getCurrentSession = async () => {
  try {
    const { data, error } = await supabase.auth.getSession();
    if (error) throw error;
    return data.session;
  } catch (error) {
    console.error('Erreur lors de la récupération de la session:', error);
    return null;
  }
};

// Update user profile
export const updateProfile = async (userData: Partial<UserProfile>) => {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    
    if (!user) {
      throw new Error('Utilisateur non connecté');
    }
    
    console.log('Updating profile for user', user.id, 'with data:', userData);
    
    const { error } = await supabase
      .from('profiles')
      .update({
        first_name: userData.first_name,
        last_name: userData.last_name,
        due_date: userData.due_date,
        phone: userData.phone,
        baby_sex: userData.baby_sex,
        warning_accepted: userData.warning_accepted,
        updated_at: new Date().toISOString()
      })
      .eq('id', user.id);
    
    if (error) throw error;
    
    toast.success('Profil mis à jour avec succès');
    return { success: true };
  } catch (error: any) {
    console.error('Erreur lors de la mise à jour du profil:', error);
    toast.error(error.message || 'Erreur lors de la mise à jour du profil');
    return { success: false, error: error.message || 'Erreur lors de la mise à jour du profil' };
  }
};

// Reset password
export const resetPassword = async (email: string) => {
  try {
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${window.location.origin}/reset-password`,
    });
    
    if (error) throw error;
    
    return { success: true };
  } catch (error: any) {
    console.error('Erreur lors de l\'envoi du lien de réinitialisation:', error);
    return { success: false, error: error.message || 'Erreur lors de l\'envoi du lien de réinitialisation' };
  }
};

// Update password
export const updatePassword = async (newPassword: string) => {
  try {
    const { error } = await supabase.auth.updateUser({
      password: newPassword,
    });
    
    if (error) throw error;
    
    toast.success('Mot de passe mis à jour avec succès');
    return { success: true };
  } catch (error: any) {
    console.error('Erreur lors de la mise à jour du mot de passe:', error);
    toast.error(error.message || 'Erreur lors de la mise à jour du mot de passe');
    return { success: false, error: error.message || 'Erreur lors de la mise à jour du mot de passe' };
  }
};

// Change password
export const changePassword = async (currentPassword: string, newPassword: string) => {
  try {
    // First get the user's email
    const { data: { user } } = await supabase.auth.getUser();
    
    if (!user || !user.email) {
      toast.error('Utilisateur non connecté ou email non disponible');
      return { success: false, error: 'Utilisateur non connecté ou email non disponible' };
    }

    // Verify the current password by attempting a sign-in with it
    const { error: signInError } = await supabase.auth.signInWithPassword({
      email: user.email,
      password: currentPassword,
    });

    if (signInError) {
      toast.error('Mot de passe actuel incorrect');
      return { success: false, error: 'Mot de passe actuel incorrect' };
    }

    // If sign-in is successful, proceed to update the password
    const { error } = await supabase.auth.updateUser({
      password: newPassword,
    });

    if (error) throw error;

    toast.success('Mot de passe mis à jour avec succès');
    return { success: true };
  } catch (error: any) {
    console.error('Erreur lors de la mise à jour du mot de passe:', error);
    toast.error(error.message || 'Erreur lors de la mise à jour du mot de passe');
    return { success: false, error: error.message || 'Erreur lors de la mise à jour du mot de passe' };
  }
};
