import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
const SearchHeader = () => {
  const navigate = useNavigate();
  return <div className="bg-[#fff5ff] py-4 px-4 flex items-center border-b safe-top">
      <button className="mr-2" onClick={() => navigate(-1)}>
        
      </button>
      <h1 className="text-xl font-bold">Recherche de produits</h1>
    </div>;
};
export default SearchHeader;