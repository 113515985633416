import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Search } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { Badge } from '@/components/ui/badge';
import { Card } from '@/components/ui/card';
import { Carousel, CarouselContent, CarouselItem } from '@/components/ui/carousel';
import { supabase } from '@/integrations/supabase/client';
import BottomNavbar from '@/components/BottomNavbar';
import { admobService } from '@/services/admobService';

interface BlogPost {
  id: string;
  title: string;
  excerpt: string;
  category: string;
  image: string;
  date: string;
  content: string;
}

interface Category {
  id: string;
  name: string;
}

const BlogPage = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [activeCategory, setActiveCategory] = useState('all');
  const [blogPosts, setBlogPosts] = useState<BlogPost[]>([]);
  const [categories, setCategories] = useState<Category[]>([
    { id: 'all', name: 'Tous' }
  ]);
  const [loading, setLoading] = useState(true);
  const [adAttempted, setAdAttempted] = useState(false);
  const adContainerRef = useRef<HTMLDivElement>(null);

  const loadBannerAd = async (retryCount = 0, maxRetries = 3) => {
    if (!adContainerRef.current || retryCount >= maxRetries) return;
    
    try {
      console.log(`Tentative de chargement de la publicité (essai ${retryCount + 1}/${maxRetries})...`);
      
      const adContainer = document.getElementById('ad-container');
      if (!adContainer) {
        console.error("L'élément 'ad-container' n'existe pas dans le DOM");
        return;
      }
      
      console.log("État de l'environnement:", {
        isNativeApp: admobService.isNativeApp(),
        isWebViewApp: admobService.isWebViewApp(),
        canShowAds: admobService.canShowAds()
      });
      
      const success = await admobService.showBannerAd('ad-container');
      console.log("Résultat du chargement de la publicité:", success);
      
      if (!success && retryCount < maxRetries) {
        setTimeout(() => {
          loadBannerAd(retryCount + 1, maxRetries);
        }, 2000);
      }
      
      setAdAttempted(true);
    } catch (error) {
      console.error('Erreur lors du chargement de la publicité:', error);
      if (retryCount < maxRetries) {
        setTimeout(() => {
          loadBannerAd(retryCount + 1, maxRetries);
        }, 2000);
      }
    }
  };

  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        setLoading(true);
        const {
          data,
          error
        } = await supabase.from('blog_posts').select('*').order('created_at', { ascending: false });
        if (error) {
          console.error('Erreur lors de la récupération des articles:', error);
          return;
        }
        if (data) {
          const updatedData = data.map(post => {
            if (post.image && post.image.startsWith('/lovable-uploads/')) {
              const category = post.category.toLowerCase();
              let unsplashUrl = '';
              
              if (category.includes('nutrition')) {
                unsplashUrl = 'https://images.unsplash.com/photo-1512621776951-a57141f2eefd?q=80&w=1170&auto=format&fit=crop';
              } else if (category.includes('conseils')) {
                unsplashUrl = 'https://images.unsplash.com/photo-1498837167922-ddd27525d352?q=80&w=1170&auto=format&fit=crop';
              } else if (category.includes('bien-être') || category.includes('bien-etre')) {
                unsplashUrl = 'https://images.unsplash.com/photo-1507120410856-1f35574c3b45?q=80&w=1170&auto=format&fit=crop';
              } else if (category.includes('recettes')) {
                unsplashUrl = 'https://images.unsplash.com/photo-1504674900247-0877df9cc836?q=80&w=1170&auto=format&fit=crop';
              } else if (category.includes('mythes')) {
                unsplashUrl = 'https://images.unsplash.com/photo-1505253758473-96b7015fcd40?q=80&w=1170&auto=format&fit=crop';
              } else {
                unsplashUrl = 'https://images.unsplash.com/photo-1576402187878-974f70c890a5?q=80&w=1333&auto=format&fit=crop';
              }
              
              return { ...post, image: unsplashUrl };
            }
            return post;
          });
          
          setBlogPosts(updatedData);
          
          const uniqueCategories = [...new Set(data.map(article => article.category))];
          
          const categoryList: Category[] = [
            { id: 'all', name: 'Tous' },
            ...uniqueCategories.map(category => ({
              id: category.toLowerCase(),
              name: category
            }))
          ];
          
          setCategories(categoryList);
          
          setTimeout(loadBannerAd, 500);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des articles:', error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchBlogPosts();
    
    const adLoadingTimeout = setTimeout(() => {
      loadBannerAd(0, 5);
    }, 1500);
    
    return () => {
      clearTimeout(adLoadingTimeout);
    };
  }, []);

  useEffect(() => {
    if (adAttempted && !loading) {
      const reloadTimeout = setTimeout(() => {
        console.log("Rechargement de la publicité après changement...");
        loadBannerAd(0, 3);
      }, 500);
      
      return () => {
        clearTimeout(reloadTimeout);
      };
    }
  }, [activeCategory, adAttempted, loading]);

  const filteredArticles = blogPosts.filter(article => {
    const matchesSearch = searchQuery === '' || article.title.toLowerCase().includes(searchQuery.toLowerCase()) || article.excerpt.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesCategory = activeCategory === 'all' || article.category.toLowerCase() === activeCategory.toLowerCase();
    return matchesSearch && matchesCategory;
  });

  const handleViewArticle = (articleId: string) => {
    navigate(`/blog/${articleId}`);
  };

  const renderBlogPosts = () => {
    if (filteredArticles.length === 0) {
      return (
        <div className="bg-white rounded-lg p-8 text-center">
          <p className="text-gray-500">Aucun article trouvé.</p>
        </div>
      );
    }

    return filteredArticles.map(article => (
      <BlogPostCard 
        key={article.id} 
        article={article} 
        handleViewArticle={handleViewArticle} 
      />
    ));
  };

  return (
    <div className="flex flex-col min-h-screen bg-[#fff5ff] pb-28 md:pb-8 safe-area">
      <div className="bg-[#fff5ff] py-4 px-4 flex items-center border-b safe-top">
        <button className="mr-2" onClick={() => navigate(-1)}>
          <ArrowLeft className="h-5 w-5" />
        </button>
        <h1 className="text-xl font-bold">Blog</h1>
      </div>

      <div className="px-4 mb-4 mt-4">
        <div className="relative">
          <Input type="text" placeholder="Rechercher un article" className="pl-10 pr-4 py-2 rounded-full border-pink-300 text-sm" value={searchQuery} onChange={e => setSearchQuery(e.target.value)} />
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
            <Search className="h-5 w-5 text-pink-400" />
          </div>
        </div>
      </div>

      <div className="px-4 mb-4">
        <Carousel className="w-full">
          <CarouselContent>
            {categories.map(category => (
              <CarouselItem key={category.id} className="basis-auto">
                <button 
                  className={`whitespace-nowrap rounded-full px-4 py-1.5 text-sm flex-shrink-0 ${activeCategory === category.id ? 'bg-pink-400 text-white' : 'bg-white text-pink-400 border border-pink-300'}`} 
                  onClick={() => setActiveCategory(category.id)}
                >
                  {category.name}
                </button>
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      </div>

      <div className="px-4 space-y-4 pb-24">
        {loading ? (
          <div className="flex justify-center py-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-pink-500"></div>
          </div>
        ) : (
          renderBlogPosts()
        )}
      </div>

      <BottomNavbar />
    </div>
  );
};

const BlogPostCard = ({ article, handleViewArticle }: { 
  article: BlogPost; 
  handleViewArticle: (id: string) => void 
}) => {
  return (
    <Card key={article.id} className="overflow-hidden rounded-xl">
      <div className="relative">
        <img 
          src={article.image} 
          alt={article.title} 
          className="w-full h-48 object-cover cursor-pointer" 
          onClick={() => handleViewArticle(article.id)} 
        />
        
        <div className="absolute top-4 left-4">
          <Badge className="bg-white text-pink-400 rounded-full hover:bg-white/90">
            {article.category}
          </Badge>
        </div>
      </div>
      
      <div className="p-4">
        <h2 className="font-bold mb-2">{article.title}</h2>
        <p className="text-sm text-gray-700 mb-2">{article.excerpt}</p>
        
        <div className="flex justify-between items-center mt-2">
          <span className="text-xs text-gray-500">{article.date}</span>
          <button 
            className="flex items-center text-xs font-medium text-pink-400" 
            onClick={() => handleViewArticle(article.id)}
          >
            Lire plus <ArrowLeft className="h-3 w-3 ml-1 rotate-180" />
          </button>
        </div>
      </div>
    </Card>
  );
};

export default BlogPage;
