
import React from 'react';
import { DialogContent, Dialog, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { Calendar } from 'lucide-react';

interface ProductViewLimitModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  nextResetDate: Date | null;
}

// This component is simplified to just inform users that all features are now free
const ProductViewLimitModal = ({ open, onOpenChange }: ProductViewLimitModalProps) => {
  const navigate = useNavigate();
  
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Nutrimam est désormais entièrement gratuit!</DialogTitle>
          <DialogDescription>
            Toutes les fonctionnalités de Nutrimam sont maintenant accessibles gratuitement.
          </DialogDescription>
        </DialogHeader>
        
        <div className="flex flex-col items-center gap-4 py-4">
          <div className="h-20 w-20 rounded-full bg-pink-100 flex items-center justify-center">
            <Calendar className="h-10 w-10 text-pink-500" />
          </div>
          
          <div className="text-center">
            <p className="text-sm text-gray-700 mb-2">
              Profitez de toutes les fonctionnalités sans aucune limitation.
            </p>
          </div>
        </div>
        
        <DialogFooter>
          <Button onClick={() => onOpenChange(false)}>Continuer</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ProductViewLimitModal;
