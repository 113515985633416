
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, User, Lock, LogOut, Trash, ChevronRight } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { toast } from 'sonner';
import { signOut } from '@/services/authService';
import { useAuth } from '@/hooks/useAuth';
import { 
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/components/ui/alert-dialog';
import { deleteAccount } from '@/services/deleteAccountService';
import { getUserSubscription } from '@/services/subscriptionService';
import BottomNavbar from '@/components/BottomNavbar';

const AccountPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [subscription, setSubscription] = useState<any>(null);
  const [isLoadingSubscription, setIsLoadingSubscription] = useState(false); // Changed to false since we don't need to load subscriptions
  
  useEffect(() => {
    document.title = "Mon Compte | NutriMam";
    return () => {
      document.title = "NutriMam";
    };
  }, []);
  
  // We don't need to load subscriptions anymore as all features are free
  // The effect is kept for compatibility but does nothing
  useEffect(() => {
    // No-op as all features are now free
    setIsLoadingSubscription(false);
  }, [user]);
  
  const handleLogout = async () => {
    const result = await signOut();
    
    if (result.success) {
      toast.success("Vous avez été déconnecté avec succès.");
      navigate('/');
    } else {
      toast.error(result.error || "Erreur lors de la déconnexion");
    }
  };

  const handleDeleteAccount = async () => {
    if (!user) {
      toast.error("Vous devez être connecté pour supprimer votre compte");
      return;
    }

    try {
      setIsDeleting(true);
      const toastId = toast.loading("Suppression du compte en cours...");

      const { success, error } = await deleteAccount();
      
      toast.dismiss(toastId);
      
      if (!success) {
        throw new Error(error);
      }
      
      setIsDialogOpen(false);
      toast.success("Votre compte a été supprimé avec succès");
      navigate('/', { replace: true });
    } catch (error: any) {
      console.error('Erreur lors de la suppression du compte:', error);
      toast.error(error.message || "Erreur lors de la suppression du compte");
    } finally {
      setIsDeleting(false);
    }
  };
  
  // All accounts can now delete as all features are free
  const canDeleteAccount = true;
  
  const deleteAccountMessage = () => {
    return "Toutes mes informations seront perdues";
  };
  
  return <div className="flex flex-col min-h-screen bg-[#fff5ff] pb-16 md:pb-0 safe-area">
      <div className="bg-[#fff5ff] py-4 px-4 flex items-center border-b safe-top">
        <button className="mr-2" onClick={() => navigate('/settings')}>
          <ArrowLeft className="h-6 w-6" />
        </button>
        <h1 className="text-xl font-bold">Mon compte</h1>
      </div>

      <div className="flex-1 px-4 py-4">
        <div className="space-y-0 mb-4">
          <div className="bg-pink-400 rounded-t-xl p-3">
            <h2 className="text-white font-semibold">Gérer mon compte</h2>
          </div>
          
          <div className="bg-white rounded-b-xl overflow-hidden shadow-sm">
            <button 
              className="w-full flex items-center justify-between p-4 border-b"
              onClick={() => navigate('/profile')}
            >
              <div className="flex items-center">
                <User className="h-5 w-5 text-pink-400 mr-3" />
                <p className="font-medium text-pink-400">Mon profil</p>
              </div>
              <ChevronRight className="h-5 w-5 text-gray-400" />
            </button>
            
            <button 
              className="w-full flex items-center justify-between p-4 border-b"
              onClick={() => navigate('/change-password')}
            >
              <div className="flex items-center">
                <Lock className="h-5 w-5 text-pink-400 mr-3" />
                <p className="font-medium text-pink-400">Modifier mon mot de passe</p>
              </div>
              <ChevronRight className="h-5 w-5 text-gray-400" />
            </button>
            
            <button 
              className="w-full flex items-center justify-between p-4"
              onClick={handleLogout}
            >
              <div className="flex items-center">
                <LogOut className="h-5 w-5 text-pink-400 mr-3" />
                <p className="font-medium text-pink-400">Me déconnecter</p>
              </div>
              <ChevronRight className="h-5 w-5 text-gray-400" />
            </button>
          </div>
        </div>

        <div className="space-y-0">
          <div className="bg-pink-400 rounded-t-xl p-3">
            <h2 className="text-white font-semibold">Zone dangereuse</h2>
          </div>
          
          <div className="bg-white rounded-b-xl overflow-hidden shadow-sm">
            <AlertDialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
              <AlertDialogTrigger asChild>
                <button 
                  className="w-full flex items-center justify-between p-4"
                  disabled={isLoadingSubscription || !canDeleteAccount}
                >
                  <div className="flex flex-col items-start">
                    <div className="flex items-center">
                      <Trash className={`h-5 w-5 mr-3 ${canDeleteAccount ? 'text-pink-400' : 'text-gray-400'}`} />
                      <p className={`font-medium ${canDeleteAccount ? 'text-pink-400' : 'text-gray-400'}`}>Supprimer mon compte</p>
                    </div>
                    <p className="text-sm text-gray-500 ml-8 text-left">
                      {isLoadingSubscription ? "Vérification de votre abonnement..." : deleteAccountMessage()}
                    </p>
                  </div>
                  <ChevronRight className="h-5 w-5 text-gray-400" />
                </button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Êtes-vous sûr(e) de vouloir supprimer votre compte ?</AlertDialogTitle>
                  <AlertDialogDescription>
                    Cette action est irréversible. Toutes vos données personnelles, 
                    historiques de scans et préférences seront définitivement supprimées.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel onClick={() => setIsDialogOpen(false)}>Annuler</AlertDialogCancel>
                  <AlertDialogAction 
                    className="bg-red-500 hover:bg-red-600"
                    onClick={handleDeleteAccount}
                    disabled={isDeleting}
                  >
                    {isDeleting ? 'Suppression...' : 'Supprimer définitivement'}
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </div>
        </div>
      </div>

      <BottomNavbar />
    </div>;
};
export default AccountPage;
