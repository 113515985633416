
import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getNutriScoreImage } from '@/utils/productUtils';

interface Product {
  product_name: string;
  brands: string;
  image_url: string;
  code: string;
  quantity: string;
  nutriscore_grade?: string;
  nova_group?: string;
}

interface SearchResults {
  products: Product[];
  count: number;
  page: number;
  page_count: number;
  page_size: number;
}

export const useProductSearch = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [nutriScoreImages, setNutriScoreImages] = useState<{ [key: string]: string; }>({});

  const {
    data,
    isLoading,
    error,
    refetch
  } = useQuery<SearchResults>({
    queryKey: ['products', searchTerm],
    queryFn: async () => {
      if (!searchTerm) return {
        products: [],
        count: 0,
        page: 0,
        page_count: 0,
        page_size: 0
      };
      const response = await fetch(`https://world.openfoodfacts.org/cgi/search.pl?search_terms=${encodeURIComponent(searchTerm)}&search_simple=1&action=process&json=1&page_size=15`);
      if (!response.ok) {
        throw new Error('Erreur de réseau');
      }
      return response.json();
    },
    enabled: !!searchTerm
  });

  useEffect(() => {
    const loadNutriScoreImages = async () => {
      if (!data?.products) return;
      const scores: string[] = ['a', 'b', 'c', 'd', 'e', 'unknown'];
      const imageUrls: {
        [key: string]: string;
      } = {};
      for (const score of scores) {
        try {
          const imageUrl = await getNutriScoreImage(score);
          imageUrls[score] = imageUrl;
        } catch (error) {
          console.error(`Error loading nutriscore image for ${score}:`, error);
        }
      }
      setNutriScoreImages(imageUrls);
    };
    loadNutriScoreImages();
  }, [data?.products]);

  const handleSearch = () => {
    setSearchTerm(searchQuery.trim());
  };

  return {
    searchQuery,
    setSearchQuery,
    searchTerm,
    data,
    isLoading,
    error,
    nutriScoreImages,
    handleSearch
  };
};
