import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft, Share2 } from 'lucide-react';
import { supabase } from '@/integrations/supabase/client';
import { useToast } from '@/hooks/use-toast';
import { Button } from '@/components/ui/button';
import { useAuth } from '@/hooks/useAuth';
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";
import BottomNavbar from '@/components/BottomNavbar';

interface BlogPost {
  id: string;
  title: string;
  excerpt: string;
  category: string;
  content: string;
  image: string;
  date: string;
  author: string;
  sources: string;
}

const BlogArticlePage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [article, setArticle] = useState<BlogPost | null>(null);
  const [loading, setLoading] = useState(true);
  const [shareError, setShareError] = useState<string | null>(null);
  const { toast } = useToast();
  const { user } = useAuth();
  
  const isAuthenticated = !!user;

  useEffect(() => {
    const fetchArticleData = async () => {
      try {
        setLoading(true);
        if (!id) return;

        const { data: articleData, error: articleError } = await supabase
          .from('blog_posts')
          .select('*')
          .eq('id', id)
          .single();

        if (articleError) {
          console.error('Erreur lors de la récupération de l\'article:', articleError);
          return;
        }

        if (articleData) {
          if (articleData.image && articleData.image.startsWith('/lovable-uploads/')) {
            const category = articleData.category.toLowerCase();
            let unsplashUrl = '';
            
            if (category.includes('nutrition')) {
              unsplashUrl = 'https://images.unsplash.com/photo-1512621776951-a57141f2eefd?q=80&w=1170&auto=format&fit=crop';
            } else if (category.includes('conseils')) {
              unsplashUrl = 'https://images.unsplash.com/photo-1498837167922-ddd27525d352?q=80&w=1170&auto=format&fit=crop';
            } else if (category.includes('bien-être') || category.includes('bien-etre')) {
              unsplashUrl = 'https://images.unsplash.com/photo-1507120410856-1f35574c3b45?q=80&w=1170&auto=format&fit=crop';
            } else if (category.includes('recettes')) {
              unsplashUrl = 'https://images.unsplash.com/photo-1504674900247-0877df9cc836?q=80&w=1170&auto=format&fit=crop';
            } else if (category.includes('mythes')) {
              unsplashUrl = 'https://images.unsplash.com/photo-1505253758473-96b7015fcd40?q=80&w=1170&auto=format&fit=crop';
            } else {
              unsplashUrl = 'https://images.unsplash.com/photo-1576402187878-974f70c890a5?q=80&w=1333&auto=format&fit=crop';
            }
            
            articleData.image = unsplashUrl;
          }
          
          setArticle(articleData as BlogPost);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticleData();
  }, [id]);

  const handleShare = async () => {
    if (!article) return;

    try {
      setShareError(null);
      const shareUrl = `${window.location.origin}/blog/${id}`;
      await navigator.clipboard.writeText(shareUrl);
      toast({
        description: "Lien copié dans le presse-papier!",
        duration: 3000,
      });
    } catch (error) {
      console.error('Erreur lors du partage:', error);
      setShareError("Impossible de partager l'article. Vérifiez que vous avez accordé les permissions nécessaires.");
      toast({
        variant: "destructive",
        description: "Impossible de copier le lien. Vérifiez les permissions de votre navigateur.",
        duration: 3000,
      });
    }
  };

  const handleBack = () => {
    if (isAuthenticated) {
      navigate('/blog');
    } else {
      navigate('/');
    }
  };

  if (loading) {
    return (
      <div className="flex flex-col min-h-screen bg-[#fff5ff] p-4 justify-center items-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-pink-500"></div>
      </div>
    );
  }

  if (!article) {
    return (
      <div className="flex flex-col min-h-screen bg-[#fff5ff] p-4">
        <div className="flex items-center mb-4">
          <button onClick={handleBack} className="mr-4">
            <ArrowLeft className="h-6 w-6" />
          </button>
          <h1 className="text-xl font-bold">Article</h1>
        </div>
        <div className="bg-white rounded-lg p-4 text-center">
          Article introuvable
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen bg-[#fff5ff] pb-28 safe-area">
      <div className="bg-[#fff5ff] px-4 py-4 flex justify-between items-center border-b safe-top">
        <div className="flex items-center">
          <button onClick={handleBack} className="mr-4">
            <ArrowLeft className="h-6 w-6" />
          </button>
          <h1 className="text-xl font-bold">Article</h1>
        </div>
        <Button 
          variant="ghost" 
          size="sm" 
          onClick={handleShare} 
          className="text-gray-700 p-2"
        >
          <Share2 className="h-5 w-5 text-pink-400" />
        </Button>
      </div>

      {shareError && (
        <Alert variant="destructive" className="mx-4 mt-2">
          <AlertTitle>Erreur de partage</AlertTitle>
          <AlertDescription>{shareError}</AlertDescription>
        </Alert>
      )}

      <div className="px-4 pb-8">
        <div className="rounded-lg overflow-hidden mb-4 border-2 border-blue-200 mt-4">
          <img 
            src={article.image} 
            alt={article.title} 
            className="w-full h-48 object-cover"
          />
          <div className="p-3 bg-white">
            <h2 className="text-xl font-bold text-pink-400">{article.title}</h2>
          </div>
        </div>

        <div className="bg-white rounded-lg p-4 space-y-6 pb-24">
          <article 
            className="article-content prose prose-pink max-w-none"
            dangerouslySetInnerHTML={{ 
              __html: article.content || '<p>Contenu à venir</p>' 
            }} 
          />
          
          <div className="mt-8 pt-6 border-t border-gray-200">
            <div className="space-y-3">
              <div>
                <h4 className="text-sm font-semibold text-gray-500">AUTEUR</h4>
                <p className="text-gray-800">{article.author}</p>
              </div>
              
              <div>
                <h4 className="text-sm font-semibold text-gray-500">SOURCES</h4>
                <p className="text-sm text-gray-600 italic">{article.sources}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isAuthenticated && <BottomNavbar />}
    </div>
  );
};

export default BlogArticlePage;
