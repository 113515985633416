
import React from 'react';
import ProductCard from './ProductCard';

interface Product {
  product_name: string;
  brands: string;
  image_url: string;
  code: string;
  quantity: string;
  nutriscore_grade?: string;
  nova_group?: string;
}

interface SearchResultsProps {
  isLoading: boolean;
  error: unknown;
  searchTerm: string;
  data: {
    products: Product[];
    count: number;
  } | undefined;
  nutriScoreImages: { [key: string]: string; };
  onProductClick: (code: string) => void;
}

const SearchResults = ({ 
  isLoading, 
  error, 
  searchTerm, 
  data, 
  nutriScoreImages, 
  onProductClick 
}: SearchResultsProps) => {
  const displayProducts = data?.products?.slice(0, 15) || [];

  if (isLoading) {
    return (
      <div className="flex justify-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-pink-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 text-center text-red-500">
        Une erreur est survenue lors de la recherche. Veuillez réessayer.
      </div>
    );
  }

  if (displayProducts.length > 0) {
    return (
      <>
        <div className="text-sm text-gray-600 mb-2">
          {data?.count} résultats trouvés pour "{searchTerm}" (affichage des 15 premiers)
        </div>
        <div className="space-y-4">
          {displayProducts.map(product => (
            <ProductCard 
              key={product.code} 
              product={product} 
              onClick={onProductClick} 
              nutriScoreImages={nutriScoreImages}
            />
          ))}
        </div>
      </>
    );
  }

  if (searchTerm) {
    return (
      <div className="p-4 text-center text-gray-500">
        Aucun produit trouvé pour "{searchTerm}"
      </div>
    );
  }

  return (
    <div className="p-8 text-center text-gray-500">
      Recherchez des produits alimentaires pour voir les résultats
    </div>
  );
};

export default SearchResults;
