
import { useState, useEffect } from 'react';
import { useAuth } from '@/hooks/useAuth';

export const useWarningModal = () => {
  const [showWarningModal, setShowWarningModal] = useState(false);
  const { user, refreshProfile, setShowMissingInfoModal } = useAuth();

  useEffect(() => {
    // Vérifie si l'utilisateur est connecté et si warning_accepted est false
    if (user && user.warning_accepted === false) {
      setShowWarningModal(true);
      // Masquer la modale de profil si la modale d'avertissement est affichée
      setShowMissingInfoModal(false);
    } else {
      setShowWarningModal(false);
    }
  }, [user, setShowMissingInfoModal]);

  const closeWarningModal = async () => {
    setShowWarningModal(false);
    // Rafraîchir le profil pour mettre à jour l'état
    await refreshProfile();
    // Après avoir fermé la modale d'avertissement, vérifier si des informations sont manquantes
    // La logique de vérification et d'affichage sera gérée dans AuthContext
  };

  return {
    showWarningModal,
    closeWarningModal
  };
};
