
import { supabase } from '@/integrations/supabase/client';
import { Product } from '@/utils/productUtils';
import { HistoryEntry } from './types';

/**
 * Adds a product to the Supabase history
 */
export const addToSupabaseHistory = async (
  userId: string, 
  product: Product, 
  preventDuplication: boolean = false
): Promise<boolean> => {
  try {
    // If we want to avoid duplications, check if the product exists in recent history
    if (preventDuplication) {
      const { data: existingEntries } = await supabase
        .from('scan_history')
        .select('id')
        .eq('user_id', userId)
        .eq('product_id', product.id.toString())
        .order('scanned_at', { ascending: false })
        .limit(1);
      
      // If the product already exists in recent history, don't add it again
      if (existingEntries && existingEntries.length > 0) {
        console.log('Produit déjà dans l\'historique récent, pas d\'ajout');
        return false;
      }
    }
    
    // Add the product to history in Supabase
    const { error } = await supabase
      .from('scan_history')
      .insert({
        user_id: userId,
        product_id: product.id.toString(),
        product_name: product.name,
        product_brand: product.brand,
        product_image_url: product.imageUrl,
        product_nutri_score: product.nutriScore,
        product_nova_score: product.novaScore,
        product_weight: product.weight,
        category_type: product.status
      });
    
    if (error) {
      console.error('Erreur Supabase lors de l\'ajout à l\'historique:', error);
      return false;
    }
    
    return true;
  } catch (error) {
    console.error('Erreur lors de l\'ajout à l\'historique Supabase:', error);
    return false;
  }
};

/**
 * Retrieves history from Supabase
 */
export const getSupabaseHistory = async (userId: string, limit?: number): Promise<HistoryEntry[]> => {
  try {
    // Build the query
    const query = supabase
      .from('scan_history')
      .select('*')
      .eq('user_id', userId)
      .order('scanned_at', { ascending: false });
    
    if (limit) {
      query.limit(limit);
    }
    
    const { data, error } = await query;
    
    if (error) {
      console.error('Erreur Supabase lors de la récupération de l\'historique:', error);
      return [];
    }
    
    // Convert Supabase data to HistoryEntry objects
    return data.map(item => ({
      id: parseInt(item.product_id),
      entryId: item.id.toString(), // Use the history entry ID as unique identifier
      name: item.product_name || '',
      fullName: item.product_name || '',
      brand: item.product_brand || '',
      weight: item.product_weight || '',
      category: '', // Adding missing property
      imageUrl: item.product_image_url || '/placeholder.svg',
      nutriScore: item.product_nutri_score || '',
      novaScore: item.product_nova_score || 0,
      status: item.category_type || '',
      categories: [],
      allergens: [],
      warnings: [],
      detailedAllergens: [],
      ingredients: [],
      ingredientCount: 0,
      nutrition: {
        items: [],
        energy: 'N/A',
        energyKcal: 'N/A',
        carbohydrates: 'N/A',
        proteins: 'N/A',
        fiber: 'N/A'
      }
    }));
  } catch (error) {
    console.error('Erreur lors de la récupération de l\'historique Supabase:', error);
    return [];
  }
};

/**
 * Removes an entry from Supabase history
 */
export const removeFromSupabaseHistory = async (userId: string, entryId: string): Promise<boolean> => {
  try {
    const { error } = await supabase
      .from('scan_history')
      .delete()
      .eq('id', entryId)
      .eq('user_id', userId);
    
    if (error) {
      console.error('Erreur Supabase lors de la suppression de l\'entrée de l\'historique:', error);
      return false;
    }
    
    return true;
  } catch (error) {
    console.error('Erreur lors de la suppression de l\'entrée de l\'historique Supabase:', error);
    return false;
  }
};

/**
 * Clears all Supabase history for a user
 */
export const clearSupabaseHistory = async (userId: string): Promise<boolean> => {
  try {
    const { error } = await supabase
      .from('scan_history')
      .delete()
      .eq('user_id', userId);
    
    if (error) {
      console.error('Erreur Supabase lors de la suppression de l\'historique:', error);
      return false;
    }
    
    return true;
  } catch (error) {
    console.error('Erreur lors de la suppression de l\'historique Supabase:', error);
    return false;
  }
};
