
import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider, useAuth } from "@/hooks/useAuth";
import ProtectedRoute from "@/components/ProtectedRoute";
// PremiumGuard is still imported but now it just renders its children
import PremiumGuard from "@/components/PremiumGuard";
import Index from "./pages/Index";
import NotFound from "./pages/NotFound";
import HomePage from "./pages/HomePage";
import HistoryPage from "./pages/HistoryPage";
import BlogPage from "./pages/BlogPage";
import BlogArticlePage from "./pages/BlogArticlePage";
import SettingsPage from "./pages/SettingsPage";
import AccountPage from "./pages/AccountPage";
import ProfilePage from "./pages/ProfilePage";
import EditProfilePage from "./pages/EditProfilePage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import AllergyPage from "./pages/AllergyPage";
import ContactPage from "./pages/ContactPage";
import ContactExternePage from "./pages/ContactExternePage";
import LegalInfoPage from "./pages/LegalInfoPage";
import SearchPage from "./pages/SearchPage";
import ProductDetailPage from "./pages/ProductDetailPage";
import AuthCallback from "./pages/AuthCallback";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import WarningModal from "./components/WarningModal";
import { useWarningModal } from "./hooks/useWarningModal";
import MissingInfoModal from "./components/MissingInfoModal";
import SignUpConfirmationPage from "./pages/SignUpConfirmationPage";

const queryClient = new QueryClient();

const MissingInfoModalController = () => {
  const { user, showMissingInfoModal, setShowMissingInfoModal, refreshProfile } = useAuth();
  const { showWarningModal } = useWarningModal();
  
  // Ne pas afficher la modale de profil si la modale d'avertissement est affichée
  const shouldShowMissingInfoModal = showMissingInfoModal && !showWarningModal;
  
  if (!user) return null;
  
  return (
    <MissingInfoModal 
      open={shouldShowMissingInfoModal} 
      onOpenChange={setShowMissingInfoModal}
      onCompleted={refreshProfile}
      userId={user.id}
    />
  );
};

const AppWithWarning = () => {
  const { showWarningModal, closeWarningModal } = useWarningModal();
  
  return (
    <>
      <WarningModal isOpen={showWarningModal} onClose={closeWarningModal} />
      <MissingInfoModalController />
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/auth/callback" element={<AuthCallback />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        
        {/* Public Routes */}
        <Route path="/blog/:id" element={<BlogArticlePage />} />
        <Route path="/legal" element={<LegalInfoPage />} />
        <Route path="/contact-externe" element={<ContactExternePage />} />
        <Route path="/signup-confirmation" element={<SignUpConfirmationPage />} />
        
        {/* Protected Routes */}
        <Route path="/home" element={
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        } />
        <Route path="/history" element={
          <ProtectedRoute>
            <HistoryPage />
          </ProtectedRoute>
        } />
        <Route path="/blog" element={
          <ProtectedRoute>
            <BlogPage />
          </ProtectedRoute>
        } />
        <Route path="/settings" element={
          <ProtectedRoute>
            <SettingsPage />
          </ProtectedRoute>
        } />
        <Route path="/account" element={
          <ProtectedRoute>
            <AccountPage />
          </ProtectedRoute>
        } />
        <Route path="/profile" element={
          <ProtectedRoute>
            <ProfilePage />
          </ProtectedRoute>
        } />
        <Route path="/edit-profile" element={
          <ProtectedRoute>
            <EditProfilePage />
          </ProtectedRoute>
        } />
        <Route path="/change-password" element={
          <ProtectedRoute>
            <ChangePasswordPage />
          </ProtectedRoute>
        } />
        <Route path="/allergies" element={
          <ProtectedRoute>
            <AllergyPage />
          </ProtectedRoute>
        } />
        <Route path="/contact" element={
          <ProtectedRoute>
            <ContactPage />
          </ProtectedRoute>
        } />
        <Route path="/search" element={
          <ProtectedRoute>
            <SearchPage />
          </ProtectedRoute>
        } />
        <Route path="/product/:id" element={
          <ProtectedRoute>
            <ProductDetailPage />
          </ProtectedRoute>
        } />
        
        {/* Catch-all route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

const App = () => (
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <TooltipProvider>
        <Toaster />
        <Sonner />
        <BrowserRouter>
          <AppWithWarning />
        </BrowserRouter>
      </TooltipProvider>
    </AuthProvider>
  </QueryClientProvider>
);

export default App;
