
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Settings, History, Camera, Search, FileText } from 'lucide-react';

const BottomNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  // Déterminer la route active
  const isActive = (path: string) => {
    return location.pathname === path;
  };
  
  return (
    <div className="fixed bottom-0 left-0 right-0 border-t bg-white safe-bottom">
      <div className="flex justify-around py-5">
        <button 
          className={`flex flex-col items-center ${isActive('/settings') ? 'text-pink-400' : 'text-gray-400'}`} 
          onClick={() => navigate('/settings')}
        >
          <Settings className="h-6 w-6" />
          <span className="text-xs mt-1 mb-2">Paramètres</span>
        </button>
        <button 
          className={`flex flex-col items-center ${isActive('/history') ? 'text-pink-400' : 'text-gray-400'}`} 
          onClick={() => navigate('/history')}
        >
          <History className="h-6 w-6" />
          <span className="text-xs mt-1 mb-2">Historique</span>
        </button>
        <button 
          className={`flex flex-col items-center ${isActive('/home') ? 'text-pink-400' : 'text-gray-400'}`} 
          onClick={() => navigate('/home')}
        >
          <Camera className="h-6 w-6" />
          <span className="text-xs mt-1 mb-2">Scan</span>
        </button>
        <button 
          className={`flex flex-col items-center ${isActive('/search') ? 'text-pink-400' : 'text-gray-400'}`}
          onClick={() => navigate('/search')}
        >
          <Search className="h-6 w-6" />
          <span className="text-xs mt-1 mb-2">Recherche</span>
        </button>
        <button 
          className={`flex flex-col items-center ${isActive('/blog') ? 'text-pink-400' : 'text-gray-400'}`}
          onClick={() => navigate('/blog')}
        >
          <FileText className="h-6 w-6" />
          <span className="text-xs mt-1 mb-2">Blog</span>
        </button>
      </div>
    </div>
  );
};

export default BottomNavbar;
