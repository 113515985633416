
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Lock, CheckCircle } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [resetComplete, setResetComplete] = useState(false);
  const [tokenError, setTokenError] = useState(false);

  useEffect(() => {
    // Vérifier si un hash est présent dans l'URL (cas de redirection depuis email)
    const checkSession = async () => {
      const { data, error } = await supabase.auth.getSession();
      
      if (error) {
        console.error('Erreur lors de la vérification de la session:', error);
        setTokenError(true);
        return;
      }
      
      // Si aucune session et aucun hash, l'utilisateur pourrait être arrivé directement
      if (!data.session && !window.location.hash) {
        setTokenError(true);
      }
    };
    
    checkSession();
  }, []);

  const validatePassword = (password: string) => {
    if (password.length < 6) {
      return 'Le mot de passe doit contenir au moins 6 caractères';
    }
    
    if (!/[a-z]/.test(password)) {
      return 'Le mot de passe doit contenir au moins une lettre minuscule';
    }
    
    if (!/[A-Z]/.test(password)) {
      return 'Le mot de passe doit contenir au moins une lettre majuscule';
    }
    
    if (!/[0-9]/.test(password)) {
      return 'Le mot de passe doit contenir au moins un chiffre';
    }
    
    return null;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    
    // Validation
    const passwordError = validatePassword(newPassword);
    if (passwordError) {
      setError(passwordError);
      return;
    }
    
    if (newPassword !== confirmPassword) {
      setError('Les mots de passe ne correspondent pas');
      return;
    }
    
    setIsSubmitting(true);
    
    try {
      const { error } = await supabase.auth.updateUser({
        password: newPassword
      });
      
      if (error) {
        throw error;
      }
      
      toast.success('Mot de passe mis à jour avec succès');
      setResetComplete(true);
      
      // Rediriger après un court délai
      setTimeout(() => {
        navigate('/');
      }, 3000);
    } catch (error: any) {
      console.error('Erreur lors de la réinitialisation du mot de passe:', error);
      setError(error.message || 'Une erreur est survenue lors de la réinitialisation du mot de passe');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (tokenError) {
    return (
      <div className="min-h-screen w-full flex flex-col bg-pink-50 p-6">
        <div className="flex items-center mb-8">
          <button onClick={() => navigate('/')} className="p-2 rounded-full hover:bg-pink-100" aria-label="Back">
            <ArrowLeft className="h-6 w-6 text-nutrimam-accent" />
          </button>
          <h1 className="text-lg font-medium text-center flex-1 pr-8">Réinitialisation du mot de passe</h1>
        </div>
        
        <div className="flex-1 flex flex-col items-center justify-center">
          <Alert variant="destructive" className="mb-6">
            <AlertDescription>
              Ce lien de réinitialisation est invalide ou a expiré. Veuillez demander un nouveau lien de réinitialisation.
            </AlertDescription>
          </Alert>
          
          <Button 
            onClick={() => navigate('/')} 
            className="w-full py-6 bg-nutrimam-accent hover:bg-nutrimam-accent/90 text-white rounded-xl font-semibold text-lg"
          >
            Retour à l'accueil
          </Button>
        </div>
      </div>
    );
  }

  if (resetComplete) {
    return (
      <div className="min-h-screen w-full flex flex-col bg-pink-50 p-6">
        <div className="flex items-center mb-8">
          <h1 className="text-lg font-medium text-center flex-1">Réinitialisation réussie</h1>
        </div>
        
        <div className="flex-1 flex flex-col items-center justify-center text-center">
          <CheckCircle className="h-20 w-20 text-green-500 mb-6" />
          <h2 className="text-xl font-semibold mb-2">Mot de passe mis à jour</h2>
          <p className="text-gray-600 mb-8">
            Votre mot de passe a été réinitialisé avec succès.<br />
            Vous allez être redirigé vers la page de connexion.
          </p>
          
          <Button 
            onClick={() => navigate('/')} 
            className="w-full py-6 bg-nutrimam-accent hover:bg-nutrimam-accent/90 text-white rounded-xl font-semibold text-lg"
          >
            Se connecter
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen w-full flex flex-col bg-pink-50 p-6">
      {/* Header */}
      <div className="flex items-center mb-8">
        <button onClick={() => navigate('/')} className="p-2 rounded-full hover:bg-pink-100" aria-label="Back">
          <ArrowLeft className="h-6 w-6 text-nutrimam-accent" />
        </button>
        <h1 className="text-lg font-medium text-center flex-1 pr-8">Réinitialisation du mot de passe</h1>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="text-center mb-4">
            <p className="text-gray-700">
              Créez un nouveau mot de passe pour<br />
              votre compte Nutrimam.
            </p>
          </div>

          {/* New Password Input */}
          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <Lock className="h-5 w-5 text-gray-500" />
              <label htmlFor="new-password" className="text-sm font-medium text-gray-700">Nouveau mot de passe</label>
            </div>
            <Input 
              id="new-password" 
              type="password" 
              value={newPassword} 
              onChange={e => setNewPassword(e.target.value)} 
              placeholder="Entrez votre nouveau mot de passe" 
              required 
              className="border-gray-300" 
            />
            <p className="text-xs text-gray-500">
              Doit contenir au moins 6 caractères, une lettre minuscule, une lettre majuscule et un chiffre.
            </p>
          </div>

          {/* Confirm Password Input */}
          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <Lock className="h-5 w-5 text-gray-500" />
              <label htmlFor="confirm-password" className="text-sm font-medium text-gray-700">Confirmer le mot de passe</label>
            </div>
            <Input 
              id="confirm-password" 
              type="password" 
              value={confirmPassword} 
              onChange={e => setConfirmPassword(e.target.value)} 
              placeholder="Confirmez votre nouveau mot de passe" 
              required 
              className="border-gray-300" 
            />
          </div>

          {/* Error Alert */}
          {error && <Alert variant="destructive">
            <AlertDescription>{error}</AlertDescription>
          </Alert>}

          {/* Submit Button */}
          <Button 
            type="submit" 
            disabled={isSubmitting} 
            className="w-full py-6 bg-nutrimam-accent hover:bg-nutrimam-accent/90 text-white rounded-xl font-semibold text-lg"
          >
            {isSubmitting ? 'Mise à jour...' : 'Mettre à jour le mot de passe'}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
