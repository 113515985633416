import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { X, Trash2 } from 'lucide-react';
import { Card } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Button } from '@/components/ui/button';
import { Product } from '@/utils/productUtils';
import { getHistory, removeFromHistory, clearHistory, HistoryEntry } from '@/services/historyService';
import { toast } from 'sonner';
import { getNutriScoreImage, getNovaScoreColor } from '@/utils/productUtils';
import useAuth from '@/hooks/useAuth';
import BottomNavbar from '@/components/BottomNavbar';

const HistoryPage = () => {
  const navigate = useNavigate();
  const {
    isAuthenticated
  } = useAuth();
  const [items, setItems] = useState<HistoryEntry[]>([]);
  const [nutriScoreImages, setNutriScoreImages] = useState<{
    [key: string]: string;
  }>({});
  const [swipingStates, setSwipingStates] = useState<Record<string, {
    offset: number;
    isSwiping: boolean;
    startX?: number;
  }>>({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadHistory();
    loadNutriScoreImages();
  }, []);

  const loadHistory = async () => {
    setIsLoading(true);
    try {
      const historyItems = await getHistory();
      setItems(historyItems);
    } catch (error) {
      console.error('Erreur lors du chargement de l\'historique:', error);
      toast.error('Impossible de charger l\'historique');
    } finally {
      setIsLoading(false);
    }
  };

  const loadNutriScoreImages = async () => {
    const scores: string[] = ['a', 'b', 'c', 'd', 'e', 'unknown'];
    const imageUrls: {
      [key: string]: string;
    } = {};
    for (const score of scores) {
      try {
        const imageUrl = await getNutriScoreImage(score);
        imageUrls[score] = imageUrl;
      } catch (error) {
        console.error(`Error loading nutriscore image for ${score}:`, error);
      }
    }
    setNutriScoreImages(imageUrls);
  };

  const getUniqueItemKey = (item: HistoryEntry): string => {
    return `entry-${item.entryId}`;
  };

  const handleSwipeStart = (key: string, clientX: number) => {
    setSwipingStates(prev => ({
      ...prev,
      [key]: {
        offset: 0,
        isSwiping: true,
        startX: clientX
      }
    }));
  };

  const handleSwipeMove = (key: string, clientX: number) => {
    if (!swipingStates[key]?.isSwiping) return;
    const startX = swipingStates[key].startX || 0;
    const offset = clientX - startX;
    const limitedOffset = Math.max(Math.min(offset, 0), -100);
    setSwipingStates(prev => ({
      ...prev,
      [key]: {
        ...prev[key],
        offset: limitedOffset
      }
    }));
  };

  const handleSwipeEnd = (key: string, entryId: string) => {
    const offset = swipingStates[key]?.offset || 0;
    if (offset < -50) {
      handleDeleteItem(entryId);
    }
    setSwipingStates(prev => ({
      ...prev,
      [key]: {
        offset: 0,
        isSwiping: false
      }
    }));
  };

  const handleDeleteItem = async (entryId: string) => {
    try {
      await removeFromHistory(entryId);
      setItems(items.filter(item => item.entryId !== entryId));
      toast.success("Produit supprimé de l'historique", {
        duration: 1000
      });
    } catch (error) {
      console.error('Erreur lors de la suppression du produit:', error);
      toast.error("Erreur lors de la suppression du produit", {
        duration: 1000
      });
    }
  };

  const handleClearHistory = async () => {
    try {
      await clearHistory();
      setItems([]);
      toast.success("Historique effacé", {
        duration: 1000
      });
    } catch (error) {
      console.error('Erreur lors de l\'effacement de l\'historique:', error);
      toast.error("Erreur lors de l'effacement de l'historique", {
        duration: 1000
      });
    }
  };

  const handleProductClick = (productId: number) => {
    navigate(`/product/${productId}`);
  };

  const EmptyHistoryState = () => <div className="flex flex-col items-center justify-center h-full p-8 text-center">
      <img alt="Scan first" className="w-48 mb-8" src="/lovable-uploads/554b1d0b-bc59-423b-be33-b05b04a84add.png" />
      <h3 className="text-xl font-bold mb-2">Il faut d'abord scanner !</h3>
      <p className="text-gray-600">Les résultats enregistrés apparaîtront ici</p>
      {!isAuthenticated && <Button className="mt-4 bg-pink-400 hover:bg-pink-500" onClick={() => navigate('/')}>
          Se connecter pour sauvegarder l'historique
        </Button>}
    </div>;

  const LoadingState = () => <div className="flex flex-col items-center justify-center h-full p-8">
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-pink-400 mb-4"></div>
      <p className="text-gray-600">Chargement de l'historique...</p>
    </div>;

  return <div className="flex flex-col min-h-screen bg-white safe-area">
      <div className="bg-[#fff5ff] py-4 px-4 flex items-center justify-between border-b safe-top">
        <h1 className="text-xl font-bold">Historique</h1>
        
        {items.length > 0 && (
          <Button 
            variant="ghost" 
            onClick={handleClearHistory} 
            className="text-gray-600 hover:text-red-500"
          >
            <Trash2 className="h-5 w-5" />
          </Button>
        )}
      </div>
      
      <div className="flex-1 bg-white">
        {isLoading ? <LoadingState /> : items.length > 0 ? <ScrollArea className="h-full px-4 py-2 pb-24">
            <div className="space-y-3">
              {items.map(item => {
                const uniqueKey = getUniqueItemKey(item);
                return <div key={uniqueKey} className="relative overflow-hidden rounded-xl" onTouchStart={e => handleSwipeStart(uniqueKey, e.touches[0].clientX)} onTouchMove={e => handleSwipeMove(uniqueKey, e.touches[0].clientX)} onTouchEnd={() => handleSwipeEnd(uniqueKey, item.entryId)} onMouseDown={e => handleSwipeStart(uniqueKey, e.clientX)} onMouseMove={e => swipingStates[uniqueKey]?.isSwiping && handleSwipeMove(uniqueKey, e.clientX)} onMouseUp={() => handleSwipeEnd(uniqueKey, item.entryId)} onMouseLeave={() => swipingStates[uniqueKey]?.isSwiping && handleSwipeEnd(uniqueKey, item.entryId)}>
                    <div className="absolute right-0 top-0 bottom-0 flex items-center justify-center w-16 bg-red-500 text-white rounded-r-xl">
                      <X size={24} />
                    </div>
                    
                    <Card className="bg-white/75 backdrop-blur-md rounded-xl border border-white/20 shadow-lg cursor-pointer transform transition-all duration-200 hover:scale-[1.02] hover:-translate-y-1" style={{
                transform: `translateX(${swipingStates[uniqueKey]?.offset || 0}px)`,
                boxShadow: '0 6px 15px rgba(0, 0, 0, 0.08)'
              }} onClick={() => handleProductClick(item.id)}>
                      <div className="flex p-4">
                        <div className="h-20 w-20 flex-shrink-0 mr-4 bg-white rounded-lg p-1 shadow-sm">
                          <img src={item.imageUrl} alt={item.name} className="h-full w-full object-contain rounded-lg" />
                        </div>
                        
                        <div className="flex-1">
                          <h4 className="text-lg font-bold mb-1">{item.name || 'Produit sans nom'}</h4>
                          <p className="text-sm text-gray-600">{item.brand || 'Marque inconnue'}</p>
                          <p className="text-xs text-gray-500">{item.weight || ''}</p>
                          
                          <div className="flex items-center gap-3 mt-2">
                            {item.nutriScore && nutriScoreImages[item.nutriScore.toLowerCase()] && <img src={nutriScoreImages[item.nutriScore.toLowerCase()]} alt={`Nutri-Score ${item.nutriScore}`} className="h-9" />}
                            
                            {item.novaScore && <div className="flex items-center">
                                <span className="text-xs font-medium mr-1">NOVA</span>
                                <div className={`w-6 h-6 rounded-full ${getNovaScoreColor(item.novaScore)} flex items-center justify-center text-white font-bold text-xs shadow-sm`}>
                                  {item.novaScore}
                                </div>
                              </div>}
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>;
              })}
            </div>
          </ScrollArea> : <EmptyHistoryState />}
      </div>
      
      <BottomNavbar />
    </div>;
};

export default HistoryPage;
