
import React from 'react';
import { Search } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useToast } from '@/hooks/use-toast';

interface SearchBarProps {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  handleSearch: () => void;
}

const SearchBar = ({ searchQuery, setSearchQuery, handleSearch }: SearchBarProps) => {
  const { toast } = useToast();
  
  const onSearch = () => {
    if (!searchQuery.trim()) {
      toast({
        title: "Erreur",
        description: "Veuillez entrer un terme de recherche",
        variant: "destructive"
      });
      return;
    }
    handleSearch();
  };

  return (
    <div className="px-4 my-4">
      <div className="flex gap-2">
        <div className="relative flex-1">
          <Input 
            type="text" 
            placeholder="Rechercher un produit" 
            className="pl-10 pr-4 py-2 rounded-full border-pink-300 text-sm" 
            value={searchQuery} 
            onChange={e => setSearchQuery(e.target.value)} 
            onKeyDown={e => e.key === 'Enter' && onSearch()} 
          />
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
            <Search className="h-5 w-5 text-pink-400" />
          </div>
        </div>
        <Button 
          onClick={onSearch} 
          className="bg-pink-500 hover:bg-pink-600 text-white rounded-full"
        >
          Rechercher
        </Button>
      </div>
    </div>
  );
};

export default SearchBar;
