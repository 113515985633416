
import React, { useEffect } from 'react';
import { useAuth } from '@/hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import AuthPage from '@/pages/AuthPage';

const Index = () => {
  const { isAuthenticated, loading } = useAuth();
  const navigate = useNavigate();
  
  useEffect(() => {
    if (isAuthenticated && !loading) {
      navigate('/home');
    }
  }, [isAuthenticated, loading, navigate]);

  // Skip all splash and onboarding screens, directly show auth page
  const handleAuthComplete = () => {
    navigate('/home');
  };
  
  return (
    <div className="w-full max-w-md mx-auto min-h-screen bg-[#fff5ff]">
      {/* Directly show the auth page instead of splash screens */}
      <AuthPage onComplete={handleAuthComplete} onBack={() => {}} />
    </div>
  );
};

export default Index;
