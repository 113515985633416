// Interface pour les réponses des callbacks AdMob
interface AdMobCallbackResponse {
  status: string;
  error?: string;
  additionalData?: any;
  rewardData?: any;
}

// Constantes pour les ID AdMob
const ADMOB_APP_ID = 'ca-app-pub-9106270262318916~1003568820';
const BANNER_AD_UNIT_ID = 'ca-app-pub-9106270262318916/4326369185';

// Vérifie si l'environnement est natif (Android/iOS)
const isNativeApp = (): boolean => {
  return typeof window !== 'undefined' && (
    (typeof window.WebToNative === 'object' && typeof window.WebToNative.bannerAd === 'function') ||
    (typeof window.webkit?.messageHandlers?.bannerAd === 'object') ||
    (typeof window.Android === 'object' && typeof window.Android.bannerAd === 'function')
  );
};

// Vérifie si l'application est dans une WebView avec une détection plus robuste
const isWebViewApp = (): boolean => {
  if (typeof window === 'undefined') return false;
  
  // Détection de WebView pour iOS
  const isIOSWebView = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent) ||
                       typeof window.webkit !== 'undefined';
  
  // Détection de WebView pour Android
  const isAndroidWebView = /wv|WebView/.test(navigator.userAgent) ||
                          typeof window.Android !== 'undefined' ||
                          // Détection spécifique pour les WebView Android
                          !!window.navigator.userAgent.match(/Android/) && 
                          (!!document.documentElement.classList.contains('webview') ||
                           !!window.navigator.userAgent.match(/Version\/[0-9.]+/));
  
  // Détection générique supplémentaire
  const isGenericWebView = typeof window.ReactNativeWebView !== 'undefined' || 
                          typeof window.WebToNative !== 'undefined';
  
  console.log('Détection WebView:', {isIOSWebView, isAndroidWebView, isGenericWebView});
  
  return isIOSWebView || isAndroidWebView || isGenericWebView;
};

// Détermine si l'environnement peut afficher des publicités
const canShowAds = (): boolean => {
  const nativeApp = isNativeApp();
  const webViewApp = isWebViewApp();
  console.log('Environnement publicitaire:', {nativeApp, webViewApp});
  return nativeApp || webViewApp;
};

// ID pour les publicités bannières
const getBannerAdId = (): string => {
  if (canShowAds()) {
    return BANNER_AD_UNIT_ID;
  }
  return ''; // Fallback pour le web
};

// Vérifie si WebToNative est correctement initialisé et explore les alternatives
const checkWebToNativeInitialization = (): void => {
  console.log('Vérification de l\'environnement WebToNative:');
  console.log('- window.WebToNative:', typeof window.WebToNative !== 'undefined' ? 'Disponible' : 'Non disponible');
  console.log('- window.webkit:', typeof window.webkit !== 'undefined' ? 'Disponible' : 'Non disponible');
  console.log('- window.Android:', typeof window.Android !== 'undefined' ? 'Disponible' : 'Non disponible');

  if (typeof window.WebToNative === 'undefined') {
    console.warn('WebToNative n\'est pas disponible. Recherche d\'alternatives...');
    
    if (typeof window.webkit !== 'undefined' && window.webkit.messageHandlers) {
      console.log('Interface webkit.messageHandlers détectée, compatible pour iOS WebView');
    } else if (typeof window.Android !== 'undefined') {
      console.log('Interface Android détectée, compatible pour Android WebView');
    } else {
      console.warn('Aucune interface native détectée pour les publicités');
    }
  } else {
    console.log('WebToNative disponible:', window.WebToNative);
    if (typeof window.WebToNative.bannerAd !== 'function') {
      console.warn('La fonction bannerAd n\'est pas disponible dans WebToNative');
    } else {
      console.log('WebToNative correctement initialisé avec bannerAd disponible');
    }
  }
};

// Force le chargement manuel des publicités en cas de problème
const forceLoadAd = (containerId: string, adId: string): void => {
  try {
    const container = document.getElementById(containerId);
    if (!container) {
      console.error(`Conteneur ${containerId} introuvable`);
      return;
    }
    
    console.log('Tentative de chargement forcé de la publicité dans', containerId);
    
    // Essai avec WebToNative si disponible
    if (typeof window.WebToNative !== 'undefined' && typeof window.WebToNative.bannerAd === 'function') {
      window.WebToNative.bannerAd({
        adId: adId,
        containerId: containerId,
        bannerAdCallback: (response: string) => {
          console.log('Réponse de callback publicitaire:', response);
        }
      });
      return;
    }
    
    // Essai avec webkit pour iOS
    if (typeof window.webkit !== 'undefined' && window.webkit.messageHandlers && window.webkit.messageHandlers.bannerAd) {
      window.webkit.messageHandlers.bannerAd.postMessage({
        adId: adId,
        containerId: containerId
      });
      return;
    }
    
    // Essai avec Android
    if (typeof window.Android !== 'undefined' && typeof window.Android.bannerAd === 'function') {
      window.Android.bannerAd(adId, containerId);
      return;
    }
    
    console.warn('Aucune méthode de chargement publicitaire disponible');
  } catch (error) {
    console.error('Erreur lors du chargement forcé de la publicité:', error);
  }
};

// Affiche une publicité bannière
const showBannerAd = (containerId: string): Promise<boolean> => {
  return new Promise((resolve) => {
    // Vérification de l'initialisation
    checkWebToNativeInitialization();
    
    // Marquer le container comme zone publicitaire
    const container = document.getElementById(containerId);
    if (container) {
      container.setAttribute('data-ad-container', 'true');
      container.innerHTML = '<div style="background-color: #f0f0f0; text-align: center; padding: 10px; width: 100%; min-height: 90px; display: flex; align-items: center; justify-content: center; border-radius: 8px; margin: 10px 0;">Chargement de la publicité...</div>';
    } else {
      console.error(`Conteneur d'annonce avec ID '${containerId}' introuvable dans le DOM`);
      resolve(false);
      return;
    }
    
    if (!canShowAds()) {
      console.log('Les publicités bannières ne sont disponibles que dans les applications natives ou WebView');
      resolve(false);
      return;
    }

    try {
      const adId = getBannerAdId();
      if (!adId) {
        console.warn('Aucun ID d\'annonce disponible');
        resolve(false);
        return;
      }

      console.log('Tentative d\'affichage de la publicité avec ID:', adId, 'dans le conteneur:', containerId);
      
      // 1. Essayer WebToNative.bannerAd
      if (typeof window.WebToNative !== 'undefined' && typeof window.WebToNative.bannerAd === 'function') {
        console.log('Utilisation de WebToNative.bannerAd');
        
        window.WebToNative.bannerAd({
          adId: adId,
          containerId: containerId,
          bannerAdCallback: (response: string) => {
            try {
              const parsedResponse: AdMobCallbackResponse = JSON.parse(response);
              console.log('Réponse de la bannière publicitaire:', parsedResponse);
              
              if (parsedResponse.status === 'success') {
                console.log('Publicité affichée avec succès');
                resolve(true);
              } else {
                console.error('Échec de l\'affichage de la publicité:', parsedResponse.error);
                // En cas d'échec, tenter un chargement forcé après un délai
                setTimeout(() => forceLoadAd(containerId, adId), 1000);
                resolve(false);
              }
            } catch (e) {
              console.error('Erreur lors de l\'analyse de la réponse de la publicité:', e);
              resolve(false);
            }
          }
        });
      } 
      // 2. Essayer iOS WebView
      else if (typeof window.webkit !== 'undefined' && window.webkit.messageHandlers && window.webkit.messageHandlers.bannerAd) {
        console.log('Utilisation de webkit.messageHandlers.bannerAd pour iOS WebView');
        try {
          window.webkit.messageHandlers.bannerAd.postMessage({
            adId: adId,
            containerId: containerId
          });
          console.log('Message envoyé via webkit.messageHandlers');
          resolve(true);
        } catch (e) {
          console.error('Erreur lors de l\'envoi via webkit.messageHandlers:', e);
          resolve(false);
        }
      } 
      // 3. Essayer Android WebView
      else if (typeof window.Android !== 'undefined' && typeof window.Android.bannerAd === 'function') {
        console.log('Utilisation de Android.bannerAd pour Android WebView');
        try {
          window.Android.bannerAd(adId, containerId);
          console.log('Message envoyé via interface Android');
          resolve(true);
        } catch (e) {
          console.error('Erreur lors de l\'envoi via interface Android:', e);
          resolve(false);
        }
      } else {
        console.warn('Aucune méthode d\'affichage publicitaire n\'a été trouvée');
        
        // Utiliser un délai et réessayer en cas d'initialisation tardive
        setTimeout(() => {
          console.log('Tentative différée d\'affichage de publicité...');
          forceLoadAd(containerId, adId);
        }, 2000);
        
        resolve(false);
      }
    } catch (error) {
      console.error('Erreur lors de l\'affichage de la publicité bannière:', error);
      resolve(false);
    }
  });
};

export const admobService = {
  showBannerAd,
  isNativeApp,
  isWebViewApp,
  canShowAds,
  forceLoadAd
};
