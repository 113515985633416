import { supabase } from '@/integrations/supabase/client';
import { CategoryType } from '@/hooks/useCategorizeProduct';

// Types and interfaces
export type NutritionItem = {
  name: string;
  value: string;
  level: 'low' | 'moderate' | 'high';
  icon: 'check' | 'warning' | 'circle-x';
};

export type NutritionData = {
  items: NutritionItem[];
  energy: string;
  energyKcal: string;
  carbohydrates: string;
  proteins: string;
  fiber: string;
};

export type Product = {
  id: number;
  name: string;
  fullName: string;
  brand: string;
  weight: string;
  category: string;
  categories?: string[];
  imageUrl: string;
  novaScore: number;
  nutriScore: string;
  status: string;
  warnings: string[];
  allergens: string[];
  detailedAllergens?: string[];
  nutrition: NutritionData;
  ingredients: string[];
  ingredientCount: number;
};

// Helper function to get Nutri-Score image URL from Supabase
export const getNutriScoreImage = async (score: string) => {
  const normalizedScore = score.toUpperCase();
  let imageName;
  
  switch (normalizedScore) {
    case 'A':
      imageName = 'nutriscorea.png';
      break;
    case 'B':
      imageName = 'nutriscoreb.png';
      break;
    case 'C':
      imageName = 'nutriscorec.png';
      break;
    case 'D':
      imageName = 'nutriscored.png';
      break;
    case 'E':
      imageName = 'nutriscoree.png';
      break;
    default:
      imageName = 'nutriscoreunknow.png';
  }
  
  try {
    const { data } = await supabase.storage
      .from('nutriscore')
      .getPublicUrl(imageName);
    
    return data.publicUrl;
  } catch (err) {
    console.error('Failed to get Nutri-Score image from Supabase:', err);
    return `/lovable-uploads/${imageName}`;
  }
};

// Helper function to get NOVA score color
export const getNovaScoreColor = (score: number) => {
  switch (score) {
    case 1:
      return 'bg-green-600';
    case 2:
      return 'bg-yellow-500';
    case 3:
      return 'bg-orange-500';
    case 4:
      return 'bg-red-600';
    default:
      return 'bg-gray-400';
  }
};

// Helper function to get NOVA score explanation
export const getNovaScoreExplanation = (score: number) => {
  switch (score) {
    case 1:
      return "Groupe 1 - Aliments non transformés ou transformés minimalement";
    case 2:
      return "Groupe 2 - Ingrédients culinaires transformés";
    case 3:
      return "Groupe 3 - Aliments transformés";
    case 4:
      return "Groupe 4 - Produits alimentaires et boissons ultra-transformés";
    default:
      return "Information NOVA non disponible";
  }
};

// Helper function to get nutrition icon class names
export const getNutritionIcon = (icon: string) => {
  switch (icon) {
    case 'check':
      return { className: 'w-6 h-6 bg-green-500 rounded-full flex items-center justify-center text-white', symbol: '✓' };
    case 'warning':
      return { className: 'w-6 h-6 bg-amber-500 rounded-full flex items-center justify-center', symbol: '⚠️' };
    case 'circle-x':
      return { className: 'w-6 h-6 bg-red-500 rounded-full flex items-center justify-center text-white', symbol: '✕' };
    default:
      return { className: '', symbol: '' };
  }
};

// Helper function to get category status color
export const getCategoryStatusColor = (status: CategoryType) => {
  switch (status) {
    case 'forbidden':
      return 'bg-red-100';
    case 'authorized-with-warning':
      return 'bg-orange-100';
    case 'authorized-with-moderation':
      return 'bg-amber-100';
    case 'authorized':
      return 'bg-green-100';
    case 'lack-of-information':
      return 'bg-gray-100';
    default:
      return 'bg-green-100';
  }
};

// Helper function to get category status text color
export const getCategoryStatusTextColor = (status: CategoryType) => {
  switch (status) {
    case 'forbidden':
      return 'text-red-600';
    case 'authorized-with-warning':
      return 'text-orange-600';
    case 'authorized-with-moderation':
      return 'text-amber-800';
    case 'authorized':
      return 'text-green-800';
    case 'lack-of-information':
      return 'text-gray-800';
    default:
      return 'text-green-800';
  }
};

// Helper function to get category status title
export const getCategoryStatusTitle = (status: CategoryType) => {
  switch (status) {
    case 'forbidden':
      return 'Interdit pendant la grossesse';
    case 'authorized-with-warning':
      return 'Autorisé avec précautions';
    case 'authorized-with-moderation':
      return 'Autorisé avec modération';
    case 'authorized':
      return 'Autorisé sans modération';
    case 'lack-of-information':
      return 'Information insuffisante';
    default:
      return 'Autorisé sans modération';
  }
};
