
import { useState } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { UserProfile } from '@/types/auth.types';

export const useProfileLoader = () => {
  const [loading, setLoading] = useState(true);

  const loadUserProfile = async (userId: string): Promise<UserProfile | null> => {
    try {
      const { data: profile, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', userId)
        .single();
      
      if (error) {
        console.error('Erreur lors de la récupération du profil:', error);
        setLoading(false);
        return null;
      }
      
      if (profile) {
        console.log('Profil chargé avec succès:', { 
          userId: profile.id
        });
        
        return profile;
      }
      
      setLoading(false);
      return null;
    } catch (error) {
      console.error('Erreur lors du chargement du profil utilisateur:', error);
      setLoading(false);
      return null;
    }
  };

  return { loadUserProfile, setLoading };
};
