
import { Product } from '@/utils/productUtils';

// Type for representing a history entry
export interface HistoryEntry extends Product {
  entryId: string; // ID unique for each history entry
}

// Configuration constants
export const MAX_HISTORY_ITEMS = 50;
