
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';

interface DeleteUserResponse {
  success: boolean;
  message?: string;
  error?: string;
}

export const deleteAccount = async () => {
  try {
    // Get the current user
    const { data: { user } } = await supabase.auth.getUser();
    
    if (!user) {
      throw new Error('Aucun utilisateur connecté');
    }
    
    // Désinscrire l'utilisateur de la newsletter
    if (user.email) {
      try {
        const { error: unsubscribeError } = await supabase.functions.invoke('newsletter-unsubscribe', {
          body: { 
            email: user.email,
            userId: user.id 
          }
        });
      
        if (unsubscribeError) {
          console.error('Erreur lors de la désinscription à la newsletter:', unsubscribeError);
        }
      } catch (unsubErr) {
        console.error('Erreur lors de l\'appel de la fonction de désinscription:', unsubErr);
      }
    }
    
    // Appeler la fonction RPC personnalisée pour supprimer l'utilisateur et ses données associées
    const { data, error } = await supabase.rpc('delete_user');
    
    if (error) {
      console.error('Erreur lors de la suppression du compte:', error);
      throw error;
    }
    
    // Convertir les données en type attendu
    const deleteData = data as unknown as DeleteUserResponse;
    
    if (!deleteData.success) {
      throw new Error(deleteData.message || deleteData.error || 'Échec de la suppression du compte');
    }
    
    // Déconnecter l'utilisateur après la suppression réussie
    await supabase.auth.signOut();
    
    return { success: true };
  } catch (error: any) {
    console.error('Erreur lors de la suppression du compte:', error);
    return { 
      success: false, 
      error: error.message || 'Erreur lors de la suppression du compte' 
    };
  }
};
