import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, User, History, Camera, Search, FileText, Settings, Shield, Book, Info } from 'lucide-react';

const LegalInfoPage = () => {
  const navigate = useNavigate();
  return <div className="flex flex-col min-h-screen bg-[#fff5ff] pb-16 md:pb-0 safe-area">
      {/* Header */}
      <div className="bg-[#fff5ff] py-4 px-4 flex items-center border-b safe-top">
        <button className="mr-2" onClick={() => navigate('/')}>
          <ArrowLeft className="h-6 w-6" />
        </button>
        <h1 className="text-xl font-bold">Informations légales</h1>
      </div>

      {/* Legal Content */}
      <div className="flex-1 px-4 py-4 overflow-y-auto pb-24">
        {/* Terms Section */}
        <div className="space-y-0 mb-4" id="terms">
          <div className="bg-pink-400 rounded-t-xl p-3">
            <h2 className="text-white font-semibold">Conditions d'utilisation</h2>
          </div>
          
          <div className="bg-white rounded-b-xl overflow-hidden shadow-sm p-4">
            <div className="space-y-4">
              <h3 className="font-medium text-pink-400">1. Acceptation des conditions</h3>
              <p className="text-sm text-gray-600">
                En accédant à l'application Nutrimam, vous acceptez d'être lié par ces conditions d'utilisation, toutes les lois et réglementations applicables, 
                et vous acceptez que vous êtes responsable du respect des lois locales applicables. Si vous n'acceptez pas l'une de ces conditions, 
                il vous est interdit d'utiliser cette application. Les documents contenus dans cette application sont protégés par les lois applicables 
                en matière de droits d'auteur et de marques commerciales.
              </p>

              <h3 className="font-medium text-pink-400">2. Licence d'utilisation</h3>
              <p className="text-sm text-gray-600">
                La permission est accordée de télécharger temporairement une copie des documents (informations ou logiciels) sur l'application Nutrimam 
                pour un usage personnel et non commercial transitoire uniquement. Cette licence ne constitue pas un transfert de titre et, sous cette licence, 
                vous ne pouvez pas : modifier ou copier les documents; utiliser les documents à des fins commerciales ou pour toute exposition publique; 
                tenter de décompiler ou de désosser tout logiciel contenu sur l'application Nutrimam; supprimer tout droit d'auteur ou autres annotations 
                de propriété des documents; ou transférer les documents à une autre personne ou "miroir" les documents sur un autre serveur.
              </p>

              <h3 className="font-medium text-pink-400">3. Avis de non-responsabilité</h3>
              <p className="text-sm text-gray-600">
                Les documents sur l'application Nutrimam sont fournis "tels quels". 2a-bot ne donne aucune garantie, expresse ou implicite, et décline 
                et nie par la présente toutes les autres garanties, y compris, sans limitation, les garanties implicites ou les conditions de qualité 
                marchande, d'adéquation à un usage particulier, ou de non-violation de la propriété intellectuelle ou autre violation des droits. 
                En outre, 2a-bot ne garantit pas et ne fait aucune représentation concernant l'exactitude, les résultats probables, ou la fiabilité 
                de l'utilisation des documents sur son application ou autrement liés à ces documents ou sur tout site lié à cette application.
              </p>

              <h3 className="font-medium text-pink-400">4. Limitations</h3>
              <p className="text-sm text-gray-600">
                En aucun cas, 2a-bot ou ses fournisseurs ne seront responsables de tout dommage (y compris, sans limitation, les dommages pour perte 
                de données ou de profit, ou en raison d'une interruption d'activité) découlant de l'utilisation ou de l'incapacité d'utiliser les 
                documents sur l'application Nutrimam, même si 2a-bot ou un représentant autorisé a été notifié oralement ou par écrit de la possibilité 
                de tels dommages. Comme certaines juridictions n'autorisent pas les limitations sur les garanties implicites, ou les limitations de 
                responsabilité pour les dommages consécutifs ou accessoires, ces limitations peuvent ne pas s'appliquer à vous.
              </p>

              <h3 className="font-medium text-pink-400">5. Révisions et erreurs</h3>
              <p className="text-sm text-gray-600">
                Les documents apparaissant sur l'application Nutrimam pourraient inclure des erreurs techniques, typographiques ou photographiques. 
                2a-bot ne garantit pas que l'un des documents de son application est précis, complet ou à jour. 2a-bot peut apporter des modifications 
                aux documents contenus sur son application à tout moment sans préavis. 2a-bot ne s'engage toutefois pas à mettre à jour les documents.
              </p>

              <h3 className="font-medium text-pink-400">6. Liens</h3>
              <p className="text-sm text-gray-600">
                2a-bot n'a pas examiné tous les sites liés à son application et n'est pas responsable du contenu de ces sites liés. L'inclusion de tout 
                lien n'implique pas l'approbation par 2a-bot du site. L'utilisation de tout site Web lié est aux risques et périls de l'utilisateur.
              </p>

              <h3 className="font-medium text-pink-400">7. Modifications des conditions d'utilisation</h3>
              <p className="text-sm text-gray-600">
                2a-bot peut réviser ces conditions d'utilisation de son application à tout moment sans préavis. En utilisant cette application, 
                vous acceptez d'être lié par la version alors actuelle de ces conditions d'utilisation.
              </p>

              <h3 className="font-medium text-pink-400">8. Droit applicable</h3>
              <p className="text-sm text-gray-600">
                Toute réclamation relative à l'application Nutrimam est régie par les lois de la France sans égard à ses dispositions en matière de conflit de lois.
              </p>
            </div>
          </div>
        </div>

        {/* Privacy Section */}
        <div className="space-y-0 mb-4" id="privacy">
          <div className="bg-pink-400 rounded-t-xl p-3">
            <h2 className="text-white font-semibold">Politique de confidentialité</h2>
          </div>
          
          <div className="bg-white rounded-b-xl overflow-hidden shadow-sm p-4">
            <div className="space-y-4">
              <h3 className="font-medium text-pink-400">1. Collecte des informations</h3>
              <p className="text-sm text-gray-600">
                Nous recueillons des informations lorsque vous vous inscrivez sur notre application, vous connectez à votre compte, 
                scannez des produits, ou utilisez d'autres fonctionnalités de l'application. Les informations collectées incluent votre nom, 
                prénom, adresse e-mail, préférences alimentaires et allergies. En outre, nous recevons et enregistrons automatiquement 
                des informations à partir de votre appareil mobile, y compris votre adresse IP, les données du navigateur et les pages que vous consultez.
              </p>

              <h3 className="font-medium text-pink-400">2. Utilisation des informations</h3>
              <p className="text-sm text-gray-600">
                Toute information que nous recueillons auprès de vous peut être utilisée pour :
                <br />- Personnaliser votre expérience et répondre à vos besoins individuels
                <br />- Améliorer notre application
                <br />- Améliorer le service client et vos besoins de support
                <br />- Vous contacter par e-mail
                <br />- Administrer un concours, une promotion, ou une enquête
                <br />- Vous fournir des recommandations de produits adaptées à vos besoins
              </p>

              <h3 className="font-medium text-pink-400">3. Protection des informations</h3>
              <p className="text-sm text-gray-600">
                Nous mettons en œuvre une variété de mesures de sécurité pour maintenir la sécurité de vos informations personnelles. 
                Nous utilisons un cryptage à la pointe de la technologie pour protéger les informations sensibles transmises en ligne. 
                Nous protégeons également vos informations hors ligne. Seuls les employés qui ont besoin d'effectuer un travail spécifique 
                (par exemple, la facturation ou le service client) ont accès aux informations personnellement identifiables. 
                Les ordinateurs/serveurs dans lesquels nous stockons des informations personnellement identifiables sont conservés dans un environnement sécurisé.
              </p>

              <h3 className="font-medium text-pink-400">4. Cookies</h3>
              <p className="text-sm text-gray-600">
                Nous utilisons des cookies pour comprendre et enregistrer vos préférences pour les visites futures, compiler des données agrégées 
                sur le trafic du site et l'interaction du site afin que nous puissions offrir de meilleures expériences et outils à l'avenir. 
                Nous pouvons contracter avec des fournisseurs de services tiers pour nous aider à mieux comprendre nos utilisateurs de site. 
                Ces fournisseurs de services ne sont pas autorisés à utiliser les informations collectées en notre nom, sauf pour nous aider 
                à conduire et améliorer notre activité.
              </p>

              <h3 className="font-medium text-pink-400">5. Divulgation à des tiers</h3>
              <p className="text-sm text-gray-600">
                Nous ne vendons, n'échangeons, ni ne transférons vos informations personnelles identifiables à des tiers. 
                Cela n'inclut pas les tiers de confiance qui nous aident à exploiter notre application, à gérer notre activité, 
                ou à vous servir, tant que ces parties acceptent de garder ces informations confidentielles. 
                Nous pouvons également divulguer vos informations lorsque nous croyons que la divulgation est appropriée pour se conformer à la loi, 
                faire respecter les politiques de notre site, ou protéger nos droits, notre propriété, ou notre sécurité ou celle d'autres personnes.
                Cependant, les informations non personnellement identifiables peuvent être fournies à d'autres parties pour le marketing, la publicité, ou d'autres utilisations.
              </p>

              <h3 className="font-medium text-pink-400">6. Consentement</h3>
              <p className="text-sm text-gray-600">
                En utilisant notre application, vous consentez à notre politique de confidentialit���.
              </p>

              <h3 className="font-medium text-pink-400">7. Modifications de notre politique de confidentialité</h3>
              <p className="text-sm text-gray-600">
                Si nous décidons de changer notre politique de confidentialité, nous publierons ces changements sur cette page.
              </p>

              <h3 className="font-medium text-pink-400">8. Contact</h3>
              <p className="text-sm text-gray-600">
                Si vous avez des questions concernant cette politique de confidentialité, vous pouvez nous contacter à :
                <br />2a-bot.compagnie@gmail.com
                <br />40 chemin de lizieu, 01510 Pugieu, Ain, France
                <br />SIRET : 92893578200013
              </p>
            </div>
          </div>
        </div>

        {/* Other Legal Info */}
        <div className="space-y-0">
          <div className="bg-pink-400 rounded-t-xl p-3">
            <h2 className="text-white font-semibold">Informations supplémentaires</h2>
          </div>
          
          <div className="bg-white rounded-b-xl overflow-hidden shadow-sm p-4">
            <div className="flex items-start mb-3">
              <Info className="h-5 w-5 text-pink-400 mr-3 mt-0.5 flex-shrink-0" />
              <div>
                <p className="font-medium text-pink-400 mb-1">Mentions légales</p>
                <p className="text-sm text-gray-600">Nutrimam est une application développée par 2a-bot.fr, SIRET: 92893578200013, dont le siège social est situé à 40 chemin de lizieu, 01510 Pugieu, France.</p>
              </div>
            </div>
            
            <div className="flex items-start">
              <Info className="h-5 w-5 text-pink-400 mr-3 mt-0.5 flex-shrink-0" />
              <div>
                <p className="font-medium text-pink-400 mb-1">Responsabilité</p>
                <p className="text-sm text-gray-600">
                  Les informations fournies par Nutrimam sont à titre informatif uniquement. 
                  Nous nous efforçons de fournir des informations exactes, mais ne pouvons garantir 
                  l'exactitude de toutes les données. Consultez toujours un professionnel de santé 
                  pour des conseils médicaux.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Navigation buttons for public access */}
      <div className="fixed bottom-0 left-0 right-0 border-t bg-white md:hidden safe-bottom">
        <div className="flex justify-around py-5">
          <button className="flex flex-col items-center text-gray-400" onClick={() => navigate('/')}>
            <Info className="h-6 w-6" />
            <span className="text-xs mt-1 mb-2">Accueil</span>
          </button>
        </div>
      </div>
    </div>;
};
export default LegalInfoPage;
