
import React, { useState } from 'react';
import LoginForm from '@/components/auth/LoginForm';
import SignUpForm from '@/components/auth/SignUpForm';
import { ChevronLeft } from 'lucide-react';
import { cn } from '@/lib/utils';

interface AuthPageProps {
  onComplete: () => void;
  onBack: () => void;
}

const AuthPage = ({ onComplete, onBack }: AuthPageProps) => {
  const [isLogin, setIsLogin] = useState(true);
  
  return (
    <div className="min-h-screen w-full flex flex-col bg-[#fff5ff] safe-area">
      {/* Header with Back Button */}
      <div className="py-4 px-4 flex items-center border-b safe-top">
        <button onClick={onBack} className="p-2 rounded-full hover:bg-gray-100">
          <ChevronLeft className="h-5 w-5 text-gray-600" />
        </button>
        <h1 className="ml-4 text-xl font-bold">
          {isLogin ? 'Connexion' : 'Créer un compte'}
        </h1>
      </div>
      
      {/* Content Area */}
      <div className="flex-1 p-4 flex flex-col">
        {isLogin ? (
          <LoginForm onComplete={onComplete} />
        ) : (
          <SignUpForm onComplete={onComplete} />
        )}
        
        <div className="mt-6 text-center">
          {isLogin ? (
            <p className="text-gray-600">
              Vous n'avez pas de compte ?{' '}
              <button 
                onClick={() => setIsLogin(false)}
                className="text-nutrimam-accent font-medium"
              >
                Créer un compte
              </button>
            </p>
          ) : (
            <p className="text-gray-600">
              Déjà inscrit(e) ?{' '}
              <button 
                onClick={() => setIsLogin(true)}
                className="text-nutrimam-accent font-medium"
              >
                Se connecter
              </button>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthPage;
