
import { useState, useEffect } from 'react';

export interface ProductViewLimit {
  canViewProduct: boolean;
  viewsRemaining: number;
  nextResetDate: Date | null;
  isLimitReached: false;
}

// Modified to always allow product views with no limit
export const useProductViewLimit = (): ProductViewLimit => {
  return {
    canViewProduct: true,
    viewsRemaining: Infinity,
    nextResetDate: null,
    isLimitReached: false
  };
};

// This function is kept as a no-op to prevent breaking code that might call it
export const incrementProductView = async (userId: string): Promise<void> => {
  // No need to track views anymore
  return;
};
