
// Helper function to translate ingredient from English to French
export const translateIngredient = (ingredient: string): string => {
  const translations: {[key: string]: string} = {
    'milk': 'lait',
    'sugar': 'sucre',
    'salt': 'sel',
    'water': 'eau',
    'carrot': 'carotte',
    'carrots': 'carottes',
    'apple': 'pomme',
    'banana': 'banane',
    'potato': 'pomme de terre',
    'potatoes': 'pommes de terre',
    'wheat': 'blé',
    'flour': 'farine',
    'oil': 'huile',
    'vegetable oil': 'huile végétale',
    'palm oil': 'huile de palme',
    'vitamin': 'vitamine',
    'vitamins': 'vitamines',
    'minerals': 'minéraux',
    'chocolate': 'chocolat',
    'cocoa': 'cacao',
    'eggs': 'œufs',
    'egg': 'œuf',
    'chicken': 'poulet',
    'beef': 'bœuf',
    'pork': 'porc',
    'fish': 'poisson',
    'cheese': 'fromage',
    'yogurt': 'yaourt',
    'tomato': 'tomate',
    'onion': 'oignon',
    'garlic': 'ail',
    'celery': 'céleri',
    'peanut': 'arachide',
    'nut': 'noix',
    'almond': 'amande',
    'walnut': 'noix',
    'gluten': 'gluten',
    'starch': 'amidon',
    'corn starch': 'amidon de maïs',
    'modified starch': 'amidon modifié',
    'corn': 'maïs',
    'rice': 'riz',
    'barley': 'orge',
    'oat': 'avoine',
    'rye': 'seigle',
    'emulsifier': 'émulsifiant',
    'preservative': 'conservateur',
    'antioxidant': 'antioxydant',
    'flavoring': 'arôme',
    'flavor': 'arôme',
    'sweetener': 'édulcorant',
    'coloring': 'colorant',
    'color': 'colorant',
    'stabilizer': 'stabilisant',
    'thickener': 'épaississant',
    'acid': 'acide',
    'citric acid': 'acide citrique',
    'ascorbic acid': 'acide ascorbique',
    'lactic acid': 'acide lactique',
    'acidity regulator': 'régulateur d\'acidité'
  };

  const lowerIngredient = ingredient.toLowerCase().trim();
  for (const [eng, fr] of Object.entries(translations)) {
    if (lowerIngredient === eng || lowerIngredient.includes(eng)) {
      const regex = new RegExp(eng, 'i');
      return ingredient.replace(regex, fr);
    }
  }

  return ingredient;
};

// Helper function to translate allergen from English to French
export const translateAllergen = (allergen: string): string => {
  const translations: {[key: string]: string} = {
    'gluten': 'gluten',
    'wheat': 'blé',
    'crustaceans': 'crustacés',
    'eggs': 'œufs',
    'fish': 'poisson',
    'peanuts': 'arachides',
    'soybeans': 'soja',
    'milk': 'lait',
    'nuts': 'fruits à coque',
    'almonds': 'amandes',
    'hazelnuts': 'noisettes',
    'walnuts': 'noix',
    'cashews': 'noix de cajou',
    'pecans': 'noix de pécan',
    'pistachios': 'pistaches',
    'macadamia': 'noix de macadamia',
    'brazil nuts': 'noix du Brésil',
    'celery': 'céleri',
    'mustard': 'moutarde',
    'sesame': 'sésame',
    'sesame seeds': 'graines de sésame',
    'sulphur dioxide': 'anhydride sulfureux',
    'sulphites': 'sulfites',
    'lupin': 'lupin',
    'molluscs': 'mollusques',
    'shellfish': 'fruits de mer',
    'tree nuts': 'fruits à coque',
    'lactose': 'lactose'
  };

  const lowerAllergen = allergen.toLowerCase().trim();
  for (const [eng, fr] of Object.entries(translations)) {
    if (lowerAllergen === eng || lowerAllergen.includes(eng)) {
      const regex = new RegExp(eng, 'i');
      return allergen.replace(regex, fr);
    }
  }

  return allergen;
};
